var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    {
      staticClass: "justify-content-center core__client__input__group__wrapper",
    },
    [
      !_vm.hiddenLabel
        ? _c(
            "b-col",
            {
              staticClass: "d-flex client__title__input",
              class: { "align-items-center": _vm.labelMiddleCenter },
              attrs: {
                xl: _vm.labelXl,
                lg: _vm.labelLg,
                md: _vm.labelMd,
                sm: "12",
                sx: "12",
              },
            },
            [
              _vm._t("label", function () {
                return [
                  _c("label", {
                    staticClass:
                      "m-0 core__client__input__group__wrapper-title__input",
                    domProps: { innerHTML: _vm._s(_vm.title) },
                  }),
                  _vm._v(" "),
                  _vm.required
                    ? _c(
                        "span",
                        { staticClass: "text-danger ml-1 font-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("panel.label_client_required_field"))
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-col",
        {
          attrs: {
            xl: _vm.inputXl,
            lg: _vm.inputLg,
            md: _vm.inputMd,
            sm: "12",
            sx: "12",
          },
        },
        [
          _vm._t("input", function () {
            return [
              _c(
                "div",
                { class: _vm.addClassCustom },
                [
                  _vm.input
                    ? _c(
                        "b-form-input",
                        _vm._g(
                          _vm._b(
                            {
                              class: {
                                "is-invalid":
                                  _vm.error &&
                                  _vm.validateFail &&
                                  _vm.validateFail.hasOwnProperty(_vm.itemKey),
                              },
                              attrs: { trim: "" },
                              model: {
                                value: _vm.data,
                                callback: function ($$v) {
                                  _vm.data = $$v
                                },
                                expression: "data",
                              },
                            },
                            "b-form-input",
                            _vm.propsInput,
                            false
                          ),
                          _vm.$listeners
                        )
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.error &&
                            _vm.validateFail &&
                            _vm.validateFail.hasOwnProperty(_vm.itemKey),
                          expression:
                            "error && validateFail && validateFail.hasOwnProperty(itemKey)",
                        },
                      ],
                      staticClass: "text-danger",
                    },
                    [_vm._v(_vm._s(_vm.validateFail[_vm.itemKey]))]
                  ),
                  _vm._v(" "),
                  _vm.hintText
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "core__client__input__group__wrapper-hint__text",
                        },
                        [_vm._v(_vm._s(_vm.hintText))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }