var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "for-template" },
    [
      _c(
        "div",
        { staticClass: "d-block" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center m-3" },
            [
              _vm._t("title-header", function () {
                return [
                  _c("p", { staticClass: "h3 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.titlePage)),
                  ]),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.alert.show
            ? _c("core-alert-system", {
                attrs: { message: _vm.alert.message, error: _vm.alert.error },
                on: {
                  "hide-alert": () => {
                    this.alert.show = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.useLoading
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, opacity: "0.7" } },
                [
                  _vm._t("section"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center create__data__action",
                      staticStyle: { "margin-top": "200px" },
                    },
                    [
                      _vm._t("create-data", function () {
                        return [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.confirmDialog",
                                  modifiers: { confirmDialog: true },
                                },
                              ],
                              staticClass: "btn-w-200",
                              attrs: { variant: "primary" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("student.button_register")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("modals", function () {
        return [
          _c("core-modal-confirm", {
            attrs: { idComponent: "confirmDialog" },
            on: { ok: _vm.handleUpdate },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }