<template>
    <div class="for-template">
        <b-card
            :header="header"
            header-bg-variant="primary"
            class="core__card__form"
            header-text-variant="white-text">
            <b-form v-on="$listeners"
                autocomplete="false">

                <!-- content -->
                <div class="core__card__form-content">
                    <div v-if="isSearching" class="core__card__form-content__searching" >
                        <slot name="searching"></slot>
                    </div>
                    <slot v-else name="content">
                    </slot>
                </div>
                <!-- overlay -->
                <b-overlay
                    v-if="useLoading"
                    :show="loading"
                    opacity="0.7"
                    no-wrap>
                </b-overlay>

                <!-- SLOT ACTIONS -->
                <div class="core__card__form-actions">
                    <slot name="actions"
                        :goBackSearching="goBackSearching">
                        <div class="d-flex justify-content-center">
                            <b-button
                                type="submit"
                                variant="primary"
                                class="mr-2 btn-w-100">{{ $t("panel.label_search") }}</b-button>
                            <b-button
                                @click="reset"
                                variant="warning"
                                class="ml-2 btn-w-100">{{ $t("panel.label_clear") }}</b-button>
                        </div>
                    </slot>
                </div>
            </b-form>
        </b-card>
        <div class="d-flex justify-content-end create__data__action" v-if="showCreateDataBtn">
            <slot name="create-data" v-if="showCreateDataBtn">
                <router-link :to="{ name: routeNameCreate }">
                    <b-button variant="success">{{ $t("panel.label_create") }}</b-button>
                </router-link>
            </slot>
        </div>

        <!-- SLOT MODAL -->
        <slot name="modals">
            <!-- Modal update-->
            <core-modal-confirm
                idComponent="confirmDialog"
                @ok="handleOk">
            </core-modal-confirm>

            <!-- Modal delete -->
            <core-modal-confirm
                remove
                idComponent="removeDialog"
                @ok="handleRemove">
            </core-modal-confirm>
        </slot>


    </div>
</template>

<script>
export default {
    name: 'CardForm',
    props: {
        header: {
            type: String,
            default: 'header-card'
        },
        showCreateDataBtn: {
            type: Boolean,
            default: true
        },
        routeNameCreate: {
            type: String,
            default: ''
        },
        useLoading: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false
        },
        isSearching: {
            type: Boolean,
            default: false
        },
    },

    methods: {
        goBackSearching(nameRoute) {
            return window.goBackSearchingRoute(nameRoute);
        },

        handleRemove() {
            this.$emit('confirm-remove');
        },

        handleOk() {
            this.$emit('confirm-ok');
        },

        reset(){
            this.$parent.reset();
        }
    },
}
</script>

<style scoped lang="scss">
    .core__card__form {
        margin-bottom: 5px;
        &-content {
            display: block;
            margin-top: 10px;
            margin-bottom :5px;
            @media only screen and (min-width: 993px){
                &__searching > .row div{
                    padding: 2px 0px !important
                }
            }
        }
        .title__searching {
            font-weight: 600;
            font-size: 15px;
            color: black;
            margin: 0;
        }

        &-actions {
            display: block;
            margin: 5px 0;
        }
    }

    .create__data__action {
        margin: 10px 0;
    }
</style>
