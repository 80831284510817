import {
  SET_ACCOUNT_INFO,
  SET_EXPIRED_AT,
  SET_IS_AUTH,
  SET_MEMBERSHIP_PERIOD,
  SET_PAYMENT_INFO,
  SET_PERMISSION_MENU,
  SET_REFRESH_AUTH,
  SET_REFRESH_AUTH_KEEP_ACCOUNT_INFO,
  SET_STATUS_ACCOUNT,
  SET_TOKEN,
  SET_WITHDRAW_FLAG,
} from "./types";

import storage from "@/helpers/storageManage";
import {
  ApplicationManageWithdrawFlag,
  ApplicationMembershipPeriod,
} from "@/plugins/setting";

export default {
  [SET_TOKEN](state, token) {
    state.token = token;
  },

  [SET_IS_AUTH](state, status) {
    state.accountLogin.isAuth = status; // success or fail
  },

  [SET_REFRESH_AUTH](state) {
    state.accountLogin = {
      isAuth: "fail",
      info: {},
    };
    state.token = null;
    state.expired_at = null;
    state.permission_menu = [];
    state.membershipPeriod = null;
    state.paymentInfo = {};
  },

  [SET_REFRESH_AUTH_KEEP_ACCOUNT_INFO](state) {
    state.accountLogin.isAuth = "fail";
    state.token = null;
    state.expired_at = null;
    state.permission_menu = [];
    state.membershipPeriod = null;
    state.paymentInfo = {};
  },

  [SET_ACCOUNT_INFO](state, user_data) {
    state.accountLogin.info = user_data;
  },

  [SET_WITHDRAW_FLAG](state, withdraw_flag) {
    state.withdraw_flag = Number(withdraw_flag);
    storage.setStorage({
      [ApplicationManageWithdrawFlag]: withdraw_flag,
    });
  },

  [SET_MEMBERSHIP_PERIOD](state, membershipPeriod) {
    state.membershipPeriod = membershipPeriod;
    storage.setStorage({
      [ApplicationMembershipPeriod]: membershipPeriod,
    });
  },

  [SET_EXPIRED_AT](state, miliseconds) {
    state.expired_at = miliseconds;
  },

  [SET_PERMISSION_MENU](state, permisionMenu) {
    state.permission_menu = permisionMenu;
  },

  [SET_STATUS_ACCOUNT](state, payload) {
    state.accountLogin.isAuth = payload; // payload is true or fail
  },

  [SET_PAYMENT_INFO](state, payload) {
    state.paymentInfo = payload;
  },
};
