var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "side-bar",
        { attrs: { title: _vm.title } },
        [
          false
            ? _c("mobile-menu", { attrs: { slot: "content" }, slot: "content" })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.menu, function (itemMenu, index) {
            return _c(
              "sidebar-link",
              { key: index, attrs: { to: itemMenu.to } },
              [
                _c("i", { class: itemMenu.icon }),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(itemMenu.title))]),
              ]
            )
          }),
          _vm._v(" "),
          _c("template", { slot: "bottom-links" }, [
            _c(
              "li",
              {
                staticClass: "active",
                attrs: { "router-link-exact-active": "" },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: { href: "#" },
                    on: { click: _vm.logout },
                  },
                  [
                    _c("i", { staticClass: "nc-icon nc-button-power" }),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("panel.logout")))]),
                  ]
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _vm._v(" "),
          _c("dashboard-content", { on: { click: _vm.toggleSidebar } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }