import {
  SET_ADMIN_TOKEN,
  SET_ADMIN_INFO,
  SET_ADMIN_EXPIRED_AT,
  SET_STATUS_ADMIN,
  SET_REFRESH_AUTH_ADMIN,
  SET_ADMIN_PAYMENT_INFO,
} from '@/stores/modules/AuthAdmin/types';

export default [
  `AuthAdmin/${SET_ADMIN_TOKEN}`,
  `AuthAdmin/${SET_ADMIN_INFO}`,
  `AuthAdmin/${SET_ADMIN_EXPIRED_AT}`,
  `AuthAdmin/${SET_STATUS_ADMIN}`,
  `AuthAdmin/${SET_REFRESH_AUTH_ADMIN}`,
  `AuthAdmin/${SET_ADMIN_PAYMENT_INFO}`,
];
