import {adminServiceInstance} from '@/plugins/api_service/services';

const prefix = '/admin/auth'

export default {
  login: (credential) => adminServiceInstance.post(`${prefix}/login`, {}, credential),
  confirmLogin: (data) => adminServiceInstance.post(`${prefix}/confirm-login`, {}, data),
  resendAuthCode: (id, data) => adminServiceInstance.put(`${prefix}/resend-auth-code/${id}`, {}, data),
  logout: () => adminServiceInstance.post(`${prefix}/logout`, {}, {}),
  getAccountPaymentInfo: (query) => adminServiceInstance.get(`${prefix}/get-account-usage-info`, query),
}
