<template>
  <component :is="tag"
             @click.native="hideSidebar"
             v-bind="$attrs"
             custom
             v-slot="{ navigate, href, isActive }"
             router-link-exact-active
  >
    <li @click="navigate" :class="{ 'active': isActive && $attrs.to != ''  }">
      <a class="nav-link" :href="href" @click="navigate">
        <slot>
          <i v-if="link.icon" :class="link.icon"></i>
          <p>{{ link.name }}</p>
        </slot>
      </a>
    </li>
  </component>
</template>
<script>
export default {
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true
    }
  },
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: '',
          path: '',
          icon: ''
        }
      }
    },
    tag: {
      type: String,
      default: 'router-link'
    }
  },
  methods: {
    hideSidebar() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}
</script>
<style>
</style>
