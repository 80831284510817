<template>
  <b-card
    class="mt-2"
    no-body>
    <b-card-body>
        <b-skeleton-table
            v-if="loading"
            :rows="5"
            :columns="tableColumnLoading"
            :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
            <div :class="{'table-responsive': responsive}" v-else>
                <slot>

                </slot>
            </div>
    </b-card-body>
    <!-- pagination -->
    <slot name="pagination">
        <b-card-text>
            <div class="mt-3" v-if="showPagination">
                <b-pagination
                    :value="currentPage"
                    v-bind="$props"
                    v-on="$listeners"
                    align="center"></b-pagination>
            </div>
        </b-card-text>
    </slot>

  </b-card>
</template>

<script>
export default {
    name: 'CoreTableResult',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        tableColumnLoading: {
            type: Number,
            default: 5
        },
        currentPage: [Number, String],
        totalRows: {
            type: Number,
            default: 10,
        },
        perPage: {
            type: Number,
            default: 5,
        },
        showPagination: {
            type: Boolean,
            default: true,
        },
        responsive:{
            type: Boolean,
            default: true,
        }
    },
    computed: {
        page: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        changePage(value) {
            console.log('change-page', value)
        }
    }
}
</script>
