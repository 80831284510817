import adminDashboardLayout from "@/layouts/Admin/TheDashboardLayout";
import corporationRoute from "./corporation";
import offerRoute from "./offer";
import jobApplicantRoute from "./job-applicant";
import {checkAuthAdminLoginBeforeTwoFactor} from "../../../helpers/authenticate";
import adminLogin from "../../../middlewares/admin/authLogin";

export default [
  {
    path: "/tkiad", // manage == corpoad
    component: () => import("@/layouts/Admin/TheContent"),
    beforeEnter: (to, from, next) => {
      if (to.path == "/tkiad" || to.path == "/tkiad/")
        next({ name: "OffersSearch" });
      else next();
    },
    children: [
      // login route here
      {
        path: "login",
        name: "AdminLogin",
        component: () =>
          import(
            /* webpackChunkName: "tkiad-login-view" */ "@/views/Admin/Auth/LoginAdminView.vue"
            ),
        meta: {
          middleware: [adminLogin],
        },
      },
      {
        path: "send-email-reset-password",
        name: "AdminSendEmailResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "tkiad-send-email-reset-password" */ "@/views/Admin/ResetPassword/AdminSendEmailResetPasswordView.vue"
            ),
        meta: {
          middleware: [adminLogin],
        },
      },
      {
        path: "reset-password",
        name: "AdminResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "tkiad-reset-password" */ "@/views/Admin/ResetPassword/AdminResetPasswordView.vue"
            ),
        meta: {
          middleware: [adminLogin],
        },
      },
      {
        path: "two-factor-auth",
        name: "AdminTwoFactorAuth",
        component: () =>
          import(
            /* webpackChunkName: "tkiad-two-factor-auth" */ "@/views/Admin/Auth/AdminTwoFactorAuthView.vue"
            ),
        beforeEnter: (to, from, next) => {
          if (!checkAuthAdminLoginBeforeTwoFactor())
            next({ name: "AdminLogin" });
          else next();
        },
        meta: {
          middleware: [adminLogin],
        },
      },
      {
        path: "block",
        name: "AdminBlock",
        component: () =>
          import(
            /* webpackChunkName: "tkiad-block" */ "@/views/Admin/Auth/AdminBlockView.vue"
            ),
        meta: {
          middleware: [adminLogin],
        },
      },
      // admin menu route
      {
        path: "/",
        name: "AdminHome",
        component: adminDashboardLayout,
        props: { title: "Admin" },
        children: [corporationRoute, offerRoute, jobApplicantRoute],
      },
      {
        path: "unauthorized",
        name: "AdminPageUnauthorized",
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/401.vue"
          ),
      },
      {
        path: "forbidden",
        name: "AdminPageForbidden",
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/403.vue"
          ),
      },
      {
        path: ":pathMatch(.*)*",
        name: "AdminPageNotFound",
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/404.vue"
          ),
      },
    ],
  },
];
