<template>
    <multiselect
        v-model="select"
        :options="localOptions"
        v-bind="$props"
        :multiple="true"
        :taggable="true"
        track-by="id"
        label="text"
        :placeholder="options ? $t('panel.label_please_choose') : $t('panel.label_no_data')"
        :selectLabel="''"
        :deselectLabel="''"
        :selectedLabel="''"
        :disabled="disabled"
    >
        <template slot="option" slot-scope="props">
            {{ props.option.text }}
        </template>
        <template slot="noOptions">
            {{ $t("panel.label_no_data") }}
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'CoreSelectData',
    components: {
        Multiselect
    },
    props: {
        options: Array,
        value: Array,
        error: {
            type: Boolean,
            default: false
        },
        showPlaceHolder: {
            type: Boolean,
            default: true
        },
        hideSearch: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localOptions: []
        }
    },
    created() {
        this.initializeOption();
    },
    mounted() {
    },
    computed: {
        select: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        labelDefault() {
            let data = [{
                id: '',
                text:  this.options ? this.$t("panel.label_please_choose") : this.$t("panel.label_no_data"),
            }]
            return data;
        }
    },
    methods: {
        initializeOption() {
            this.localOptions = [];
            if (this.showPlaceHolder) {
                this.localOptions = [...this.labelDefault, ...this.options];
            } else {
                this.localOptions = [...this.options];
            }
        }
    },
    watch: {
        error: function(value) {
            if (value) {
                $(this.$el).find('.multiselect__tags').addClass('is-invalid');
            } else {
                $(this.$el).find('.multiselect__tags').removeClass('is-invalid');
            }
        },
        options: {
            deep: true,
            handler: function () {
                this.initializeOption();
                this.$emit('input', []);
            }
        }
    },
}
</script>
<style lang="scss">
@import "../../assets/sass/component/vue-multiselect.scss";
</style>
