/* eslint-disable */ 
const setValue = (e, decimal, withOutZeroLeading) => {
  if (!decimal) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  } else {
    e.target.value = e.target.value.replace(/[^0-9\.]/g, '');
  }
  if(withOutZeroLeading){
    e.target.value = Number(e.target.value) === 0 ? 0 : e.target.value.replace(/^0+/g, '');
  }
}

export default {
  bind: function(el, binding) {
    let { decimal, withOutZeroLeading } = binding.modifiers;

    el.handler = function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    };

    el.addEventListener('keypress', el.handler)
    el.addEventListener('change', (e) => {
      setValue(e, decimal, withOutZeroLeading);
    })
    el.addEventListener('blur', (e) => {
      setValue(e, decimal, withOutZeroLeading);
    })
  },
  unbind: function(el) {
    el.removeEventListener('keypress', el.handler)
  }
}
