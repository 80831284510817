var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card",
        {
          staticClass: "core__client__card__form border-0",
          attrs: { "no-body": "" },
        },
        [
          _c(
            "b-form",
            _vm._g({ attrs: { autocomplete: "false" } }, _vm.$listeners),
            [
              _c(
                "div",
                {
                  staticClass: "core__client__card__form-content",
                  class: _vm.addClassBoxCentent,
                },
                [_vm._t("content")],
                2
              ),
              _vm._v(" "),
              _vm.useLoading
                ? _c("b-overlay", {
                    attrs: { show: _vm.loading, opacity: "0.7", "no-wrap": "" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "core__client__card__form-actions" },
                [
                  _vm._t(
                    "actions",
                    function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: _vm.backRoute } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2 btn-w-100 mr-auto",
                                    attrs: {
                                      type: "button",
                                      variant: "secondary",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("panel.label_go_back")) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "create-action-box" },
                              [
                                _vm.edit
                                  ? _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName:
                                              "v-b-modal.removeClientDialog",
                                            modifiers: {
                                              removeClientDialog: true,
                                            },
                                          },
                                        ],
                                        staticClass: "ml-2 btn-w-100",
                                        attrs: {
                                          type: "button",
                                          variant: "danger",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$t("panel.label_remove")
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName:
                                          "v-b-modal.confirmClientDialog",
                                        modifiers: {
                                          confirmClientDialog: true,
                                        },
                                      },
                                    ],
                                    staticClass: "ml-2 btn-w-100",
                                    attrs: { variant: "primary" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("panel.label_register")) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    { goBackSearching: _vm.goBackSearching }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("modals", function () {
        return [
          _c("core-modal-confirm", {
            attrs: { idComponent: "confirmClientDialog" },
            on: { ok: _vm.handleOk },
          }),
          _vm._v(" "),
          _c("core-modal-confirm", {
            attrs: { remove: "", idComponent: "removeClientDialog" },
            on: { ok: _vm.handleRemove },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }