var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "core__card__form-content" },
            [_vm._t("content")],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "p-5" }, [
            _c(
              "div",
              { staticClass: "core__card__form-actions" },
              [_vm._t("button-action")],
              2
            ),
          ]),
        ]),
      ],
      _vm._v(" "),
      _vm._t("modals", function () {
        return [
          _c("core-modal-confirm", {
            attrs: { idComponent: "confirmDialog" },
            on: { ok: _vm.handleOk },
          }),
          _vm._v(" "),
          _c("core-modal-confirm", {
            attrs: { remove: "", idComponent: "removeDialog" },
            on: { ok: _vm.handleRemove },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }