var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "client__mobile__menu__wrapper" }, [
    _c(
      "ul",
      { staticClass: "nav nav-pills flex-column mb-auto" },
      [
        _c(
          "li",
          {
            staticClass: "nav-item w-100 py-2 border",
            staticStyle: { "border-radius": "5px" },
          },
          [
            _c(
              "a",
              {
                staticClass: "nav-link user__account text-white",
                attrs: { href: "#" },
              },
              [
                _c("i", { staticClass: "fas fa-user nav-icon-size" }),
                _vm._v(" "),
                _c("span", { staticClass: "user__name" }, [
                  _vm._v(_vm._s(_vm.GET_MEMBER_INFO.name)),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        [
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "b-button",
                {
                  staticClass: "nav-link text-white",
                  attrs: { block: "" },
                  on: { click: _vm.handleLogout },
                },
                [
                  _c("i", { staticClass: "fas fa-power-off nav-icon-size" }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("panel.label_logout"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }