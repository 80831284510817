<template>
  <nav class="navbar navbar-expand-lg d-lg-none"
       :class="!hideTopNav ? 'd-lg-block' : '' "
  >
    <div class="container-fluid">
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{'toggled': $sidebar.showSidebar}"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>

export default {
  name: 'TheAdminTopNavbar',
  computed: {
    routeName() {
      const {name} = this.$route
      return this.capitalizeFirstLetter(name)
    },
    hideTopNav() {
      return true
    }
  },
  data() {
    return {
      activeNotifications: false
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    // async logoutAccount() {
    //     try {
    //         await this.$store.dispatch('Auth/logout');
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
  }
}

</script>
<style>

</style>
