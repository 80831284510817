import moment from "moment";
import _ from "lodash";
import {
  GET_ADMIN_EMAIL,
  GET_ADMIN_ID,
  GET_ADMIN_INFO,
  GET_ADMIN_IS_AUTH,
  GET_ADMIN_ROLE,
  GET_ADMIN_TYPE,
  GET_AUTH_ADMIN_TOKEN,
} from "./types";

export default {
  [GET_AUTH_ADMIN_TOKEN](state) {
    return state.token;
  },

  [GET_ADMIN_IS_AUTH]: (state) => {
    let statusLogin = state.accountLogin.isAuth,
      token = state.token,
      validExpiredAt = true;

    let pointNowTime = moment(),
      expiredAt = moment(parseInt(state.expired_at)).subtract(5, "minutes");

    if (!state.expired_at || pointNowTime.isSameOrAfter(expiredAt)) {
      validExpiredAt = false;
    }

    return !!(token && validExpiredAt && statusLogin == "success");
  },

  [GET_ADMIN_ID]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(state.accountLogin.info, "id")
        ? state.accountLogin.info.id
        : "";
    }
    return "";
  },

  [GET_ADMIN_EMAIL]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "email"
      )
        ? state.accountLogin.info.email
        : "";
    }
    return "";
  },

  [GET_ADMIN_ROLE]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "role_id"
      )
        ? state.accountLogin.info.role_id
        : "";
    }
    return "";
  },

  [GET_ADMIN_TYPE]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "type_id"
      )
        ? state.accountLogin.info.type_id
        : "";
    }
    return "";
  },

  [GET_ADMIN_INFO]: (state) => {
    return state.accountLogin.info;
  },
};
