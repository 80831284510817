<template>
  <b-card
      class="mt-2"
      no-body
  >
    <b-card-body class="mb-0 pb-0">
      <b-skeleton-table
          v-if="loading"
          :rows="5"
          :columns="tableColumnLoading"
          :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <div v-else>
        <!-- print total records -->
        <div v-if="showTotalRows" class="show__total__result" style="gap: 10px">
          <span>{{ $t('panel.label_total_searching') }}</span>
          <span>{{ `${totalRows}${$t('panel.label_unit_search')}` }}</span>
        </div>
        <slot name="filter"></slot>

        <div v-scroll-horizontal :class="{'table-responsive': responsive}">
          <!-- open slot default -->
          <slot></slot>
        </div>
      </div>
    </b-card-body>
    <!-- pagination -->
    <slot name="pagination">
      <b-card-text>
        <div class="mt-3" v-if="showPagination">
          <base-pagination
              :value="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"

              v-bind="$props"
              v-on="$listeners"
              align="center"
          />
        </div>
      </b-card-text>
    </slot>

  </b-card>
</template>

<script>
export default {
  name: 'BaseTableResult',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tableColumnLoading: {
      type: Number,
      default: 5
    },
    currentPage: [Number, String],
    totalRows: {
      type: Number,
      default: 10,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showTotalRows: {
      type: Boolean,
      default: true,
    },
    responsive: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    page: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    changePage(value) {
      console.log('change-page', value)
    }
  }
}
</script>
<style scoped lang="scss">
.show__total__result {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}
</style>