import {memberServiceInstance} from '@/plugins/api_service/services';

const prefix = '/member/auth'

export default {
    login: (credential) => memberServiceInstance.post(`${prefix}/login`, {}, credential),
    confirmLogin: (data) => memberServiceInstance.post(`${prefix}/confirm-login`, {}, data),
    resendAuthCode: (id, data) => memberServiceInstance.put(`${prefix}/resend-auth-code/${id}`, {}, data),
    logout: () => memberServiceInstance.post(`${prefix}/logout`, {}, {}),
}
