<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <b-col cols="12">
          <slot name="header-content-view">
            <p class="h3 font-weight-bold title__page">{{ titlePage }}</p>
          </slot>

          <!-- base alert system -->
          <base-alert-system
              v-if="useAlertSystem && alert.show"
              @hide-alert="() => {this.alert.show = false}"
              :message="alert.message"
              :error="alert.error"
          />

          <!-- wrap it -->
          <slot>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </slot>

          <slot name="footer-content-view"></slot>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BaseAdminContentView",
  props: {
    useAlertSystem: {
      type: Boolean,
      default: true
    },
    titlePage: {
      type: String,
      default: 'Title of Page'
    }
  },
  computed: {
  },
  data() {
    return {
      alert: {
        show: false,
        error: false,
        message: ''
      }
    }
  },
  created() {
    document.title = this.titlePage;
  },
  mounted() {
    this.$eventBus.$on('update-alert-system', this.handleAlertSystem)
  },
  methods: {
    handleAlertSystem({error, message}) {
      this.alert = {
        show: true,
        error: error,
        message: message
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.title__page {
  padding: 0;
  margin: .8rem 0;
  text-align: center;
}
</style>
