var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    _vm._g(
      {
        attrs: {
          id: _vm.idComponent,
          centered: "",
          "header-bg-variant": _vm.color,
          title: _vm.$t("panel.label_confirm_title"),
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [
                  _vm._t("content", function () {
                    return [
                      _c("p", { staticClass: "confirmText text-left" }, [
                        _vm._v(_vm._s(_vm.confirmText)),
                      ]),
                    ]
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex w-100 justify-content-end" },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { variant: _vm.color },
                          on: {
                            click: function ($event) {
                              return ok()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.textButtonConfirm) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("b-button", { on: { click: cancel } }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("panel.label_cancel_btn_modal")) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }