<template>
    <transition name="fade" mode="out-in">
        <div class="management__content">
            <!-- Alert system -->
            <core-alert-system
                v-if="useAlertSystem && alert.show"
                @hide-alert="() => {this.alert.show = false}"
                :message="alert.message"
                :error="alert.error"
                />

            <section class="content">
                <b-container fluid>
                    <slot name="title-header">
                        <p class="h3 font-weight-bold management__content-title__page">{{ titlePage }}</p>
                    </slot>
                    <slot></slot>
                </b-container>
            </section>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'ManageContent',
    props: {
        useAlertSystem: {
            type: Boolean,
            default: true
        },
        titlePage: {
            type: String,
            default: 'Title of Page'
        }
    },

    data() {
        return {
            alert: {
                show: false,
                error: false,
                message: ''
            }
        }
    },

    mounted() {
        this.$eventBus.$on('update-alert-system', this.handleAlertSystem)
    },
    methods: {
        handleAlertSystem({ error, message }) {
            this.alert = {
                show: true,
                error: error,
                message: message
            };
        }
    }
}
</script>
