import Constants from '../plugins/constants'
import { _ } from "core-js";
import moment from 'moment';
import axios from 'axios';
import StorageManage from './storageManage';
import { ApplicationStorageToken } from '../plugins/setting';

export function buildHeaders(headers) {
    let requestHeaders = getDefaultRequestHeaders();

    if (!headers) {
        return requestHeaders;
    }
    if (Array.isArray(headers)) {
        return requestHeaders;
    }
    for (let keyname in headers) {
        requestHeaders[keyname.toLowerCase()] = headers[keyname];
    }

    return requestHeaders;
}

function getDefaultRequestHeaders() {
  return {
    page: 1,
    timeout: Constants.defaultRequestTimeOut,
    "Content-Type": "application/json",
    // "Authorization": "Bearer " + StorageManage.getStorage(ApplicationStorageToken),
  }
}

// buildUrl with query
export function buildUrl(url, query) {
    if (!query || query === {} || Array.isArray(query)) {
        return url;
    }
    for (const keyname in query) {
        if (typeof query[keyname] == 'object') {
            continue;
        }
        const param = `{${keyname}}`;
        while (url.includes(param)) {
            url = url.replace(param, query[keyname]);
        }
    }
    const queries = [];
    for (const keyname in query) {
        if (typeof query[keyname] !== 'object') {
            queries.push(`${keyname}=${query[keyname]}`);
            continue;
        }
        if (Array.isArray(query[keyname])) {
            queries.push(`${keyname}=${query[keyname].join(',')}`);
        } else {
            queries.push(`${keyname}=${JSON.stringify(query[keyname])}`);
        }
    }
    return `${url}?${queries.join('&')}`;
}

// convert object contants like  { foo: {id: 1, text: 'text'} } => [{ id: 1, text: 'text }] --> use this for options-select2
export const formatConstantToArray = function (object) {
    if (typeof object != 'object') {
        throw 'Parameter must be an OBJECT type!';
    }
    let array = [];
    for (const prop in object) {
        array.push({
            id: object[prop].value,
            text: object[prop].text
        });
    }
    return array;
}

export function awaitAll(promises) {
    if (!Array.isArray(promises)) throw new Error('The param should be an Array Type!');
    return new Promise((resolve, reject) => {
        Promise.all(promises).then(result => resolve(result)).catch(err =>{console.log(err); reject(err)});
    });
}


export function awaitAllsettled(listPromise) {
    if (!Array.isArray(listPromise)) throw new Error('The param should be an Array Type!');
    return Promise.allSettled(listPromise).then(result => Promise.resolve(result)).catch(err => Promise.reject(err));
}

export async function validatePromises(listPromise, convert) {

    let result = {
        validateFail: false,
        failFields: {}
    }
    // fulfilled/ reject
    if (!Array.isArray(listPromise)) throw new Error('The param should be an Array Type!');
    let response = await Promise.allSettled(listPromise);
    // if hava one reject => auto FALSE
    result.validateFail = response.some(item => item.status === 'rejected');
    if (result.validateFail) {
        response.forEach(item => {
            if (item.status === "rejected" && item.reason.failValidation) {
                result.failFields = { ...item.reason.data, ...result.failFields };
            }
        });
    }
    if (typeof convert === 'object' && !_.isEmpty(convert) && result.validateFail) {
        for (let prop in convert) {
            convert[prop].forEach(item => {
                console.log(item)
                if (Object.prototype.hasOwnProperty.call(result.failFields, item)) {
                    console.log(item, result.failFields[item])
                    result.failFields[`${prop}.${item}`] = result.failFields[item];
                }
            })
        }
    }
    console.log(result.failFields)
    return result;
}


export function findObjectRecursiveArr(array, key, value) {
    var o;
    array.some(function iter(a) {
        if (a[key] === value) {
            o = a;
            return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
    });
    return o;
}

export function formatFormRequest(objectForm) {
    let form = new FormData();

    const convertNullToEmpty = (value) => value != null ? value : "";

    for (let dataKey in objectForm) {
        if (Array.isArray(objectForm[dataKey])) {
            let dataArrayCollection = convertNullToEmpty(objectForm[dataKey]);
            if (dataArrayCollection) {
                dataArrayCollection  = JSON.stringify(dataArrayCollection);
            }

            form.append(dataKey, dataArrayCollection);
        } else if (typeof objectForm[dataKey] === 'object') {
            for (let prop in objectForm[dataKey]) {
                form.append(`${dataKey}[${prop}]`, convertNullToEmpty(objectForm[dataKey][prop]));
            }
        } else {
            form.append(dataKey, convertNullToEmpty(objectForm[dataKey]));
        }
    }
    return form;
}


export function autoDownloadFromUrl(url, fileName = null) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    if (fileName) {
        element.setAttribute('download', fileName);
    }

    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function convertToPrincipleFormatDateTime(dataDate, format = 'YYYY/MM/DD') {
    if (moment(dataDate).isValid()) {
        return moment(dataDate).format(format);
    }
    return '';
}

export function convertNullToEmpty(value) {
    return (value != null && value != undefined) ? value : "";
}

export const buildBasicInfoFormData = ({ basic_info }) => {
    let form = new FormData();

    if (!_.isEmpty(basic_info)) {
        for (let dataKey in basic_info) {
            switch (dataKey) {
                case 'member_type':
                    if (!_.isEmpty(basic_info[dataKey])) {
                        basic_info[dataKey].forEach((item, idx) => {
                            form.append(`basic_info[${dataKey}][${idx}]`, convertNullToEmpty(item))
                        });
                    }
                    break;

                case 'document':
                    basic_info[dataKey].forEach((item, idx) => {
                        if (item) {
                            form.append(`basic_info[${dataKey}][${idx}][file]`, convertNullToEmpty(item.file));
                        }
                    })
                    break;
                case 'document_url':
                    if (basic_info[dataKey] && Array.isArray(basic_info[dataKey])) {
                        basic_info[dataKey].forEach((item, idx) => {
                            for (let prop in item) {
                                form.append(`basic_info[${dataKey}][${idx}][${prop}]`, convertNullToEmpty(item[prop]));
                            }
                        });
                    }

                    break;
                default:
                    form.append(`basic_info[${dataKey}]`, convertNullToEmpty(basic_info[dataKey]));
                    break;
            }
        }
    }

    return form;
}

export const buildExtendInfoformData = ({ extend_info }) => {
    let form = new FormData();

    if (!_.isEmpty(extend_info)) {
        for (let keyData in extend_info) {

            if (Array.isArray(extend_info[keyData].value)) {
                if (extend_info[keyData].value.length) {
                    extend_info[keyData].value.forEach((data, idx) => {
                        form.append(`extend_info[${keyData}][value][${idx}]`, convertNullToEmpty(data));
                    })
                } else {
                    form.append(`extend_info[${keyData}][value]`, '');
                }

            } else if (typeof extend_info[keyData].value == 'object') {
                for (let childKey in extend_info[keyData].value) {
                    form.append(`extend_info[${keyData}][value][${childKey}]`, convertNullToEmpty(extend_info[keyData].value[childKey]));
                }
            } else {
                form.append(`extend_info[${keyData}][value]`, convertNullToEmpty(extend_info[keyData].value));
            }
        }
    }

    return form;
}


export const downloadBlobFile = (blob, filename =  'test_download') => {
    const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
}

export const downloadStorageGoogleFile = (url, fileName = null) => {
    axios.get(url)
        .then(response => response.blob())
        .then((blob) => {
            const blobURL = URL.createObjectURL(blob);
            const element = document.createElement('a');
            element.href = blobURL;
            element.style.display = 'none';

            if (fileName && fileName.length) element.download = fileName;
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });
}

export const isPropExistInObject = (object, key) => Object.prototype.hasOwnProperty.call(object, key);

export const AsyncComponent = ({ urlComponent, delay = 200, timeout = 500}) => ({
    component: import(`${urlComponent}`),
    delay: delay,
    timeout: timeout,
});
/* eslint-disable */
export const pipeline = (...functions) => (value) => {
    return functions.reduce(async (currentValue, currentFunction) => {
        try {
            return await currentFunction(currentValue);

        } catch (err) {

        }
    }, value);
}

export function buildGcsFormData(data) {
    const formData = new FormData();
    Object.entries(data.fields)
        .forEach(([key, val]) => formData.append(key, val));
    formData.append('content-type', data.file.type);
    formData.append('file', data.file);

    return formData;
}

export const __typeOf = (value) => {
    return Object.prototype.toString.call(value).slice(8, -1)
}
