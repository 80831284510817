var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "client__header__wrapper dance",
      attrs: { id: "client__header" },
    },
    [
      _c("b-container", [
        _c("div", { staticClass: "d-block" }, [
          _c("div", { staticClass: "client__header__wrapper-branch" }, [
            _c("div", { staticClass: "client__header__wrapper-branch__logo" }, [
              _c("img", {
                attrs: { alt: "logo", src: _vm.logoImg },
                on: { click: _vm.redirectToHomePage },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "client__header__wrapper-branch__name-system__area-logo dance",
              },
              [
                _c("img", {
                  attrs: { alt: "logo", src: _vm.logoImg },
                  on: { click: _vm.redirectToHomePage },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "client__header__wrapper-branch__name" }, [
              _c(
                "div",
                {
                  staticClass:
                    "client__header__wrapper-branch__name-system__area",
                },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("panel.label_service_title"))),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "client__header__wrapper-information__user" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "custom-button-menu logout",
                    on: { click: _vm.handleLogout },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("panel.logout")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "client__header__wrapper-branch__icon__menu" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.client-sidebar-menu",
                        modifiers: { "client-sidebar-menu": true },
                      },
                    ],
                    staticClass: "m-0",
                    attrs: { variant: "info" },
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.client-sidebar-menu",
                          modifiers: { "client-sidebar-menu": true },
                        },
                      ],
                      staticClass: "fas fa-bars",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-sidebar",
                  {
                    attrs: {
                      id: "client-sidebar-menu",
                      title: "メニュー",
                      "bg-variant": "dark",
                      "text-variant": "light",
                      shadow: "",
                      backdrop: "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "px-3 py-2" },
                      [_c("client-mobile-menu")],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }