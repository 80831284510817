var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-date-picker", {
    attrs: {
      popover: { visibility: "click" },
      is24hr: _vm.is24hr,
      mode: _vm.mode,
      "model-config": _vm.modelConfig,
      masks: _vm.mask,
      locale: "ja",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ inputValue, inputEvents }) {
          return [
            _c("div", { staticClass: "d-block" }, [
              _c(
                "div",
                { staticClass: "d-flex w-100 align-items-center g-5" },
                [
                  _c(
                    "input",
                    _vm._g(
                      {
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.isFailValidation },
                        attrs: {
                          placeholder: _vm.placeholder,
                          disabled: _vm.disabled,
                        },
                        domProps: { value: inputValue },
                      },
                      inputEvents
                    )
                  ),
                  _vm._v(" "),
                  _vm.showIconAppend
                    ? _c("i", { staticClass: "fa-solid fa-calendar" })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.isFailValidation
                ? _c("div", { staticClass: "is-invalid-feedback-message" }, [
                    _vm._v(_vm._s(_vm.textValidation)),
                  ])
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v
      },
      expression: "date",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }