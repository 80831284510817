import {
  ACT_MEMBER_CONFIRM_LOGIN,
  ACT_MEMBER_LOGIN,
  ACT_MEMBER_LOGOUT,
  ACT_FORCE_FRESH_AUTH_MEMBER,
  SET_MEMBER_EXPIRED_AT,
  SET_MEMBER_INFO,
  SET_MEMBER_TOKEN,
  SET_REFRESH_AUTH_MEMBER,
  SET_STATUS_MEMBER,
} from "./types";

import storage from "@/helpers/storageManage";
import authMemberService from "@/services/member/auth_member";
import {
  ApplicationMemberAccountInfo,
  ApplicationMemberIsAuth,
  ApplicationMemberStorageToken,
  ApplicationMemberTokenExpiredAt,
} from "@/plugins/setting";

import moment from "moment";

export default {
  [ACT_MEMBER_LOGIN]: async ({ commit }, payload) => {
    let response = await authMemberService.login(payload);
    let { user_data, access_token, expires_in } = response;
    let expiredAt = moment().add(expires_in, "minutes").valueOf(); // miliseconds
    // commit
    commit(SET_MEMBER_TOKEN, access_token);
    commit(SET_MEMBER_INFO, user_data);
    commit(SET_MEMBER_EXPIRED_AT, expiredAt);
    commit(SET_STATUS_MEMBER, "success");

    storage.setStorage({
      [ApplicationMemberStorageToken]: access_token,
      [ApplicationMemberIsAuth]: "success",
      [ApplicationMemberTokenExpiredAt]: expiredAt,
      [ApplicationMemberAccountInfo]: JSON.stringify(user_data),
    });
    return response;
  },

  // [ACT_MEMBER_CONFIRM_LOGIN]: async ({ commit }, payload) => {
  //   let response = await authMemberService.confirmLogin(payload);
  //   let { user_data, access_token, expires_in } = response;
  //   let expiredAt = moment().add(expires_in, "minutes").valueOf(); // miliseconds
  //   // commit
  //   commit(SET_MEMBER_TOKEN, access_token);
  //   commit(SET_MEMBER_INFO, user_data);
  //   commit(SET_MEMBER_EXPIRED_AT, expiredAt);
  //   commit(SET_STATUS_MEMBER, "success");

  //   storage.setStorage({
  //     [ApplicationMemberStorageToken]: access_token,
  //     [ApplicationMemberIsAuth]: "success",
  //     [ApplicationMemberTokenExpiredAt]: expiredAt,
  //     [ApplicationMemberAccountInfo]: JSON.stringify(user_data),
  //   });
  //   return response;
  // },

  [ACT_MEMBER_LOGOUT]: async ({ dispatch }) => {
    await authMemberService.logout();
    dispatch(ACT_FORCE_FRESH_AUTH_MEMBER);
  },

  [ACT_FORCE_FRESH_AUTH_MEMBER]: async ({ commit }) => {
    storage.removeStorage(ApplicationMemberStorageToken);
    storage.removeStorage(ApplicationMemberIsAuth);
    storage.removeStorage(ApplicationMemberTokenExpiredAt);
    storage.removeStorage(ApplicationMemberAccountInfo);
    commit(SET_REFRESH_AUTH_MEMBER);
  },
};
