var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-block mb-5" }, [
    _c("div", { staticClass: "p-2", staticStyle: { background: "#BF967F" } }, [
      _c(
        "h4",
        {
          staticClass: "font-weight-bold m-0",
          staticStyle: { color: "#FFFFFF" },
        },
        [_vm._v(_vm._s(_vm.header))]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "core__card__form-content" }, [
      _c(
        "div",
        { staticClass: "core__card__form-content" },
        [_vm._t("content")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }