import {
  ACT_ADMIN_CONFIRM_LOGIN,
  ACT_ADMIN_LOGIN,
  ACT_ADMIN_LOGOUT,
  ACT_FORCE_FRESH_AUTH_ADMIN,
  SET_ADMIN_EXPIRED_AT,
  SET_ADMIN_INFO,
  SET_ADMIN_TOKEN,
  SET_REFRESH_AUTH_ADMIN,
  SET_STATUS_ADMIN,
} from "./types";

import storage from "@/helpers/storageManage";
import authAdminService from "@/services/admin/auth_admin";
import {
  ApplicationAdminAccountInfo,
  ApplicationAdminIsAuth,
  ApplicationAdminStorageToken,
  ApplicationAdminTokenExpiredAt,
} from "@/plugins/setting";

import moment from "moment";

export default {
  [ACT_ADMIN_LOGIN]: async ({ commit }, payload) => {
    let response = await authAdminService.login(payload);
    commit(SET_ADMIN_INFO, response);
    storage.setStorage({
      [ApplicationAdminAccountInfo]: JSON.stringify(response),
    });
    return response;
  },

  [ACT_ADMIN_CONFIRM_LOGIN]: async ({ commit }, payload) => {
    let response = await authAdminService.confirmLogin(payload);
    let { user_data, access_token, expires_in } = response;
    let expiredAt = moment().add(expires_in, "minutes").valueOf(); // miliseconds
    // commit
    commit(SET_ADMIN_TOKEN, access_token);
    commit(SET_ADMIN_INFO, user_data);
    commit(SET_ADMIN_EXPIRED_AT, expiredAt);
    commit(SET_STATUS_ADMIN, "success");

    storage.setStorage({
      [ApplicationAdminStorageToken]: access_token,
      [ApplicationAdminIsAuth]: "success",
      [ApplicationAdminTokenExpiredAt]: expiredAt,
      [ApplicationAdminAccountInfo]: JSON.stringify(user_data),
    });
    return response;
  },

  [ACT_ADMIN_LOGOUT]: async ({ dispatch }) => {
    await authAdminService.logout();
    dispatch(ACT_FORCE_FRESH_AUTH_ADMIN);
  },

  [ACT_FORCE_FRESH_AUTH_ADMIN]: async ({ commit }) => {
    storage.removeStorage(ApplicationAdminStorageToken);
    storage.removeStorage(ApplicationAdminIsAuth);
    storage.removeStorage(ApplicationAdminTokenExpiredAt);
    storage.removeStorage(ApplicationAdminAccountInfo);
    commit(SET_REFRESH_AUTH_ADMIN);
  },
};
