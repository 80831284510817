
// manage storage name
export const ApplicationManageStorageToken = "app.ichigei-project.manage-token";
export const ApplicationManageTokenExpiredAt = "app.ichigei-project.manage-token-expired-at";
export const ApplicationManageIsAuth = "app.ichigei-project.manage-is-auth";
export const ApplicationManageAccountInfo = "app.ichigei-project.manage-account-info";
export const ApplicationManagePaymentInfo = "app.ichigei-project.manage-payment-info";
export const ApplicationManageWithdrawFlag = "app.ichigei-project.manage-withdraw-flag";

export const ApplicationStorageToken = "app.ichigei-project.token";
export const ApplicationIsAuth = "app.ichigei-project.isAuth";
export const ApplicationMembershipPeriod = "app.ichigei-project.membership-period";
export const ApplicationManageSaveOrganizationFlag = "app.ichigei-project.save-organization-flag";
export const ApplicationManageSaveOrganizationId = "app.ichigei-project.save-organization-id";
export const ApplicationStorageUserDataName = "app.ichigei-project.userData";
export const ApplicationExpiredAt = "app.ichigei-project.expired-at";
export const ApplicationLang = "app.ichigei-project.language";
export const ApplicationPermissions = "app.ichigei-project.permissions";
export const ApplicationStartLifeTime = "app.ichigei-project.start-life-time";
export const ApplicationEndLifeTime = "app.ichigei-project.end-life-time";
export const MaxLifeTimeMinutes = 2; // minutes
export const AppSecondsSinceLastAccess = "app.ichigei-project.seconds-since-last-access";
export const AppEndTimeActivity = "app.ichigei-project.end-time-activity";

// admin storage name
export const ApplicationAdminStorageToken = "app.ichigei-project.admin-token";
export const ApplicationAdminTokenExpiredAt = "app.ichigei-project.admin-token-expired-at";
export const ApplicationAdminIsAuth = "app.ichigei-project.admin-is-auth";
export const ApplicationAdminAccountInfo = "app.ichigei-project.admin-account-info";
export const ApplicationAdminPaymentInfo = "app.ichigei-project.admin-payment-info";
export const ApplicationAdminExpiredAt = "app.ichigei-project.admin-expired-at";

// member storage name
export const ApplicationMemberStorageToken = "app.ichigei-project.member-token";
export const ApplicationMemberTokenExpiredAt = "app.ichigei-project.member-token-expired-at";
export const ApplicationMemberIsAuth = "app.ichigei-project.member-is-auth";
export const ApplicationMemberAccountInfo = "app.ichigei-project.member-account-info";
export const ApplicationMemberPaymentInfo = "app.ichigei-project.member-payment-info";
export const ApplicationMemberExpiredAt = "app.ichigei-project.member-expired-at";

// client storage name
export const ApplicationClientStorageToken = "app.ichigei-project.client-token";
export const ApplicationClientTokenExpiredAt = "app.ichigei-project.client-token-expired-at";
export const ApplicationClientIsAuth = "app.ichigei-project.client-is-auth";
export const ApplicationClientAccountInfo = "app.ichigei-project.client-account-info";
