var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b({ staticClass: "d-block" }, "div", _vm.$attrs, false),
    [
      _c(
        "div",
        { staticClass: "d-flex w-100 align-items-center" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.date,
                expression: "date",
              },
              {
                name: "auto-format-date",
                rawName: "v-auto-format-date",
                value: { mode: _vm.mode },
                expression: "{ mode: mode }",
              },
            ],
            staticClass: "form-control input-date",
            class: { "is-invalid": _vm.isFailValidation },
            attrs: {
              placeholder: _vm.getPlaceHolder,
              disabled: _vm.disabled || _vm.isTurningOnDatePicker,
            },
            domProps: { value: _vm.date },
            on: {
              keypress: _vm.onKeypress,
              focus: _vm.removeValidation,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.date = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("v-date-picker", {
            attrs: {
              value: _vm.localDate,
              popover: { visibility: "click" },
              is24hr: _vm.is24hr,
              mode: _vm.mode,
              "minute-increment": _vm.minuteIncrement,
              locale: "ja",
            },
            on: {
              input: _vm.handlePickDate,
              popoverWillHide: function ($event) {
                _vm.isTurningOnDatePicker = false
              },
              popoverWillShow: function ($event) {
                _vm.isTurningOnDatePicker = true
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ togglePopover }) {
                  return [
                    _vm.showIconAppend
                      ? _c(
                          "button",
                          {
                            staticClass: "calendar__button__trigger",
                            attrs: { type: "button", disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return togglePopover.apply(null, arguments)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-calendar" })]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.textValidation
        ? _c("span", {
            directives: [
              {
                name: "validate-date-format",
                rawName: "v-validate-date-format",
                value: {
                  date: _vm.date,
                  mode: _vm.mode,
                  displayName: _vm.displayName,
                },
                expression:
                  "{\n      date: date, \n      mode: mode, \n      displayName: displayName, \n    }",
              },
            ],
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isFailValidation
        ? _c("div", { staticClass: "is-invalid-feedback-message" }, [
            _vm._v(_vm._s(_vm.textValidation)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }