var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _vm._t("header-content-view", function () {
                return [
                  _c("p", { staticClass: "h3 font-weight-bold title__page" }, [
                    _vm._v(_vm._s(_vm.titlePage)),
                  ]),
                ]
              }),
              _vm._v(" "),
              _vm.useAlertSystem && _vm.alert.show
                ? _c("base-alert-system", {
                    attrs: {
                      message: _vm.alert.message,
                      error: _vm.alert.error,
                    },
                    on: {
                      "hide-alert": () => {
                        this.alert.show = false
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default", function () {
                return [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [_c("router-view")],
                    1
                  ),
                ]
              }),
              _vm._v(" "),
              _vm._t("footer-content-view"),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }