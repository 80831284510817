var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "not__found__component" }, [
    _c("div", { staticClass: "not__found__component-content__error" }, [
      _c(
        "div",
        { staticClass: "not__found__component-content__error-img__present" },
        [_c("img", { attrs: { src: _vm.errorImg } })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "not__found__component-content__error-title__component",
        },
        [_vm._v("\n      このページは表示されていません\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "not__found__component-content__error-message" },
        [
          _vm._v(
            "\n      リンクが壊れているか、ページが削除された可能性があります。開こうとしているリンクが正しいことを確認してください。\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "not__found__component-content__error-action" },
        [
          _c(
            "router-link",
            { attrs: { to: { name: _vm.defaultHomeRoute } } },
            [
              _c("b-button", { attrs: { variant: "primary" } }, [
                _vm._v("メインページ"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "text-primary mt-2",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("戻ってくる")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }