var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-50 d-block" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _vm.loading
          ? _c("b-skeleton", { attrs: { type: "input" } })
          : _c("b-form-input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: _vm.inputCurrency,
                  expression: "inputCurrency",
                },
              ],
              class: { "is-invalid": _vm.isFailValidation },
              attrs: { disabled: _vm.disabled, maxlength: "13", trim: "" },
              model: {
                value: _vm.input,
                callback: function ($$v) {
                  _vm.input = $$v
                },
                expression: "input",
              },
            }),
        _vm._v(" "),
        _vm.showUnit
          ? _c("div", { staticClass: "ml-1" }, [
              _vm._v(_vm._s(_vm.$t("panel.circle"))),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isFailValidation,
            expression: "isFailValidation",
          },
        ],
        staticClass: "is-invalid-feedback-message",
      },
      [_vm._v(_vm._s(_vm.textValidation))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }