import DashboardLayout from "@/layouts/TheDashboardLayout";
import applyJobRoute from "./apply-job";
// import accountRoute from './account';
import managePaymentRoute from "./payment";
import { checkAuthManageLoginBeforeTwoFactor } from "@/helpers/authenticate";
import corporationInfoRoute from "./corporation-info";
import authPaid from "@/middlewares/manage/authPaid";
import membershipPeriod from "@/middlewares/manage/membershipPeriod";

export default [
  {
    path: "/corpoad", // manage == corpoad
    component: () => import("@/layouts/TheContent"),
    beforeEnter: (to, from, next) => {
      if (to.path == "/corpoad" || to.path == "/corpoad/")
        next({ name: "ListApplyJobManage" });
      else next();
    },
    children: [
      {
        path: "login",
        name: "ManageLogin",
        component: () =>
          import(
            /* webpackChunkName: "corpoad-login-view" */ "@/views/Manage/Auth/LoginManageView.vue"
          ),
        meta: {
          middleware: [membershipPeriod],
        },
      },
      {
        path: "send-email-reset-password",
        name: "ManageSendEmailResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "corpoad-send-email-reset-password" */ "@/views/Manage/ResetPassword/SendEmailResetPasswordView.vue"
          ),
        meta: {
          middleware: [membershipPeriod],
        },
      },
      {
        path: "reset-password",
        name: "ManageResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "corpoad-reset-password" */ "@/views/Manage/ResetPassword/ResetPasswordView.vue"
          ),
        meta: {
          middleware: [membershipPeriod],
        },
      },
      {
        path: "two-factor-auth",
        name: "ManageTwoFactorAuth",
        component: () =>
          import(
            /* webpackChunkName: "corpoad-two-factor-auth" */ "@/views/Manage/Auth/TwoFactorAuthView.vue"
          ),
        beforeEnter: (to, from, next) => {
          if (!checkAuthManageLoginBeforeTwoFactor())
            next({ name: "ManageLogin" });
          else next();
        },
        meta: {
          middleware: [membershipPeriod],
        },
      },
      {
        path: "account-block",
        name: "ManageAccountBlock",
        component: () =>
          import(
            /* webpackChunkName: "corpoad-account-block" */ "@/views/Manage/Auth/AccountBlockView.vue"
          ),
        meta: {
          middleware: [membershipPeriod],
        },
      },
      {
        path: "register/:token",
        name: "ManageRegistration",
        component: () =>
          import(
            /* webpackChunkName: "corpoad-registration" */ "@/views/Manage/Auth/RegistrationView.vue"
          ),
        meta: {
          middleware: [membershipPeriod],
        },
      },
      {
        path: "registration-verify-email",
        name: "ManageRegistrationVerifyEmail",
        component: () =>
          import(
            /* webpackChunkName: "corpoad-registration-verify-email" */ "@/views/Manage/Auth/RegistrationVerifyEmailView.vue"
          ),
        meta: {
          middleware: [membershipPeriod],
        },
      },
      {
        path: "/",
        name: "ManageHome",
        component: DashboardLayout,
        props: { title: "Corporations" },
        children: [
          {
            path: "withdrawal",
            name: "WithdrawalManage",
            component: () =>
              import(
                /* webpackChunkName: "withdrawal-layout-view" */ "@/views/Manage/Withdrawal/LayoutView.vue"
              ),
            meta: {
              middleware: [authPaid],
            },
          },
          applyJobRoute,
          corporationInfoRoute,
        ],
      },
      managePaymentRoute,
      {
        path: "unauthorized",
        name: "ManagePageUnauthorized",
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/401.vue"
          ),
      },
      {
        path: "forbidden",
        name: "ManagePageForbidden",
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/403.vue"
          ),
      },
      {
        path: ":pathMatch(.*)*",
        name: "ManagePageNotFound",
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/404.vue"
          ),
      },
    ],
  },
];
