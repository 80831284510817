var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "for-template" },
    [
      !_vm.notFound
        ? _c(
            "div",
            { staticClass: "d-block" },
            [
              _vm.update
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-end mb-1" },
                    [
                      _vm.showBtnDelete
                        ? _c(
                            "b-button",
                            {
                              staticClass: "btn-w-100",
                              attrs: {
                                variant: "danger",
                                disabled: _vm.loading,
                              },
                              on: { click: _vm.openModalRemove },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("panel.label_remove")) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-card",
                {
                  staticClass: "core__card__form",
                  attrs: { "header-text-variant": "white-text" },
                },
                [
                  _c(
                    "b-form",
                    _vm._g(
                      {
                        attrs: { autocomplete: "false" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      _vm.$listeners
                    ),
                    [
                      _c(
                        "div",
                        { staticClass: "core__card__form-content" },
                        [_vm._t("default")],
                        2
                      ),
                      _vm._v(" "),
                      _vm.useLoading
                        ? _c("b-overlay", {
                            attrs: {
                              show: _vm.loading,
                              opacity: "0.7",
                              "no-wrap": "",
                              "spinner-variant": "secondary",
                              "spinner-type": "grow",
                              "spinner-small": "",
                              rounded: "",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "core__card__form-actions" },
                    [
                      _vm._t(
                        "actions",
                        function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center mt-5 mb-4",
                              },
                              [
                                _vm.showBtnCreateUpdate
                                  ? [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-2 btn-w-100",
                                          attrs: {
                                            type: "submit",
                                            variant: "primary",
                                            disabled: _vm.disabledExecuteBtn,
                                          },
                                          on: {
                                            click: _vm.openModalCreateUpdate,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("panel.label_register")
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                        {
                          showAction: _vm.showAction,
                          openModalCreateUpdate: _vm.openModalCreateUpdate,
                          goBackSearching: _vm.goBackSearching,
                          disabledExecuteBtn: _vm.disabledExecuteBtn,
                          showBtnCreateUpdate: _vm.showBtnCreateUpdate,
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex g-5 w-100 w-100" },
            [_c("not-found-component")],
            1
          ),
      _vm._v(" "),
      _vm._t("modals", function () {
        return [
          _c("base-modal-confirm", {
            attrs: { name: "base-modal-create-update-confirm" },
            on: { "confirm-process": _vm.handleOk },
          }),
          _vm._v(" "),
          _c("base-modal-confirm", {
            attrs: {
              name: "base-modal-remove-confirm",
              customConfirmText: _vm.confirmTextDelete,
              remove: "",
            },
            on: { "confirm-process": _vm.handleRemove },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }