<template>
    <b-row class="justify-content-center core__client__input__group__wrapper">
        <b-col v-if="!hiddenLabel" :xl="labelXl" :lg="labelLg" :md="labelMd" sm="12" sx="12" class="d-flex client__title__input" :class="{ 'align-items-center': labelMiddleCenter }">
            <slot name="label">
                <label class="m-0 core__client__input__group__wrapper-title__input" v-html="title"></label>
                <span v-if="required" class="text-danger ml-1 font-weight-bold">{{ $t('panel.label_client_required_field')}}</span>
            </slot>
        </b-col>
        <b-col :xl="inputXl" :lg="inputLg" :md="inputMd" sm="12" sx="12">
            <slot name="input">
                <div :class="addClassCustom">
                <!-- text-input -->
                <b-form-input
                    v-if="input"
                    v-model="data"
                    :class="{ 'is-invalid': error && validateFail && validateFail.hasOwnProperty(itemKey)}"
                    trim
                    v-bind="propsInput"
                    v-on="$listeners"
                    ></b-form-input>
                <div v-show="error && validateFail && validateFail.hasOwnProperty(itemKey)" class="text-danger">{{ validateFail[itemKey] }}</div>
                <span v-if="hintText"
                    class="core__client__input__group__wrapper-hint__text"
                >{{ hintText }}</span>
                </div>
            </slot>

        </b-col>
    </b-row>

</template>

<script>
export default {
    name: 'CoreClientInputGroup',
    props: {
        value: {
            type: [String, Number]
        },
        // add * behind label of required input
        required: {
            type: Boolean,
            default: false
        },
        // title of input
        title: {
            type: String,
            default: ''
        },
        // if want show text-input
        input: {
            type: Boolean,
            default: true
        },

        error: {
            type: Boolean,
            default: false,
        },
        // object validationFail from parent
        validateFail: {
            type: Object,
            default: function () {
                return {}
            }
        },
        // key name of validationFail
        itemKey: {
            type: String,
            default: ''
        },
        // prop of input such as maxlength, required, ...
        propsInput: {
            type: Object,
            default: function() {
                return {}
            }
        },
        hintText: {
            type: String,
            default: ''
        },
        labelMiddleCenter: {
            type: Boolean,
            default: true,
        },
        addClassCustom:{
            type: String,
            default: ''
        },
        labelLg: {
            type: Number,
            default: 2,
        },
        inputLg: {
            type: Number,
            default: 5,
        },
        labelXl:{
            type: Number,
            default: 3,
        },
        inputXl:{
            type: Number,
            default: 5,
        },
        labelMd: {
             type: Number,
            default: 4,
        },
        inputMd: {
            type: Number,
            default: 7,
        },
        hiddenLabel: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .core__client__input__group__wrapper {
        margin-bottom: 8px;

        &-title__input {
            margin: 0;
            font-size: 15px;
            color: #303030;

            @media screen and (max-width: 900px) {
                margin-bottom: 5px;
            }
        }

        &-hint__text {
            color: #8993a4;
            font-size: 12px;
        }


        @media screen and (max-width: 900px) {
            margin-bottom: 15px;

            .client__title__input {
                margin-bottom: 3px;
            }
        }

        @media screen and (min-width: 768px) and (max-width: 991px){
            .col-md-8 {
                padding: 6px;
            }
        }
    }
</style>
