import memberLogin from "@/middlewares/member/authLogin";
import authMember from "@/middlewares/member/authMember";


export default [
    {
        path: '/',
        component: () => import('@/layouts/TheContent'),
        beforeEnter: (to, from, next) => {
            if (to.path == "/")
                next({ name: "MemberLogin" });
            else next();
        },
        children: [
            {
                path: 'login',
                name: 'MemberLogin',
                component: () => import(/* webpackChunkName: "member-login-view" */'@/views/Member/Auth/LoginMemberView.vue'),
                meta: {
                    middleware: [memberLogin],
                },
            },
            {
                path: 'send-email-reset-password',
                name: 'MemberSendEmailResetPassword',
                component: () => import(/* webpackChunkName: "member-send-email-reset-password" */'@/views/Member/ResetPassword/SendEmailResetPasswordView.vue'),
                meta: {
                    middleware: [memberLogin],
                },
            },
            {
                path: 'reset-password',
                name: 'MemberResetPassword',
                component: () => import(/* webpackChunkName: "member-reset-password" */'@/views/Member/ResetPassword/ResetPasswordView.vue'),
                meta: {
                    middleware: [memberLogin],
                },
            },
            {
                path: 'register/:token',
                name: 'MemberRegistration',
                component: () => import(/* webpackChunkName: "member-registration" */'@/views/Member/Auth/RegistrationView.vue'),
                meta: {
                    middleware: [memberLogin],
                },
            },
            {
                path: 'registration-verify-email',
                name: 'MemberRegistrationVerifyEmail',
                component: () => import(/* webpackChunkName: "member-registration-verify-email" */'@/views/Member/Auth/RegistrationVerifyEmailView.vue'),
                meta: {
                    middleware: [memberLogin],
                },
            },
            {
                path: 'member', // member
                component: () => import('@/layouts/TheContent'),
                children: [
                    {
                        path: 'edit',
                        name: 'MemberEdit',
                        component: () => import('@/views/Member/MemberInfo/Edit.vue'),
                        meta: {
                            middleware: [authMember]
                        }
                    },
                    {
                        path: 'unauthorized',
                        name: 'MemberPageUnauthorized',
                        component: () => import('@/views/Errors/401.vue')
                    },
                    {
                        path: 'forbidden',
                        name: 'MemberPageForbidden',
                        component: () => import('@/views/Errors/403.vue')
                    },
                    {
                        path: ':pathMatch(.*)*',
                        name: 'MemberPageNotFound',
                        component: () => import('@/views/Errors/404.vue'),
                    }
                ],
            },
        ],
    },
]
