var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-block selection-base-component" }, [
    _c(
      "select",
      _vm._b(
        { staticClass: "form-control select2" },
        "select",
        _vm.$props,
        false
      ),
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.errorFetch
      ? _c("div", { staticClass: "d-block mt-1 text-danger" }, [
          _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.$t("panel.fail"))),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }