var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-alert",
    {
      attrs: { fade: "", variant: _vm.variantColor },
      on: { "dismiss-count-down": _vm.handleDismiss },
      model: {
        value: _vm.dismissCountDown,
        callback: function ($$v) {
          _vm.dismissCountDown = $$v
        },
        expression: "dismissCountDown",
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.message) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }