import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import predicates from './predicates';
import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex);

const state = {
  nameApp: 'i-chi-gei',
  searchingInstance: {},
  // account info when login
  accountLogin: {
    isAuth: 'fail',
    info: {}
  },
  paymentInfo: {},
  membershipPeriod: {},
  validation: {},
  pageNotFoundClient: false,
};

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  plugins: [createMutationsSharer({
    predicate: (mutation) => {
      const predicate = predicates;

      return predicate.indexOf(mutation.type) >= 0;
    }
  })],
  modules
});
