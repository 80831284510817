const regexYYYYMMDD = /([0-9]{2}\d\d)\/(0[1-9]|1[0-2])\/((0|1)[0-9]|2[0-9]|3[0-1])$/;
const regexDDMMYYYY = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(([0-9]{1,2})\d\d))$/;
const regexYYYYMMDDHHmm = /([0-9]{2}\d\d)\/(0[1-9]|1[0-2])\/((0|1)[0-9]|2[0-9]|3[0-1]) ((0|1)[0-9]|2[0-4]):([0-5][0-9])$/;

export {
  regexYYYYMMDD,
  regexDDMMYYYY,
  regexYYYYMMDDHHmm
}

var isShift = false;
var seperator = "/";

function formatValidDate(input, keyCode, mode = 'date') {
  if (keyCode == 16) {
      isShift = true;
  }
  // Allow only Numeric Keys.
  if (((keyCode >= 48 && keyCode <= 57) || keyCode == 8 || keyCode <= 37 || keyCode <= 39 || (keyCode >= 96 && keyCode <= 105)) && isShift == false) {
      if (mode == 'date') {
        if ((input.value.length == 4 || input.value.length == 7) && keyCode != 8) {
          input.value += seperator;
        }
      }
      
      if (mode == 'dateTime') {
        if ((input.value.length == 4 || input.value.length == 7) && keyCode != 8) { // 2024/01/02 24
          input.value += seperator;
        }
        if (input.value.length == 10 && keyCode != 8) {
          input.value += ' ';
        }

        if (input.value.length == 13 && keyCode != 8) {
          input.value += ':';
        }
      }

      return true;
  }
  else {
      return false;
  }
}

export default {
  inserted: function (el, binding) {

    let { mode = 'date' } = binding.value;

    // maximum length for YYYY/MM/DD
    if (mode == 'date') {
      el.setAttribute('maxlength', 10);
    } else if (mode == 'dateTime') {
      el.setAttribute('maxlength', 16)
    }

    el.handlerInput = function (e) {
      return formatValidDate(this, e.keyCode, mode);
    }
    el.addEventListener("keydown", el.handlerInput);
  }
}