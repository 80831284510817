const actions = ['access', 'create', 'edit', 'show', 'delete'];

const screen_permissions = {
    admin: 'admin',
    operator: 'operator',
    school: 'school',
    teacher: 'teacher',
    trial_management: 'trial_management',
    ad_media: 'ad_media',
    payment_information: 'payment_information',
    statistic: 'statistic',
    default_color: 'default_color',
    calendar: 'calendar',
    student: 'student',
    teacher_student: 'teacher_student',
    teacher_student_payment_information: 'teacher_student_payment_information',
}

let generatePermission = function () {
    let screenPermissions = {};
    for (let prop in screen_permissions) {
        actions.forEach(item => {
            let naming = `${screen_permissions[prop]}_${item}`;
            screenPermissions[naming] = naming;
        })
    }
    return screenPermissions;
}

const permissions = generatePermission();
export default permissions;
// management_menu_access
// admin_access (search)
// admin_create (create page)
// admin_edit   (edit page)

// admin_show
// admin_delete

// operator_access
// operator_create
// operator_edit
// operator_show
// operator_delete
// school_access
// school_create
// school_edit
// school_show
// school_delete
// student_access
// student_create
// student_edit
// student_show
// student_delete
// teacher_access
// teacher_create
// teacher_edit
// teacher_show
// teacher_delete
// trial_management_access
// trial_management_create
// trial_management_edit
// trial_management_show
// trial_management_delete
// ad_media_access
// ad_media_create
// ad_media_edit
// ad_media_show
// ad_media_delete
// payment_information_access
// payment_information_create
// payment_information_edit
// payment_information_show
// payment_information_delete
// statistic_access
// statistic_create
// statistic_edit
// statistic_show
// statistic_delete
// default_color_access
// default_color_create
// default_color_edit
// default_color_show
// default_color_delete
// calendar_access
// calendar_create
// calendar_edit
// calendar_show
// calendar_delete
// student_payment_access
