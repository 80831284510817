<template>
  <div class="for-template">
    <div v-if="!notFound" class="d-block">
      <div v-if="update" class="d-flex justify-content-end mb-1">
        <b-button
          v-if="showBtnDelete"
          @click="openModalRemove"
          variant="danger"
          class="btn-w-100"
          :disabled="loading"
        >
          {{ $t("panel.label_remove") }}
        </b-button>
      </div>
      <b-card class="core__card__form" header-text-variant="white-text">
        <b-form v-on="$listeners" v-on:submit.prevent autocomplete="false">
          <!-- content -->
          <div class="core__card__form-content">
            <slot></slot>
          </div>
          <!-- overlay -->
          <b-overlay
            v-if="useLoading"
            :show="loading"
            opacity="0.7"
            no-wrap
            spinner-variant="secondary"
            spinner-type="grow"
            spinner-small
            rounded
          />
        </b-form>
        <!-- SLOT ACTIONS -->
        <div class="core__card__form-actions">
          <slot
            name="actions"
            :showAction="showAction"
            :openModalCreateUpdate="openModalCreateUpdate"
            :goBackSearching="goBackSearching"
            :disabledExecuteBtn="disabledExecuteBtn"
            :showBtnCreateUpdate="showBtnCreateUpdate"
          >
            <div class="d-flex justify-content-center">
              <!-- define searching page name to turn back -->
              <b-button
                v-if="showBtnBackSearch"
                @click="goBackSearching"
                type="button"
                variant="secondary"
                class="mr-2 btn-w-100"
              >
                {{ $t("panel.label_go_back") }}
              </b-button>

              <slot name="switch-status"></slot>

              <!-- define create or update button -->
              <template v-if="showBtnCreateUpdate">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2 btn-w-100"
                  @click="openModalCreateUpdate"
                  :disabled="disabledExecuteBtn"
                >
                  {{
                    update
                      ? $t("panel.label_update")
                      : $t("panel.label_register")
                  }}
                </b-button>
              </template>
            </div>
          </slot>
        </div>
      </b-card>
    </div>
    <div v-else class="d-flex g-5 w-100 w-100">
      <not-found-component />
    </div>
    <!-- SLOT MODAL -->
    <slot name="modals">
      <!-- Modal update-->
      <base-modal-confirm
        name="base-modal-create-update-confirm"
        :customConfirmText="confirmTextSubmit"
        @confirm-process="handleOk"
      >
      </base-modal-confirm>

      <!-- Modal delete -->
      <base-modal-confirm
        name="base-modal-remove-confirm"
        :customConfirmText="confirmTextDelete"
        remove
        @confirm-process="handleRemove"
      >
      </base-modal-confirm>
    </slot>
  </div>
</template>

<script>
import NotFoundComponent from "@/components/Errors/NotFoundComponent";

export default {
  name: "BaseCardCreateUpdate",
  components: {
    NotFoundComponent,
  },

  props: {
    header: {
      type: String,
      default: "header-card",
    },
    useLoading: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    confirmTextDelete: {
      type: String,
      default: "",
    },
    notFound: {
      type: Boolean,
      default: false,
    },
    disabledExecuteBtn: Boolean,
    goBackRouteName: {
      type: String,
      default: "",
    },
    usePermissionChecking: {
      type: Boolean,
      default: true,
    },
    confirmTextSubmit: {
      type: String,
      default: "",
    },
    useModalConfirm: {
      type: Boolean,
    },
    showBtnBackSearch: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    showBtnCreateUpdate() {
      return this.showAction;
    },

    showBtnDelete() {
      return this.showRemoveButton;
    },
  },

  created() {},
  methods: {
    handleRemove() {
      this.$emit("confirm-remove");
    },

    handleOk() {
      this.$emit("confirm-ok");
    },

    reset() {
      this.$parent.reset();
    },
    goBackSearching() {
      if (this.goBackRouteName) {
        this.$router.push(window.goBackSearchingRoute(this.goBackRouteName));
      } else {
        this.$router.go(-1);
      }
    },
    openModalCreateUpdate() {
      if (this.useModalConfirm) {
        this.$modal.show("base-modal-create-update-confirm");
      } else if (!this.disabledExecuteBtn) {
        this.handleOk();
      }
      // this.$modal.show('base-modal-create-update-confirm')
      // requirement: not use modal to show dialog confirm
    },
    openModalRemove() {
      this.$modal.show("base-modal-remove-confirm");
    },
  },
};
</script>

<style scoped lang="scss">
.core__card__form {
  margin-bottom: 5px;
  &-content {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    @media only screen and (min-width: 993px) {
      &__searching > .row div {
        padding: 2px 0px !important;
      }
    }
  }
  .title__searching {
    font-weight: 600;
    font-size: 15px;
    color: black;
    margin: 0;
  }

  &-actions {
    display: block;
    margin: 5px 0;
  }
}

.create__data__action {
  margin: 10px 0;
}
</style>
