import {manageServiceInstance} from '@/plugins/api_service/services';

const prefix = '/management/auth'

export default {
    login: (credential) => manageServiceInstance.post(`${prefix}/login`, {}, credential),
    confirmLogin: (data) => manageServiceInstance.post(`${prefix}/confirm-login`, {}, data),
    resendAuthCode: (id, data) => manageServiceInstance.put(`${prefix}/resend-auth-code/${id}`, {}, data),
    logout: () => manageServiceInstance.post(`${prefix}/logout`, {}, {}),
    getAccountPaymentInfo: (query) => manageServiceInstance.get(`${prefix}/get-account-usage-info`, query),
}
