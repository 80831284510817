import _ from "lodash";

const files = require.context('.', true, /index.js/);

let module = {};

files.keys().forEach((key) => {
    if (key !== './index.js') {
        let _keyarr = (key.split('.')[1]).split('/');
        let folderName = (_keyarr[1]).split('_');
        let defaultName = '';
        if (folderName.length > 1) {
            // folderName = folderName.map((item, index) => index > 1 ? _.capitalize(item) : item);
            folderName = folderName.map((item, index) => index > 0 ? _.capitalize(item) : item);
            defaultName = folderName.join('');
            console.log(defaultName)
        } else {
            defaultName = folderName[0];
        }
        module[_.upperFirst(defaultName)] = files(key).default;
    }
});

// export default modules;
export default module;