import axios from "axios";
import reqConstant from "@/constants/request";
import Request from "@/constants/request";
import { buildUrl } from "@/helpers/common.js";
import StatusCode from "@/constants/statusCode";
import store from "@/stores";
import {
  ACT_FORCE_FRESH_AUTH,
  GET_AUTH_TOKEN,
} from "@/stores/modules/AuthManage/types";
import { GET_ACCOUNT_ID } from "@/stores/modules/AuthManage/types";
import { GET_MEMBER_ID, GET_AUTH_MEMBER_TOKEN } from "@/stores/modules/AuthMember/types";
import {
  ACT_FORCE_FRESH_AUTH_ADMIN,
  GET_ADMIN_ID,
  GET_AUTH_ADMIN_TOKEN,
} from "@/stores/modules/AuthAdmin/types";

// function to call refresh token
function refreshToken() {
  return axios({
    method: "post",
    url: "/api/management/auth/refresh-token",
    headers: {
      Authorization: "Bearer " + store.getters[`AuthManage/${GET_AUTH_TOKEN}`],
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

function refreshAdminToken() {
  return axios({
    method: "post",
    url: "/api/admin/auth/refresh-token",
    headers: {
      Authorization: "Bearer " + store.getters[`AuthAdmin/${GET_AUTH_ADMIN_TOKEN}`],
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

function refreshMemberToken() {
  return axios({
    method: "post",
    url: "/api/member/auth/refresh-token",
    headers: {
      Authorization: "Bearer " + store.getters[`AuthMember/${GET_AUTH_MEMBER_TOKEN}`],
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

let refreshing_token = null;

class CommonService {
  constructor(baseURL, role) {
    this.role = role;
    const options = {
      baseURL: baseURL,
      timeout: reqConstant.defaultRequestTimeOut,
      headers: {
        accept: reqConstant.accept,
        "content-type": reqConstant.contentType,
      },
    };

    this.axiosDownloadInstance = axios.create({
      ...options,
      responseType: "blob",
    });

    this.axiosInstance = new axios.create(options);
    this.axiosInstance.defaults.withCredentials = true;

    // interceptor request
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        let token = "";
        let user_id = "";
        switch (this.role) {
          case "Manage":
            token = await store.getters[`AuthManage/${GET_AUTH_TOKEN}`];
            user_id = store.getters[`AuthManage/${GET_ACCOUNT_ID}`];
            break;
          case "Admin":
            token = await store.getters[`AuthAdmin/${GET_AUTH_ADMIN_TOKEN}`];
            user_id = store.getters[`AuthAdmin/${GET_ADMIN_ID}`];
            break;
          case "Member":
            token = await store.getters[`AuthMember/${GET_AUTH_MEMBER_TOKEN}`];
            user_id = store.getters[`AuthMember/${GET_MEMBER_ID}`];
            break;
        }
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        // add header user-id
        if (user_id) {
          config.headers["user-id"] = user_id;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // interceptor response
    this.axiosInstance.interceptors.response.use(
      (res) => {
        return Promise.resolve(res);
      },
      async (err) => {
        if (!axios.isCancel(err)) {
          const originalConfig = err.config;

          if (originalConfig.url !== "/login" && err.response) {
            // check status code from backend [401]
            if (err.response.status === 401) {
              if (err.response.data.error === "TokenInValid") {
                location.replace("/login");
                return false;
              } else if (
                err.response.data.error === "TokenHasExpired" &&
                !originalConfig._retry
              ) {
                originalConfig._retry = true;
                try {
                  if (this.role == "Admin") {
                    refreshing_token = refreshAdminToken();
                  } else if (this.role == "Member") {
                    refreshing_token = refreshMemberToken();
                  } else {
                    refreshing_token = refreshToken();
                  }
                  // call refresh token
                  let res = await refreshing_token;
                  refreshing_token = null;
                  let { expires_in, access_token } = res.data.data;
                  store.dispatch("Auth/refreshToken", {
                    access_token,
                    expires_in,
                  });
                  return this.axiosInstance(originalConfig);
                } catch (err) {
                  // token has been black list
                  if (err.response.status === 403) {
                    location.replace("/auth/login/teacher_miss_token");
                    return;
                  }
                  return Promise.reject(err);
                }
              } else {
                switch (this.role) {
                  case "Manage":
                    // 1. refresh state auth manage
                    store.dispatch(`AuthManage/${ACT_FORCE_FRESH_AUTH}`);
                    // window.location.replace('/manage/login')
                    break;
                  case "Admin":
                    // 2. refresh state auth admin
                    store.dispatch(`AuthAdmin/${ACT_FORCE_FRESH_AUTH_ADMIN}`);
                    break;
                }
              }
            }
          }
          return Promise.reject(err);
        } else {
          return Promise.reject({ isCancel: true });
        }
      }
    );
  }

  getOptions(header = {}) {
    return {
      headers: buildHeaders(header),
    };
  }

  // Handle error
  handleError(err) {
    if (err.response) {
      let statusCodeResponse = err.response.status; // get status from err
      let structureError = {};
      structureError.statusCode = statusCodeResponse || "";
      // 422 validation
      if (statusCodeResponse === StatusCode.HTTP_UNPROCESSABLE_ENTITY) {
        structureError.failValidation = true;
        structureError.data = parseValidationMessage(err.response.data.error);
        structureError.source_data = err.response.data.error;
        return structureError;
      }
      // 403 forbidden
      if (statusCodeResponse === StatusCode.HTTP_FORBIDDEN) {
        structureError.forbidden = true;
        structureError.data =
          err.response.data.message || "Your action is not allowed!";
        return structureError;
      }

      // 401 Unauthorized
      if (statusCodeResponse === StatusCode.HTTP_UNAUTHORIZED) {
        // token WRONG
        structureError.unauthorized = true;
        // refresh store auth to default null and clear all localstorage
        // Store.dispatch('Auth/refreshAuth');

        // if (window.location.pathname !== '/auth/login') {
        //     location.reload();
        // }

        // try {
        //     Store.dispatch('Auth/login', { login_id: 'admin@admin.com', password: '123'})
        //     .then(res =>console.log(res));

        // } catch (err) {

        // }
        return structureError;
      }

      // 404 Not Found ==> when Id-edit is not found or not belongs to
      if (statusCodeResponse === StatusCode.HTTP_NOT_FOUND) {
        structureError.notFound = true;
        return structureError;
      }

      // 400 Bad request
      if (statusCodeResponse === StatusCode.HTTP_BAD_REQUEST) {
        structureError.badRequest = true;
        structureError.data = err.response.data.message;
        return structureError;
      }

      // 402 Payment Required
      if (statusCodeResponse === StatusCode.HTTP_PAYMENT_REQUIRED) {
        structureError.paymentRequired = true;
        structureError.data = err.response.data.message;
        return structureError;
      }

      structureError.data = "Unknown error!";
      return structureError;
    }

    return err;
  }

  // Handle success
  handleSuccess(response) {
    // if (response.status === StatusCode.HTTP_OK) {
    //     let message = response.data.message || 'Successfull';
    //     Store.dispatch('setAlertSystem', {
    //         show: true,
    //         error: false,
    //         message: message
    //     })
    // }
    //   console.log(response.data);
    //   console.log(Object.prototype.hasOwnProperty.call(response.data, 'account_menu_permission'))
    if (
      Object.prototype.hasOwnProperty.call(
        response.data,
        "account_menu_permission"
      ) &&
      response.data.account_menu_permission
    ) {
      if (!response.data.data) {
        response.data.data = {};
      }
      response.data.data.account_menu_permission =
        response.data.account_menu_permission;
    }
    return response.data.data;
  }

  get(url, query = {}, headers = {}) {
    let options = this.getOptions(headers);

    let { useCancelToken = true } = query;

    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };
    //   if (cancelToken) {
    //     options.cancelToken = cancelToken;
    //   }

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, options)
        .then((res) => {
          resolve(this.handleSuccess(res));
        })
        .catch((err) => {
          if (!err.isCancel) {
            console.log("from instance service", err);
            reject(this.handleError(err));
          }
        });
    });
  }

  retrieveFileUrl(url, query = {}, headers = {}) {
    let options = this.getOptions(headers);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };
    //   url = buildUrl(url, query);

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  post(url, query = {}, body = {}, headers = {}) {
    let options = this.getOptions(headers);

    //   url = buildUrl(url, query);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, body, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  put(url, query = {}, body = {}, headers = {}) {
    let options = this.getOptions(headers);
    //   url = buildUrl(url, query);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(url, body, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  delete(url, query = {}, headers = {}) {
    let options = this.getOptions(headers);
    // url = buildUrl(url, query);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(url, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  postFile(
    url,
    query = {},
    file,
    body = {},
    headers = {},
    onUploadProgress = null
  ) {
    let formData = new FormData();
    formData.append("file-upload", file);

    for (let key in body) {
      formData.append(key, body[key]);
    }

    let options = this.getOptions(headers);
    if (onUploadProgress) {
      options.onUploadProgress = onUploadProgress;
    }

    url = buildUrl(url, query);

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, formData, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  downloadFile(url, query = {}, body = {}, headers = {}) {
    let options = this.getOptions(headers);
    // url = buildUrl(url, query);
    options = {
      ...options,
      params: { ...query },
      responseType: "blob",
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, body, options)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(this.handleError(err)));
    });
  }
}

// function building header
function buildHeaders(headers) {
  let requestHeaders = getDefaultRequestHeaders();

  if (!headers) {
    return requestHeaders;
  }
  if (Array.isArray(headers)) {
    return requestHeaders;
  }
  for (let keyName in headers) {
    requestHeaders[keyName.toLowerCase()] = headers[keyName];
  }

  return requestHeaders;
}

// default request headers
function getDefaultRequestHeaders() {
  return {
    page: 1,
    timeout: Request.defaultRequestTimeOut,
    "content-type": "application/json",
    // "Authorization": "Bearer " + StorageManage.getStorage(ApplicationStorageToken),
  };
}

// parse Validation Message from laravel
export function parseValidationMessage(listMessage) {
  if (typeof listMessage != "object") {
    throw new Error("Parameter type should be a object!");
  }
  let validation = {};
  for (let key in listMessage) {
    validation[key] = listMessage[key][0];
  }
  return validation;
}

export default CommonService;
