<template>
    <div class="d-block mb-5">
        <div style="background: #BF967F" class="p-2">
            <h4 class="font-weight-bold m-0" style="color: #FFFFFF">{{ header }}</h4>
        </div>

        <!-- content -->
        <div class="core__card__form-content">
            <div class="core__card__form-content">
                <slot name="content">
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CoreClientCardForm',
        props: {
            header: {
                type: String,
                default: 'header-card'
            },
        },
    }
</script>

<style scoped lang="scss">
    .core__card__form {
        margin-bottom: 5px;

        &-content {
            display: block;
            margin-top: 10px;
            margin-bottom: 5px;
            @media only screen and (min-width: 993px) {
                &__searching > .row div {
                    padding: 2px 0px !important
                }
            }
        }

        .title__searching {
            font-weight: 600;
            font-size: 15px;
            color: black;
            margin: 0;
        }

        &-actions {
            display: block;
            margin: 5px 0;
        }
    }

    .create__data__action {
        margin: 10px 0;
    }
</style>
