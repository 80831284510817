<template>
  <div id="client__header" class="client__header__wrapper dance">
    <b-container>
      <div class="d-block">
        <div class="client__header__wrapper-branch">
          <!-- logo mobile -->
          <div class="client__header__wrapper-branch__logo">
            <img alt="logo" :src="logoImg" @click="redirectToHomePage" />
          </div>

          <div
            class="client__header__wrapper-branch__name-system__area-logo dance"
          >
            <img alt="logo" :src="logoImg" @click="redirectToHomePage" />
          </div>
          <div class="client__header__wrapper-branch__name">
            <div class="client__header__wrapper-branch__name-system__area">
              <h4>{{ $t("panel.label_service_title") }}</h4>
            </div>
          </div>

          <div class="client__header__wrapper-information__user">
            <b-button class="custom-button-menu logout" @click="handleLogout">
              {{ $t("panel.logout") }}
            </b-button>
          </div>
          <!-- icon menu mobile -->
          <div class="client__header__wrapper-branch__icon__menu">
            <b-button variant="info" class="m-0" v-b-toggle.client-sidebar-menu>
              <i v-b-toggle.client-sidebar-menu class="fas fa-bars"></i>
            </b-button>

            <b-sidebar
              id="client-sidebar-menu"
              title="メニュー"
              bg-variant="dark"
              text-variant="light"
              shadow
              backdrop
            >
              <div class="px-3 py-2">
                <client-mobile-menu />
              </div>
              <!-- <template #footer>
                <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                    <b-button block @click="handleLogout" class="d-flex w-100 align-items-center">
                        <span class="mx-auto">{{ $t('client.label_menu_student_logout') }}</span>
                        <i class="fas fa-sign-out-alt"></i>
                    </b-button>
                </div>
              </template> -->
            </b-sidebar>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import logo from "../../assets/images/logo.jpg";
import ClientMobileMenu from "./ClientMobileMenu.vue";
import { mapGetters, mapActions } from "vuex";
import { ACT_MEMBER_LOGOUT } from "@/stores/modules/AuthMember/types";

export default {
  name: "ClientHeader",
  components: {
    ClientMobileMenu,
  },
  computed: {
    logoImg() {
      return logo;
    },
  },
  methods: {
    async handleLogout() {
      await this.$store.dispatch(`AuthMember/${ACT_MEMBER_LOGOUT}`);
      await this.$router.push({ name: "MemberLogin" });
    },

    redirectToHomePage(event) {
      event.preventDefault();
      this.$router.push({ name: "MemberEdit" }).catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/component/client/jumpotron.scss";

$btnColorMenu: #ffe158;
$btnTextColor: #3a73b8;
$btnLogoutColor: #cccccc;
$btnLogoutTextColor: #ffffff;

.custom-button-menu {
  background-color: $btnColorMenu;
  color: $btnTextColor;
  font-weight: bold;
  flex: 1;

  &.client-active-route {
    background-color: #f9efe0;
    border-color: #f9efe0;

    &:active {
      border-color: #f9efe0;
    }

    &:focus {
      border-color: #f9efe0;
    }
  }

  &.logout {
    background-color: $btnLogoutColor;
    border-color: $btnLogoutColor;
  }
}
</style>
