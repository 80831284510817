<template>
    <div class="d-block w-100">
        <b-form-textarea
            v-model="text"
            :placeholder="placeholder"
            :rows="rows"
            :disabled="disabled"
            :max-rows="maxRows"
            :class="{ 'is-invalid': error && validateFail.hasOwnProperty(keyName) }"
            :maxlength="maxlength"
        ></b-form-textarea>
        <div v-show="error && validateFail.hasOwnProperty(keyName)" class="text-danger">{{ validateFail[keyName] }}</div>
    </div>
</template>

<script>
export default {
    name: 'CoreTextare',
    props: {
        value: null,
        rows: {
            type: Number,
            default: 5
        },
        maxRows: {
            type: Number,
        },
        error: {
            type: Boolean,
            default: false,
        },
        validateFail: {
            type: Object, 
            default: {},
        },
        keyName: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: ''
        },
        maxlength: {
            type: Number,
            default: 1000,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(newText) {
                this.$emit('input', newText)
            }
        }
    }
}
</script>

<style>

</style>