// if you want to define a global variable that can be use everywhere in app, should define here!

import { mapGetters } from 'vuex';
import {age_type, role, typeStudentTeacher} from '../constants';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Vue from 'vue';

Vue.mixin({
    computed: {
        ...mapGetters('Auth', ['getAccountId', 'getRole', 'getType', 'getStudentAgeType', 'isAuth']),

        // check if user is systemAdmin
        isSystemAdmin() {
            return this.getRole === role.systemAdmin;
        },
        // check if user is admin
        isAdmin() {
            return this.getRole === role.admin;
        },
        // check if user is operator
        isOperator() {
            return this.getRole === role.operator;
        },
        // check if user is teacher
        isTeacher() {
            return this.getRole === role.teacher;
        },
        // check if user is student
        isStudent() {
            return this.getRole === role.student;
        },

        // check type is Dance
        isDanceType() {
            return this.getType === typeStudentTeacher.dance.value;
        },

        isVocalType() {
            return this.getType === typeStudentTeacher.vocal.value;
        },

        inputCurrency() {
            return createNumberMask({
                    prefix: '',
                    allowDecimal: true,
                    includeThousandsSeparator: true,
                    allowNegative: false,
                });
        },

        isAdultStudent() {
            return this.getStudentAgeType === age_type.adult.value;
        }
    },
    methods: {
        formatMoneyStringWithSeparator(inputMoney) {
            return +inputMoney.replaceAll(',', ''); // convert to number
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
});
