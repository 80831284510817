var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "client__content__wrapper" }, [
    _c("div", { staticClass: "client__content__wrapper-title__page" }, [
      _vm._v("\n        " + _vm._s(_vm.titlePage) + "\n    "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "client__content__wrapper-main" },
      [
        _vm.useAlertSystem && _vm.alert.show
          ? _c("core-alert-system", {
              attrs: { message: _vm.alert.message, error: _vm.alert.error },
              on: {
                "hide-alert": () => {
                  this.alert.show = false
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }