import {
    SET_BASIC_INFO,
    SET_EXTEND_INFO,
    SET_DETAIL_EMPTY_INFO,
} from "./types";

export default {
    [SET_BASIC_INFO]: (state, payload) => {
        state.detail.basic_info = payload;
    },

    [SET_EXTEND_INFO]: (state, payload) => {
        state.detail.extend_info = payload;
    },

    [SET_DETAIL_EMPTY_INFO]: (state) => {
        state.detail = {
            basic_info: {},
            extend_info: {},
        };
    },
};
