var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "justify-content-center core__input__group__wrapper" },
    [
      _c(
        "b-col",
        {
          staticClass: "d-flex",
          class: { "align-items-center": _vm.labelMiddleCenter },
          attrs: { xl: "3", lg: "2", md: "4", sm: "12", sx: "12" },
        },
        [
          _vm._t("label", function () {
            return [
              _c("label", {
                staticClass: "m-0 core__input__group__wrapper-title__input",
                domProps: { innerHTML: _vm._s(_vm.title) },
              }),
              _vm._v(" "),
              _vm.required
                ? _c("span", { staticClass: "text-danger font-weight-bold" }, [
                    _vm._v("  必須"),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { xl: "6", lg: "5", md: "7", sm: "12", sx: "12" } },
        [
          _vm._t("input", function () {
            return [
              _c(
                "div",
                { class: _vm.addClassCustom },
                [
                  _vm.input
                    ? _c(
                        "b-form-input",
                        _vm._g(
                          _vm._b(
                            {
                              class: {
                                "is-invalid":
                                  _vm.error &&
                                  _vm.validateFail &&
                                  _vm.validateFail.hasOwnProperty(_vm.itemKey),
                              },
                              attrs: { trim: "" },
                              model: {
                                value: _vm.data,
                                callback: function ($$v) {
                                  _vm.data = $$v
                                },
                                expression: "data",
                              },
                            },
                            "b-form-input",
                            _vm.propsInput,
                            false
                          ),
                          _vm.$listeners
                        )
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.error &&
                            _vm.validateFail &&
                            _vm.validateFail.hasOwnProperty(_vm.itemKey),
                          expression:
                            "error && validateFail && validateFail.hasOwnProperty(itemKey)",
                        },
                      ],
                      staticClass: "text-danger",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.validateFail[_vm.itemKey]) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hintText
                    ? _c(
                        "span",
                        {
                          staticClass: "core__input__group__wrapper-hint__text",
                        },
                        [_vm._v(_vm._s(_vm.hintText))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }