// actions
export const ACT_LOGIN_MEMBER = 'ACT_LOGIN_MEMBER';
export const ACT_LOGIN_ACCOUNT = 'ACT_LOGIN_ACCOUNT';
export const ACT_CONFIRM_LOGIN_ACCOUNT = 'ACT_CONFIRM_LOGIN_ACCOUNT';
export const ACT_LOGOUT = 'ACT_LOGOUT';
export const ACT_GET_ACCOUNT_INFO = 'ACT_GET_ACCOUNT_INFO';
export const ACT_GET_MANAGE_INFO = 'ACT_GET_MANAGE_INFO';
export const ACT_FORCE_FRESH_AUTH = 'ACT_FORCE_FRESH_AUTH';
export const ACT_FRESH_AUTH_KEEP_ACCOUNT_INFO = 'ACT_FRESH_AUTH_KEEP_ACCOUNT_INFO';
export const ACT_GET_PAYMENT_INFO = 'ACT_GET_PAYMENT_INFO';
export const ACT_UPDATE_WITHDRAW = 'ACT_UPDATE_WITHDRAW';

// mutations
export const SET_TOKEN = 'SET_TOKEN';
export const SET_IS_AUTH = 'SET_IS_AUTH';
export const SET_REFRESH_AUTH = 'SET_REFRESH_AUTH';
export const SET_REFRESH_AUTH_KEEP_ACCOUNT_INFO = 'SET_REFRESH_AUTH_KEEP_ACCOUNT_INFO';
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO';
export const SET_EXPIRED_AT = 'SET_EXPIRED_AT';
export const SET_PERMISSION_MENU = 'SET_PERMISSION_MENU';
export const SET_IS_SCREEN_MANAGE = 'SET_IS_SCREEN_MANAGE';
export const SET_STATUS_ACCOUNT = 'SET_STATUS_ACCOUNT';
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export const SET_WITHDRAW_FLAG = 'SET_WITHDRAW_FLAG';
export const SET_MEMBERSHIP_PERIOD = 'SET_MEMBERSHIP_PERIOD';

// getters
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const GET_ACCOUNT_IS_AUTH = 'GET_ACCOUNT_IS_AUTH';
export const GET_ACCOUNT_ID = 'GET_ACCOUNT_ID';
export const GET_ACCOUNT_EMAIL = 'GET_ACCOUNT_EMAIL';
export const GET_ACCOUNT_ROLE = 'GET_ACCOUNT_ROLE';
export const GET_ACCOUNT_TYPE = 'GET_ACCOUNT_TYPE';
export const GET_WITHDRAW_FLAG = 'GET_WITHDRAW_FLAG';
export const GET_PERMISSION_MENU = 'GET_PERMISSION_MENU';
export const GET_IS_SCREEN_MANAGE = 'GET_IS_SCREEN_MANAGE';
export const GET_SYSTEM_ADMIN_FLAG = 'GET_SYSTEM_ADMIN_FLAG';
export const GET_PERMISSION_TREE_MENU = 'GET_PERMISSION_TREE_MENU';
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO';
export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';
export const GET_IS_PAID = 'GET_IS_PAID';
export const GET_PAYMENT_ID = 'GET_PAYMENT_ID';
export const GET_MEMBERSHIP_PERIOD = 'GET_MEMBERSHIP_PERIOD';


export const GET_EVENT_MENU = 'GET_EVENT_MENU';
