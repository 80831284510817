<template>
  <b-alert
      v-model="dismissCountDown"
      fade
      :variant="variantColor"
      @dismiss-count-down="handleDismiss"
  >
    {{ message }}
  </b-alert>
</template>

<script>
export default {
  name: 'BaseAlertSystem',
  props: {
    error: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    displayTime: {
      type: Number,
      default: 5
    },
  },
  computed: {
    variantColor() {
      return this.error ? 'danger' : 'success'
    }
  },
  mounted() {
    this.dismissCountDown = this.displayTime; // 5 seconds
    this.scrollTopWindow();
  },
  data() {
    return {
      dismissCountDown: 0
    }
  },
  methods: {
    handleDismiss(time) {
      if (!time) {
        this.$emit('hide-alert', false)
      }
    }
  }
}
</script>
