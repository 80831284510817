var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      { staticClass: "management__content" },
      [
        _vm.useAlertSystem && _vm.alert.show
          ? _c("core-alert-system", {
              attrs: { message: _vm.alert.message, error: _vm.alert.error },
              on: {
                "hide-alert": () => {
                  this.alert.show = false
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "content" },
          [
            _c(
              "b-container",
              { attrs: { fluid: "" } },
              [
                _vm._t("title-header", function () {
                  return [
                    _c(
                      "p",
                      {
                        staticClass:
                          "h3 font-weight-bold management__content-title__page",
                      },
                      [_vm._v(_vm._s(_vm.titlePage))]
                    ),
                  ]
                }),
                _vm._v(" "),
                _vm._t("default"),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }