export default {
  update:  (el, binding) => {
    let { inputType, showPassword } = binding.value
    if (inputType == 'password') {
      if(showPassword){
        el.type = 'text'
      }else{
        el.type = 'password'
      }
    }
  }
}
