<template>
    <div>
        <template>
            <div>
                <div class="core__card__form-content">
                    <slot name="content">
                    </slot>
                </div>
                <!-- SLOT BUTTON ACTION -->
                <div class="p-5">
                    <div class="core__card__form-actions">
                        <slot name="button-action">
                        </slot>
                    </div>
                </div>
            </div>
        </template>
        <!-- SLOT MODAL -->
        <slot name="modals">
            <!-- Modal update-->
            <core-modal-confirm
                    idComponent="confirmDialog"
                    @ok="handleOk">
            </core-modal-confirm>

            <!-- Modal delete -->
            <core-modal-confirm
                    remove
                    idComponent="removeDialog"
                    @ok="handleRemove">
            </core-modal-confirm>
        </slot>
    </div>

</template>

<script>
    export default {
        name: 'CoreClientAppendCardForm',
        props: {
            useLoading: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false
            },
            showAddFormBtn: {
                type: Boolean,
                default: true
            },
            error: {
                type: Boolean,
                default: false,
            },
            // object validationFail from parent
            validateFail: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                checked: false
            }
        },
        methods: {
            goBackSearching(nameRoute) {
                return window.goBackSearchingRoute(nameRoute);
            },

            handleRemove() {
                this.$emit('confirm-remove');
            },

            handleOk() {
                this.$emit('confirm-ok');
            },

            reset() {
                this.$parent.reset();
            }
        },
    }
</script>

<style scoped lang="scss">
    .core__card__form {
        margin-bottom: 5px;

        &-content {
            display: block;
            margin-top: 10px;
            margin-bottom: 5px;
            @media only screen and (min-width: 993px) {
                &__searching > .row div {
                    padding: 2px 0px !important
                }
            }
        }

        .title__searching {
            font-weight: 600;
            font-size: 15px;
            color: black;
            margin: 0;
        }

        &-actions {
            display: block;
            margin: 5px 0;
        }
    }

    .btn-box {
        margin: 10px 0 2px 0;
    }
</style>
