import managementRoute from './manage';
import adminRoute from './admin';
import clientRoute from './client';
import memberRoute from './member'

export default [
    ...managementRoute,
    ...adminRoute,
    ...clientRoute,
    ...memberRoute,
];
