import {
  SET_MEMBER_TOKEN,
  SET_MEMBER_INFO,
  SET_MEMBER_EXPIRED_AT,
  SET_STATUS_MEMBER,
  SET_REFRESH_AUTH_MEMBER,
  SET_MEMBER_PAYMENT_INFO,
} from '@/stores/modules/AuthMember/types';

export default [
  `AuthMember/${SET_MEMBER_TOKEN}`,
  `AuthMember/${SET_MEMBER_INFO}`,
  `AuthMember/${SET_MEMBER_EXPIRED_AT}`,
  `AuthMember/${SET_STATUS_MEMBER}`,
  `AuthMember/${SET_REFRESH_AUTH_MEMBER}`,
  `AuthMember/${SET_MEMBER_PAYMENT_INFO}`,
];
