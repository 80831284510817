import { mapGetters } from "vuex";
import { GET_ACCOUNT_ID } from '@/stores/modules/AuthManage/types'

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters('AuthManage', {
          '$manageAccountId': GET_ACCOUNT_ID
        })
      }
    })
  }
}
