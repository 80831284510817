// actions
export const ACT_ADMIN_LOGIN = 'ACT_ADMIN_LOGIN';
export const ACT_ADMIN_CONFIRM_LOGIN = 'ACT_ADMIN_CONFIRM_LOGIN';
export const ACT_ADMIN_LOGOUT = 'ACT_ADMIN_LOGOUT';
export const ACT_GET_ADMIN_INFO = 'ACT_GET_ADMIN_INFO';
export const ACT_FORCE_FRESH_AUTH_ADMIN = 'ACT_FORCE_FRESH_AUTH_ADMIN';
export const ACT_FRESH_AUTH_KEEP_ADMIN_INFO = 'ACT_FRESH_AUTH_KEEP_ADMIN_INFO';
export const ACT_GET_ADMIN_PAYMENT_INFO = 'ACT_GET_ADMIN_PAYMENT_INFO';

// mutations
export const SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN';
export const SET_IS_AUTH_ADMIN = 'SET_IS_AUTH_ADMIN';
export const SET_REFRESH_AUTH_ADMIN = 'SET_REFRESH_AUTH_ADMIN';
export const SET_REFRESH_AUTH_KEEP_ADMIN_INFO = 'SET_REFRESH_AUTH_KEEP_ADMIN_INFO';
export const SET_ADMIN_INFO = 'SET_ADMIN_INFO';
export const SET_ADMIN_EXPIRED_AT = 'SET_ADMIN_EXPIRED_AT';
export const SET_STATUS_ADMIN = 'SET_STATUS_ADMIN';
export const SET_ADMIN_PAYMENT_INFO = 'SET_ADMIN_PAYMENT_INFO';

// getters
export const GET_AUTH_ADMIN_TOKEN = 'GET_AUTH_ADMIN_TOKEN';
export const GET_ADMIN_IS_AUTH = 'GET_ADMIN_IS_AUTH';
export const GET_ADMIN_ID = 'GET_ADMIN_ID';
export const GET_ADMIN_EMAIL = 'GET_ADMIN_EMAIL';
export const GET_ADMIN_ROLE = 'GET_ADMIN_ROLE';
export const GET_ADMIN_TYPE = 'GET_ADMIN_TYPE';
export const GET_IS_SCREEN_ADMIN = 'GET_IS_SCREEN_ADMIN';
export const GET_SYSTEM_ADMIN_FLAG = 'GET_SYSTEM_ADMIN_FLAG';
export const GET_ADMIN_INFO = 'GET_ADMIN_INFO';
export const GET_ADMIN_PAYMENT_INFO = 'GET_ADMIN_PAYMENT_INFO';
export const GET_IS_PAID_ADMIN = 'GET_IS_PAID_ADMIN';
export const GET_ADMIN_PAYMENT_ID = 'GET_ADMIN_PAYMENT_ID';
