<template>
  <div class="container__wrapper__custom"
       :class="{'nav-open': $sidebar.showSidebar, 'loading__page': loadingPage }">
    <transition name="fade">
      <indicator-page
          v-show="loadingPage"
      ></indicator-page>
    </transition>

    <base-notifications></base-notifications>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>

import eventBus from '@/helpers/eventBus';
import IndicatorPage from './components/Indicator/IndicatorPage.vue';
import BaseNotifications from "./components/Notification/BaseNotifications";

export default {
  name: 'App',
  components: {
    BaseNotifications,
    IndicatorPage
  },
  data() {
    return {
      loadingPage: false
    }
  },
  mounted() {
    eventBus.$on('asyncComponentLoading', this.start);
    eventBus.$on('asyncComponentLoaded', this.stop)
  },

  computed: {
    '$isLoadingPage'() {
      return this.loadingPage;
    }
  },
  watch: {
    loadingPage() {
      this.$emit('loading-page', this.loadingPage);
    }
  },

  methods: {
    start() {
      this.loadingPage = true;
    },
    stop() {
      this.loadingPage = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.container__wrapper__custom {
  // min-height: 100vh;
  // height: 100%;
  position: relative;
  // min-height: -webkit-fill-available;

  &.loading__page {
    height: 100vh;
    overflow: hidden;
  }
}

.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }

  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
