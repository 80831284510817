var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "nav nav-mobile-menu" },
    [
      _c(
        "base-dropdown",
        [
          _c("template", { slot: "title" }, [
            _c("i", { staticClass: "fa fa-globe" }),
            _vm._v(" "),
            _c("b", { staticClass: "caret" }),
            _vm._v(" "),
            _c("span", { staticClass: "notification" }, [
              _vm._v("5 Notifications"),
            ]),
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v("Notification 1"),
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v("Notification 2"),
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v("Notification 3"),
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v("Notification 4"),
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v("Another notification"),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("base-dropdown", { attrs: { title: "Dropdown" } }, [
        _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
          _vm._v("Action"),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
          _vm._v("Another action"),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
          _vm._v("Something"),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
          _vm._v("Something else here"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider" }),
        _vm._v(" "),
        _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
          _vm._v("Separated link"),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link", attrs: { href: "#" } }, [
        _c("i", { staticClass: "nc-icon nc-zoom-split hidden-lg-up" }),
        _vm._v(" "),
        _c("span", { staticClass: "d-lg-none" }, [_vm._v("Search")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link", attrs: { href: "#pablo" } }, [
        _c("span", { staticClass: "no-icon" }, [_vm._v("Log out")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }