<template>
  <modal
      :name="name"
      v-bind="$attrs"
  >
    <slot></slot>
  </modal>
</template>

<script>
export default {
  name: 'BaseModalDialog',
  props: {
    name: {
      type: String,
      default: 'base-modal'
    },
    // height: [Number, String],
    // width: [Number, String],
    // resizable: Boolean,
    // draggable: Boolean,
    // clickToClose: Boolean,
    // scrollable: {
    //   type: Boolean,
    //   default: false,
    // }
  }
}
</script>