<template>
  <div class="client__content__wrapper">
      <div class="client__content__wrapper-title__page">
          {{ titlePage }}
      </div>
      <div class="client__content__wrapper-main">
          <core-alert-system
                  v-if="useAlertSystem && alert.show"
                  @hide-alert="() => {this.alert.show = false}"
                  :message="alert.message"
                  :error="alert.error"
          />
          <slot></slot>
      </div>
  </div>
</template>

<script>
export default {
    name: 'ClientContentWrapper',
    props: {
        titlePage: {
            type: String,
            default: 'Title page'
        },
        useAlertSystem: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            alert: {
                show: false,
                error: false,
                message: ''
            }
        }
    },
    mounted() {
        this.$eventBus.$on('update-alert-system', this.handleAlertSystem)
    },
    methods: {
        handleAlertSystem({error, message}) {
            this.alert = {
                show: true,
                error: error,
                message: message
            };
        }
    }
}
</script>

<style scoped lang="scss">
    @import '../../assets/sass/component/client/client-content.scss';
</style>
