var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-pagination",
    _vm._g(
      _vm._b(
        {
          attrs: {
            limit: _vm.maxLimit,
            size: "md",
            "hide-ellipsis": "",
            align: "center",
          },
        },
        "b-pagination",
        _vm.$props,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }