var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar",
      style: _vm.sidebarStyle,
      attrs: {
        "data-color": _vm.backgroundColor,
        "data-image": _vm.backgroundImage,
      },
    },
    [
      _c(
        "div",
        { staticClass: "sidebar-wrapper" },
        [
          _c("div", { staticClass: "logo" }, [
            _c(
              "a",
              {
                staticClass: "simple-text logo__container",
                attrs: { href: "#" },
              },
              [
                _c("i", { staticClass: "fas fa-home" }),
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm._t("content"),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "nav nav-main__links" },
            [
              _vm._t("default", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-link",
                    {
                      key: link.name + index,
                      attrs: { to: link.path, link: link },
                      on: { click: _vm.closeNavbar },
                    },
                    [
                      _c("i", { class: link.icon }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(link.name))]),
                    ]
                  )
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.$slots["bottom-links"]
            ? _c(
                "ul",
                { staticClass: "nav nav-bottom" },
                [_vm._t("bottom-links")],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }