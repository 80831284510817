import {
  ACT_CONFIRM_LOGIN_ACCOUNT,
  ACT_FORCE_FRESH_AUTH,
  ACT_FRESH_AUTH_KEEP_ACCOUNT_INFO,
  ACT_GET_PAYMENT_INFO,
  ACT_LOGIN_ACCOUNT,
  ACT_LOGOUT,
  SET_ACCOUNT_INFO,
  SET_EXPIRED_AT,
  SET_MEMBERSHIP_PERIOD,
  SET_PAYMENT_INFO,
  SET_REFRESH_AUTH,
  SET_REFRESH_AUTH_KEEP_ACCOUNT_INFO,
  SET_STATUS_ACCOUNT,
  SET_TOKEN,
  SET_WITHDRAW_FLAG,
} from "./types";

import storage from "@/helpers/storageManage";
import authservice from "@/services/manage/auth_manage";
// import { menuManageType } from '@/constants/commons';
import {
  ApplicationManageAccountInfo,
  ApplicationManageIsAuth,
  ApplicationManagePaymentInfo,
  ApplicationManageStorageToken,
  ApplicationManageTokenExpiredAt,
  ApplicationMembershipPeriod,
} from "@/plugins/setting";

import moment from "moment";
// import _ from "lodash";

export default {
  [ACT_LOGIN_ACCOUNT]: async ({ commit }, payload) => {
    let response = await authservice.login(payload);
    commit(SET_ACCOUNT_INFO, response);
    commit(SET_WITHDRAW_FLAG, response.withdraw_flag);
    storage.setStorage({
      [ApplicationManageAccountInfo]: JSON.stringify(response),
    });
    return response;
  },

  [ACT_CONFIRM_LOGIN_ACCOUNT]: async ({ commit }, payload) => {
    let response = await authservice.confirmLogin(payload);

    let { user_data, access_token, expires_in, payment } = response;
    let expiredAt = moment().add(expires_in, "minutes").valueOf(); // miliseconds
    // commit
    commit(SET_TOKEN, access_token);
    commit(SET_ACCOUNT_INFO, user_data);
    commit(SET_EXPIRED_AT, expiredAt);
    commit(SET_STATUS_ACCOUNT, "success");
    commit(SET_PAYMENT_INFO, payment);
    commit(SET_WITHDRAW_FLAG, user_data.withdraw_flag);
    commit(SET_MEMBERSHIP_PERIOD, user_data.membership_period);

    storage.setStorage({
      [ApplicationManageStorageToken]: access_token,
      [ApplicationManageIsAuth]: "success",
      [ApplicationManageTokenExpiredAt]: expiredAt,
      [ApplicationManageAccountInfo]: JSON.stringify(user_data),
      [ApplicationManagePaymentInfo]: JSON.stringify(payment),
    });

    // code logout form admin
    // ...

    return response;
  },

  [ACT_GET_PAYMENT_INFO]: async ({ commit }, payload) => {
    let response = await authservice.getAccountPaymentInfo(payload);
    commit(SET_PAYMENT_INFO, response);
    commit(SET_MEMBERSHIP_PERIOD, response.membership_period);
    storage.setStorage({
      [ApplicationManagePaymentInfo]: JSON.stringify(response),
    });
    return response;
  },

  [ACT_LOGOUT]: async ({ dispatch }) => {
    await authservice.logout();
    dispatch(ACT_FORCE_FRESH_AUTH);
  },

  [ACT_FRESH_AUTH_KEEP_ACCOUNT_INFO]: async ({ commit }) => {
    storage.removeStorage(ApplicationManageAccountInfo);
    storage.removeStorage(ApplicationMembershipPeriod);
    storage.removeStorage(ApplicationManagePaymentInfo);
    commit(SET_REFRESH_AUTH_KEEP_ACCOUNT_INFO);
  },

  [ACT_FORCE_FRESH_AUTH]: async ({ commit }) => {
    storage.removeStorage(ApplicationManageStorageToken);
    storage.removeStorage(ApplicationManageIsAuth);
    storage.removeStorage(ApplicationManageTokenExpiredAt);
    storage.removeStorage(ApplicationManageAccountInfo);
    storage.removeStorage(ApplicationMembershipPeriod);
    storage.removeStorage(ApplicationManagePaymentInfo);
    commit(SET_REFRESH_AUTH);
  },
};
