<template>
  <div class="box-card" :class="customClassBox">
    <div><span :class="customTitleClass">{{ title }}</span></div>
    <div :class="customClassContent" class="border rounded">
      <slot></slot>
    </div>
  </div>

</template>
<script>
export default {
  name: "BaseBox",
  props: {
    title: {
      type: String,
      default: ""
    },
    customClassBox: {
      type: String,
      default: ""
    },
    customClassContent: {
      type: String,
      default: "border rounded"
    }
  },
  computed: {
    customTitleClass() {
      return Object.prototype.hasOwnProperty.call(this.$attrs, 'class-title') ? this.$attrs['class-title'] : '';
    }
  }
};
</script>
<style>

</style>
