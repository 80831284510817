var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    _vm._b(
      {
        tag: "component",
        attrs: { custom: "", "router-link-exact-active": "" },
        nativeOn: {
          click: function ($event) {
            return _vm.hideSidebar.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ navigate, href, isActive }) {
                return [
                  _c(
                    "li",
                    {
                      class: { active: isActive && _vm.$attrs.to != "" },
                      on: { click: navigate },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: href },
                          on: { click: navigate },
                        },
                        [
                          _vm._t("default", function () {
                            return [
                              _vm.link.icon
                                ? _c("i", { class: _vm.link.icon })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(_vm.link.name))]),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      },
      "component",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }