<template>
  <b-row class="core__input__group__wrapper" :class="classCustomPosition">
    <b-col
        :xl="gridTitleInput.xl"
        :lg="gridTitleInput.lg"
        :md="gridTitleInput.md"
        :sm="gridTitleInput.sm"
        :sx="gridTitleInput.sx"
        :class="customTitleClass"
        v-if="showLabel"
    >
      <slot name="label">
        <label class="m-0 core__input__group__wrapper-title__input">
                    <span class="font-weight-bold" v-html="title">
                    </span>
          <div v-if="subTitle" class="font-weight-normal">{{ subTitle }}</div>
        </label>
        <span v-if="required" class="text-danger text-nowrap pl-1 font-weight-bold">{{ textRequired }}</span>
      </slot>
    </b-col>
    <b-col
        :xl="gridInputContent.xl"
        :lg="gridInputContent.lg"
        :md="gridInputContent.md"
        :sm="gridInputContent.sm"
        :sx="gridInputContent.sx"
    >
      <slot name="input" :inputFailValidate="isInputFailValidation">
        <div :class="addClassCustom">
          <!-- text-input -->
          <div class="position-relative">
            <b-form-input
                v-show-password="{inputType: propsInput.type, showPassword: showPassword}"
                v-if="input"
                v-model="data"
                :class="{ 'is-invalid': validationResult && validationResult.hasOwnProperty(keyName)}"
                trim
                v-bind="propsInput"
                v-mask="`${conditionMask}`"
                v-on="$listeners"
                :placeholder="$t('panel.label_place_holder')"
            />
            <div class="eye-icon_parent" v-if="propsInput.type == 'password'" @click="showPassword = !showPassword"
                 ref="ShowPasswordIcon">
              <i class="fas fa-eye eye-icon_child"></i>
            </div>
          </div>
        </div>
      </slot>

      <div v-error-input-text="{ validation: validationResult, keyName: keyName }"></div>
      <div v-if="listKeyName && validationResult" class="text-danger">
        <template v-for="(itemKeyName, index) in listKeyName">
                    <span v-if="Object.prototype.hasOwnProperty.call(validationResult, itemKeyName)" :key="index"
                          class="is-invalid-feedback-message">
                        {{ validationResult[itemKeyName] }}
                    </span>
        </template>
      </div>
      <div>
        <slot name="hideText">
          <span v-if="hintText" class="core__input__group__wrapper-hint__text">{{ hintText }}</span>
        </slot>
      </div>
    </b-col>
  </b-row>

</template>

<script>
import handleValidateMixin from '@/mixins/handle-validate-mixin';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'BaseInputGroup',
  mixins: [handleValidateMixin],
  props: {
    classTitle: {
      type: Array,
      default: function () {
        return [];
      }
    },
    value: {
      type: [String, Number]
    },
    // add * behind label of required input
    required: {
      type: Boolean,
      default: false
    },
    // title of input
    title: {
      type: String,
      default: ''
    },
    // if want show text-input
    input: {
      type: Boolean,
      default: true
    },
    // prop of input such as maxlength, required, ...
    propsInput: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hintText: {
      type: String,
      default: ''
    },
    labelMiddleCenter: {
      type: Boolean,
      default: true,
    },
    classCustomPadding: {
      type: Boolean,
      default: true,
    },
    addClassCustom: {
      type: String,
      default: ''
    },
    addClassFormInput: {
      type: String,
      default: ''
    },
    textRequired: {
      type: String,
      default: '*'
    },
    subTitle: {
      type: String,
      default: ''
    },
    conditionMask: {
      type: String,
      default: ''
    },
    listKeyName: {
      type: Array,
      default: function () {
        return [];
      }
    },
    gridTitle: {
      type: Object,
      default: function () {
        return {}
      }
    },
    gridInput: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    classCustomPosition: {
      type: String,
      default: "justify-content-center"
    },
  },
  data() {
    return {
      showPassword: false
    }
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    isInputFailValidation() {
      return !isEmpty(this.validationResult) && this.isFailValidation;
    },
    gridTitleInput() {
      let {
        xl = 3,
        lg = 3,
        md = 4,
        sm = 12,
        sx = 12
      } = this.gridTitle;
      return {xl, lg, md, sm, sx};
    },
    gridInputContent() {
      let {
        xl = 6,
        lg = 6,
        md = 7,
        sm = 12,
        sx = 12
      } = this.gridInput;
      return {xl, lg, md, sm, sx};
    },
    customTitleClass() {
      let defaultClass = ['d-flex'];
      return [
        ...defaultClass,
        this.labelMiddleCenter ? 'align-items-center' : '',
        this.classCustomPadding ? 'pt-3' : '',
        ...this.classTitle
      ];
    }
  },
  watch: {
    showPassword() {
      if (this.showPassword) {
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.add('fa-eye-slash')
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.remove('fa-eye');
      } else {
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.add('fa-eye');
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.remove('fa-eye-slash');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.core__input__group__wrapper {
  &-title__input {
    margin: 0;
    font-size: 16px;
    color: #303030;
  }

  &-hint__text {
    color: #8993a4;
    font-size: 14px;
  }

  margin-bottom: 5px;

  @media screen and (max-width: 900px) {
    margin-bottom: 10px;
  }
}

.eye-icon_parent {
  position: absolute;
  right: 10px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
