<template>
    <div class="client__mobile__menu__wrapper">

        <ul class="nav nav-pills flex-column mb-auto">
            <li class="nav-item w-100 py-2 border" style="border-radius: 5px">
                <a href="#" class="nav-link  user__account text-white">
                    <i class="fas fa-user nav-icon-size"></i>
                    <span class="user__name">{{ GET_MEMBER_INFO.name }}</span>
                </a>
            </li>
            <!-- <router-link v-for="(item, index) in menu" :key="index" custom v-slot="{ navigate, isActive }" :to="item.to">
                <li class="nav-item">
                    <a href="#" class="nav-link" :class="{ 'active': isActive, 'text-white': !isActive }" @click="navigate">
                        <i :class="item.icon"></i>
                        <span>{{ item.title }}</span>
                    </a>
                </li>
            </router-link> -->

            <template>
                <li class="nav-item">
                    <b-button block class="nav-link text-white" @click="handleLogout">
                        <i class="fas fa-power-off nav-icon-size"></i>
                        <span> {{ $t('panel.label_logout') }}</span>
                    </b-button>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import memberMenu from '@/plugins/menu/member';
import { mapGetters } from 'vuex';
import { ACT_MEMBER_LOGOUT } from "@/stores/modules/AuthMember/types";

export default {
    name: 'ClientMobileMenu',
    computed: {
        ...mapGetters('AuthMember', ['GET_MEMBER_INFO']),
        menu() {
            return memberMenu;
        }
    },
    methods: {
        async handleLogout() {
            await this.$store.dispatch(`AuthMember/${ACT_MEMBER_LOGOUT}`);
            await this.$router.push({ name: "MemberLogin" });
        },
    }
}
</script>

<style scoped lang="scss">
.client__mobile__menu__wrapper {
    font-size: 18px;
    color: white;

    .nav-item {
        margin: 4px 0;
    }

    .nav-link {

        &:hover:not(.user__account) {
            background: rgba(255, 255, 255, 0.13);
            opacity: 1;
        }

        display: flex;
        gap: .8em;
        align-items: center;
    }

    .active {
        background-color: #525354;
    }

    .user__name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
