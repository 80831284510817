import authAdmin from "@/middlewares/admin/authAdmin";

export default {
  path: "offers",
  name: "AdminOffers",
  component: () =>
    import(
      /* webpackChunkName: "admin-offers-layout-view" */ "@/views/Admin/Offers/LayoutView"
    ),
  redirect: { name: "OffersSearch" },
  children: [
    {
      path: "",
      name: "OffersSearch",
      component: () =>
        import(
          /* webpackChunkName: "admin-offers-searching-view" */ "@/views/Admin/Offers/Search"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    // {
    //   path: 'create',
    //   name: 'OffersCreate',
    //   component: () => import(/* webpackChunkName: "admin-offers-create-view" */'@/views/Admin/Offers/Create'),
    // },
    {
      path: "edit/:id",
      name: "OffersEdit",
      component: () =>
        import(
          /* webpackChunkName: "admin-offers-edit-view" */ "@/views/Admin/Offers/Edit"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
