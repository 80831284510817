import authAdmin from "@/middlewares/admin/authAdmin";

export default {
  path: "job-applicants",
  name: "AdminJobApplicants",
  component: () =>
    import(
      /* webpackChunkName: "admin-job-applicant-layout-view" */ "@/views/Admin/JobApplicants/LayoutView"
    ),
  redirect: { name: "JobApplicantsSearch" },
  children: [
    {
      path: "",
      name: "JobApplicantsSearch",
      component: () =>
        import(
          /* webpackChunkName: "admin-job-applicant-searching-view" */ "@/views/Admin/JobApplicants/Search"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    // {
    //   path: 'create',
    //   name: 'JobApplicantsCreate',
    //   component: () => import(/* webpackChunkName: "admin-job-applicant-create-view" */'@/views/Admin/JobApplicants/Create'),
    // },
    {
      path: "edit/:id",
      name: "JobApplicantEdit",
      component: () =>
        import(
          /* webpackChunkName: "admin-job-applicant-edit-view" */ "@/views/Admin/JobApplicants/Edit"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
