var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "for-template" },
    [
      _vm.showFormCard
        ? _c(
            "b-card",
            {
              staticClass: "core__card__form",
              attrs: {
                header: _vm.title,
                "header-bg-variant": "primary",
                "header-text-variant": "white-text",
              },
            },
            [
              _c(
                "b-form",
                _vm._g(
                  {
                    attrs: { autocomplete: "false" },
                    on: { keydown: _vm.handleKeyDown },
                  },
                  _vm.$listeners
                ),
                [
                  _c("div", { staticClass: "core__card__form-content" }, [
                    _c(
                      "div",
                      { staticClass: "core__card__form-content__searching" },
                      [_vm._t("content")],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.useLoading
                    ? _c("b-overlay", {
                        attrs: {
                          show: _vm.loading,
                          opacity: "0.7",
                          "no-wrap": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "core__card__form-actions" },
                    [
                      _vm._t("actions", function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-5 btn-w-100",
                                  attrs: { variant: "warning" },
                                  on: { click: _vm.reset },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("panel.label_clear")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-5 btn-w-100",
                                  attrs: { type: "submit", variant: "primary" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("panel.label_search")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showCreateButton
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-end create__data__action g-5",
            },
            [
              _vm.showCreateButton
                ? _vm._t(
                    "create-data",
                    function () {
                      return [
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: _vm.routeNameCreate } } },
                            [
                              _c(
                                "b-button",
                                { attrs: { variant: "success" } },
                                [_vm._v(_vm._s(_vm.$t("panel.label_create")))]
                              ),
                            ],
                            1
                          ),
                        ],
                      ]
                    },
                    { routeCreateName: _vm.routeNameCreate }
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("area-custom"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }