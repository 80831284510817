import {
  GET_ACCOUNT_EMAIL,
  GET_ACCOUNT_ID,
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_IS_AUTH,
  GET_ACCOUNT_ROLE,
  GET_ACCOUNT_TYPE,
  GET_AUTH_TOKEN,
  GET_IS_PAID,
  GET_IS_SCREEN_MANAGE,
  GET_MEMBERSHIP_PERIOD,
  GET_PAYMENT_ID,
  GET_PAYMENT_INFO,
  GET_PERMISSION_MENU,
  GET_WITHDRAW_FLAG,
} from "./types";
import moment from "moment";
import _ from "lodash";
import { managePaymentStatus } from "@/constants/commons";

export default {
  [GET_AUTH_TOKEN](state) {
    return state.token;
  },
  [GET_ACCOUNT_IS_AUTH]: (state) => {
    let statusLogin = state.accountLogin.isAuth,
      token = state.token,
      validExpiredAt = true;

    let pointNowTime = moment(),
      expiredAt = moment(parseInt(state.expired_at)).subtract(5, "minutes");

    if (!state.expired_at || pointNowTime.isSameOrAfter(expiredAt)) {
      validExpiredAt = false;
    }

    return !!(token && statusLogin == "success" && validExpiredAt);
  },

  [GET_MEMBERSHIP_PERIOD]: (state) => {
    return state.membershipPeriod;
  },

  [GET_IS_PAID]: (state) => {
    let statusPaid = state.paymentInfo.status,
      validPeriodAt = true;
    let pointNowTime = moment(),
      periodAt = moment(parseInt(state.paymentInfo.membership_period)).subtract(
        5,
        "minutes"
      );
    if (
      !state.paymentInfo.membership_period ||
      pointNowTime.isSameOrAfter(periodAt)
    ) {
      validPeriodAt = false;
    }

    return statusPaid == managePaymentStatus.paid.value && validPeriodAt;
  },

  [GET_ACCOUNT_ID]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(state.accountLogin.info, "id")
        ? state.accountLogin.info.id
        : "";
    }
    return "";
  },
  [GET_ACCOUNT_EMAIL]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "email"
      )
        ? state.accountLogin.info.email
        : "";
    }
    return "";
  },
  [GET_ACCOUNT_ROLE]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "role_id"
      )
        ? state.accountLogin.info.role_id
        : "";
    }
    return "";
  },
  [GET_ACCOUNT_TYPE]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "type_id"
      )
        ? state.accountLogin.info.type_id
        : "";
    }
    return "";
  },

  [GET_ACCOUNT_INFO]: (state) => {
    return state.accountLogin.info;
  },

  [GET_WITHDRAW_FLAG]: (state) => {
    return state.withdraw_flag;
  },

  [GET_PAYMENT_INFO]: (state) => {
    return state.paymentInfo;
  },

  [GET_PAYMENT_ID]: (state) => {
    if (!_.isEmpty(state.paymentInfo)) {
      return Object.prototype.hasOwnProperty.call(
        state.paymentInfo,
        "payment_id"
      )
        ? state.paymentInfo.payment_id
        : "";
    }
    return "";
  },
  [GET_PERMISSION_MENU]: (state) => state.permission_menu,
};
