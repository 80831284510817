<template>
    <div class="d-block">
        <page-not-found v-if="getPageNotFoundStatus"></page-not-found>
        <template v-else>
            <template v-if="getIsCanAccessPage">
                <div class="client__wrapper">
                    <slot>
                        <!-- header -->
                        <client-header
                                ref="ClientHeader"
                                :user-type="getType"/>
                        <!-- navbar -->
                        <navbar-client
                                ref="NavbarClient"
                                :user-type="getType"/>
                    </slot>
                    <b-container fluid="sm">
                        <transition name="fade" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </b-container>
                </div>
            </template>
            <template v-else>
                <deny-access />
            </template>
        </template>
    </div>

</template>


<script>
import ClientHeader from './ClientHeader.vue';
import NavbarClient from './NavbarClient.vue';
import { typeStudentTeacher } from '../../constants'; // only for test -- should get type of user from vuex auth [getters]
import ClientContent from './ClientContent.vue';
import { mapGetters } from 'vuex';
import DenyAccess from '@/pages/DenyAccess.vue';
import PageNotFound from '@/pages/NotFoundPage.vue';

export default {
    name: 'ClientLayout',
    components: {
        ClientHeader,
        NavbarClient,
        ClientContent,
        DenyAccess,
        PageNotFound,
    },
    computed: {
        ...mapGetters('Auth', ['getIsCanAccessPage']),
        ...mapGetters(['getPageNotFoundStatus']),
    },
    data() {
        return {
            typeStudentTeacher
        }
    },
    mounted() {
        window.onscroll = () => {
            this.detectScrollContent();
        }
    },
    methods: {
        detectScrollContent() {
            // navbar
            var navbar = document.getElementById("client__header__menu");
            if (navbar) {
                var stickyNavbar = navbar.offsetTop;
                if (window.pageYOffset > stickyNavbar) {
                    navbar.classList.add("sticky");
                } else {
                    navbar.classList.remove("sticky");
                }
            }
            // header
            var header = document.getElementById("client__header");
            if (header) {
                var stickyHeader = header.offsetTop;
                if (window.pageYOffset > stickyHeader) {
                    header.classList.add("sticky");
                } else {
                    header.classList.remove("sticky");
                }
            }
        }
    },
}
</script>

<style scoped lang="scss">
    .client__wrapper {
        height: 100vh;
        height: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: 100%;
        padding-bottom: 10px;
    }
</style>
