<template>
    <b-row class="justify-content-center core__input__group__wrapper">
        <b-col xl="3" lg="2" md="4" sm="12" sx="12" class="d-flex" :class="{ 'align-items-center': labelMiddleCenter }">
            <slot name="label">
                <label class="m-0 core__input__group__wrapper-title__input" v-html="title"></label>
                <span v-if="required" class="text-danger font-weight-bold">&nbsp;&nbsp;必須</span>
            </slot>
        </b-col>
        <b-col xl="6" lg="5" md="7" sm="12" sx="12">
            <slot name="input">
                <div :class="addClassCustom">
                    <!-- text-input -->
                    <b-form-input
                        v-if="input"
                        v-model="data"
                        :class="{ 'is-invalid': error && validateFail && validateFail.hasOwnProperty(itemKey)}"
                        trim
                        v-bind="propsInput"
                        v-on="$listeners"
                    ></b-form-input>
                    <div v-show="error && validateFail && validateFail.hasOwnProperty(itemKey)" class="text-danger">{{
                        validateFail[itemKey] }}
                    </div>
                    <span v-if="hintText"
                          class="core__input__group__wrapper-hint__text"
                    >{{ hintText }}</span>
                </div>
            </slot>

        </b-col>
    </b-row>

</template>

<script>
    export default {
        name: 'CoreClientInputGroup',
        props: {
            value: {
                type: [String, Number]
            },
            // add * behind label of required input
            required: {
                type: Boolean,
                default: false
            },
            // title of input
            title: {
                type: String,
                default: ''
            },
            // if want show text-input
            input: {
                type: Boolean,
                default: true
            },

            error: {
                type: Boolean,
                default: false,
            },
            // object validationFail from parent
            validateFail: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            // key name of validationFail
            itemKey: {
                type: String,
                default: ''
            },
            // prop of input such as maxlength, required, ...
            propsInput: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            hintText: {
                type: String,
                default: ''
            },
            labelMiddleCenter: {
                type: Boolean,
                default: true,
            },
            addClassCustom: {
                type: String,
                default: ''
            }
        },

        computed: {
            data: {
                get() {
                    return this.value
                },
                set(newValue) {
                    this.$emit('input', newValue);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .core__input__group__wrapper {
        &-title__input {
            margin: 0;
            font-size: 15px;
            color: #303030;
        }

        &-hint__text {
            color: #8993a4;
            font-size: 12px;
        }

        margin-bottom: 5px;

        @media screen and (max-width: 900px) {
            margin-bottom: 10px;
        }
    }
</style>
