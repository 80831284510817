import Vue from "vue";
import ClickOutSide from "./click-ouside.js";
import NumberOnly from "./number-only.js";
import PhoneNumberOnly from "./phone-number-only.js";
import ValidateInput from "./validate-input";
import ErrorInputText from "./error-input-text";
import ScrollHorizontal from "./scroll-horizontal";
import FormatDateInputTyping from "./format-date-input-typing.js";
import AutoFormatDate from "./AutoFormatDate/auto-format-date.js";
import ValidateDateFormat from "./AutoFormatDate/validate-date-format.js";
import ShowPassword from "./show-password.js";
import AutoScrollToElement from "./auto-scroll-to-element.js";
import AutoFocusInput from "./auto-focus-input.js";
import limitedTextarea from "./limited-textarea";

Vue.directive("click-outside", ClickOutSide);
Vue.directive("number-only", NumberOnly);
Vue.directive("phone-number-only", PhoneNumberOnly);
Vue.directive("validate-input", ValidateInput);
Vue.directive("error-input-text", ErrorInputText);
Vue.directive("scroll-horizontal", ScrollHorizontal);
Vue.directive("format-date-input-typing", FormatDateInputTyping);
Vue.directive("auto-format-date", AutoFormatDate);
Vue.directive("validate-date-format", ValidateDateFormat);
Vue.directive("show-password", ShowPassword);
Vue.directive("auto-scroll-to-element", AutoScrollToElement);
Vue.directive("auto-focus-input", AutoFocusInput);
Vue.directive("limited-textarea", limitedTextarea);
