<template>
  <div class="not__found__component">

    <div class="not__found__component-content__error">
      <div class="not__found__component-content__error-img__present">
        <img :src="errorImg"/>
      </div>
      <div class="not__found__component-content__error-title__component">
        このページは表示されていません
      </div>

      <div class="not__found__component-content__error-message">
        リンクが壊れているか、ページが削除された可能性があります。開こうとしているリンクが正しいことを確認してください。
      </div>

      <div class="not__found__component-content__error-action">
        <router-link :to="{ name: defaultHomeRoute }">
          <b-button variant="primary">メインページ</b-button>
        </router-link>

        <a class="text-primary mt-2" href="#" @click="$router.go(-1)">戻ってくる</a>
      </div>
    </div>

  </div>
</template>

<script>
import ErrorDocument from '@/assets/images/errors/error-document.svg';

export default {
  name: 'NotFoundComponent',
  props: {
    message: {
      type: String,
      default: '',
    },
    defaultHomeRoute: {
      type: String,
      default: 'ManageHome'
    }
  },
  data() {
    return {
      errorImg: ErrorDocument
    }
  }
}
</script>

<style scoped lang="scss">
.not__found__component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 60vh;

  &-content__error {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-content: center;
    text-align: center;

    @media screen and (max-width: 1200px) {
      width: 60%;
    }

    &-img__present {
      object-fit: cover;
      box-sizing: border-box;
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-title__component {
      font-weight: bold;
      font-size: 20px;
      color: #454545;
    }

    &-action {
      display: flex;
      gap: 5px;
      flex-direction: column;
    }
  }
}
</style>