<template>
  <div>
    <template v-if="isSearching">
      <b-card-header
          header-bg-variant="primary" class="header-search">
        <div class="d-flex justify-content-between align-items-center">
          <span class="header-title">{{ header }}</span>
          <b-button v-b-toggle="'collapse_card_form'" variant="primary" class="p-0 m-0"
                    @click="collapse_card_form = !collapse_card_form">
            <i class="fas fa-minus" v-if="collapse_card_form"></i>
            <i class="fas fa-plus" v-if="!collapse_card_form"></i>
          </b-button>
        </div>
      </b-card-header>
      <b-collapse visible id="collapse_card_form">
        <b-card
            :style="bodyStyle"
            class="core__card__form"
            body-class="collapse show"
            header-text-variant="white-text">
          <b-form v-on="$listeners"
                  autocomplete="false">
            <!-- content -->
            <div class="core__card__form-content">
              <slot>

              </slot>
            </div>
            <!-- SLOT ACTIONS -->
            <div class="core__card__form-actions mt-3">
              <slot name="actions"
                    :goBackSearching="goBackSearching">
                <div class="d-flex justify-content-center">
                  <b-button
                      type="submit"
                      variant="success"
                      class="mr-2 btn-w-100">{{ $t("panel.search_for") }}
                  </b-button>
                  <b-button
                      @click="reset"
                      variant="warning"
                      class="ml-2 btn-w-100">{{ $t("panel.label_clear") }}
                  </b-button>
                </div>
              </slot>
            </div>
          </b-form>
        </b-card>
      </b-collapse>
      <div class="d-flex justify-content-between btn-box">
        <div v-if="showTotalRecord" class="d-flex align-items-center pl-2"> 全{{ totalRecord }}件</div>
        <div>
          <slot name="add-btn">
          </slot>
          <router-link :to="{ name: routeNameCreate }" v-if="showCreateDataBtn">
            <b-button variant="success" class="btn-w-124">{{ $t("panel.label_create") }}</b-button>
          </router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <b-form v-on="$listeners"
              autocomplete="false">
        <div>
          <div class="core__card__form-content">
            <slot>

            </slot>
          </div>
          <!-- SLOT ACTIONS -->
          <div class="pb-3">
            <div class="core__card__form-actions">
              <slot name="actions"
                    :goBackSearching="goBackSearching">
                <div class="d-flex justify-content-center">
                  <b-button
                      type="submit"
                      variant="success"
                      class="mr-2 btn-w-100">{{ $t("panel.search_for") }}
                  </b-button>
                  <b-button
                      @click="reset"
                      variant="warning"
                      class="ml-2 btn-w-100">{{ $t("panel.label_clear") }}
                  </b-button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </b-form>
    </template>
    <!-- SLOT MODAL -->
    <slot name="modals">
      <!-- Modal update-->
      <core-modal-confirm
          idComponent="confirmDialog"
          @ok="handleOk">
      </core-modal-confirm>

      <!-- Modal delete -->
      <core-modal-confirm
          remove
          idComponent="removeDialog"
          @ok="handleRemove">
      </core-modal-confirm>
    </slot>
  </div>

</template>

<script>
export default {
  name: 'ClientCardForm',
  props: {
    header: {
      type: String,
      default: 'header-card'
    },
    showCreateDataBtn: {
      type: Boolean,
      default: true
    },
    routeNameCreate: {
      type: String,
      default: ''
    },
    useLoading: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false
    },
    isSearching: {
      type: Boolean,
      default: true
    },
    bodyStyle: {
      type: Object,
      default: function () {
        return null
      }
    },
    totalRecord: {
      type: [String, Number],
      default: 0
    },
    showTotalRecord: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      collapse_card_form: true
    }
  },
  methods: {
    goBackSearching(nameRoute) {
      return window.goBackSearchingRoute(nameRoute);
    },

    handleRemove() {
      this.$emit('confirm-remove');
    },

    handleOk() {
      this.$emit('confirm-ok');
    },

    reset() {
      this.$parent.reset();
    }
  },
}
</script>

<style scoped lang="scss">
.core__card__form {
  margin-bottom: 5px;

  &-content {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    @media only screen and (min-width: 993px) {
      &__searching > .row div {
        padding: 2px 0px !important
      }
    }
  }

  .title__searching {
    font-weight: 600;
    font-size: 15px;
    color: black;
    margin: 0;
  }

  &-actions {
    display: block;
    margin: 5px 0;
  }
}

.btn-box {
  margin: 10px 0 2px 0;
}

.header-search {
  padding: 0.5rem 1.25rem;

  .header-title {
    font-size: 1rem;
  }
}
</style>
