<template>
  <div class="for-template">
    <div v-if="!notFound" class="d-block">
      <div
          v-if="update"
          class="d-flex justify-content-end mb-1"
      >
        <b-button
            v-if="showBtnDelete"
            @click="openModalRemove"
            variant="danger"
            class="btn-w-100"
            :disabled="loading"
        >
          {{ $t("panel.label_remove") }}
        </b-button>
      </div>
      <b-card
          class="core__card__form"
          header-text-variant="white-text">
        <b-form v-on="$listeners"
                v-on:submit.prevent
                autocomplete="false">

          <!-- content -->
          <div class="core__card__form-content">
            <slot></slot>
          </div>
          <!-- overlay -->
          <b-overlay
              v-if="useLoading"
              :show="loading"
              opacity="0.7"
              no-wrap
              spinner-variant="secondary"
              spinner-type="grow"
              spinner-small
              rounded
          />
        </b-form>
        <!-- SLOT ACTIONS -->
        <div class="core__card__form-actions">
          <slot name="actions"
                :showAction="showAction"
                :openModalCreateUpdate="openModalCreateUpdate"
                :goBackSearching="goBackSearching"
                :disabledExecuteBtn="disabledExecuteBtn"
                :showBtnCreateUpdate="showBtnCreateUpdate"
          >
            <div class="d-flex justify-content-center mt-5 mb-4">
              <!-- define create or update button -->
              <template v-if="showBtnCreateUpdate">
                <b-button
                    type="submit"
                    variant="primary"
                    class="mr-2 btn-w-100"
                    @click="openModalCreateUpdate"
                    :disabled="disabledExecuteBtn"
                >
                  {{ $t("panel.label_register") }}
                </b-button>
              </template>
            </div>
          </slot>
        </div>
      </b-card>
    </div>
    <div v-else class="d-flex g-5 w-100 w-100">
      <not-found-component/>
    </div>
    <!-- SLOT MODAL -->
    <slot name="modals">
      <!-- Modal update-->
      <base-modal-confirm
          name="base-modal-create-update-confirm"
          @confirm-process="handleOk">
      </base-modal-confirm>

      <!-- Modal delete -->
      <base-modal-confirm
          name="base-modal-remove-confirm"
          :customConfirmText="confirmTextDelete"
          remove
          @confirm-process="handleRemove">
      </base-modal-confirm>
    </slot>
  </div>
</template>

<script>
import NotFoundComponent from '@/components/Errors/NotFoundComponent';

export default {
  name: 'BaseCardEntry',
  components: {
    NotFoundComponent
  },

  props: {
    header: {
      type: String,
      default: 'header-card'
    },
    useLoading: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showRemoveButton: {
      type: Boolean,
      default: true
    },
    confirmTextDelete: {
      type: String,
      default: ''
    },
    notFound: {
      type: Boolean,
      default: false,
    },
    disabledExecuteBtn: Boolean,
    goBackRouteName: {
      type: String,
      default: ''
    },
    usePermissionChecking: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    showBtnCreateUpdate() { // duplicate code, if we want to check only create-button, we can add more logic here
      if (this.usePermissionChecking) {
        if (this.isUsingPermissionManagement) {
          if (this.showAction) {
            return this.managementCanCreateEditRemove;
          }
          return false;
        }
        return this.showAction;
      } else {
        return this.showAction;
      }
    },

    showBtnDelete() { // duplicate code, if we want to check only delete-button, we can add more logic here
      if (this.usePermissionChecking) {
        if (this.isUsingPermissionManagement) {
          if (this.showRemoveButton) {
            return this.managementCanCreateEditRemove;
          }
          return false;
        }
        return this.showRemoveButton
      } else {
        return this.showRemoveButton;
      }
    }
  },

  created() {

  },
  methods: {
    handleRemove() {
      this.$emit('confirm-remove');
    },

    handleOk() {
      // if (!this.isUsingPermissionManagement) {
      //   this.$emit('confirm-ok');
      // } else {
      //   if (this.managementCanCreateEditRemove) {
          this.$emit('confirm-ok')
      //   } else {
      //     this.announcePermissionIsDeny();
      //     return;
      //   }
      // }
    },

    reset() {
      this.$parent.reset();
    },
    goBackSearching() {
      if (this.goBackRouteName) {
        this.$router.push(window.goBackSearchingRoute(this.goBackRouteName));
      } else {
        this.$router.go(-1);
      }
    },
    openModalCreateUpdate() {
      if (!this.disabledExecuteBtn) {
        this.handleOk();
      }
      // this.$modal.show('base-modal-create-update-confirm')
      // requirement: not use modal to show dialog confirm
    },
    openModalRemove() {
      if (this.isUsingPermissionManagement) {
        if (this.managementCanCreateEditRemove) {
          this.$modal.show('base-modal-remove-confirm')
        } else {
          this.announcePermissionIsDeny();
        }
      } else {
        this.$modal.show('base-modal-remove-confirm')
      }
    },

    announcePermissionIsDeny() {
      this.$toast.error(this.$t('panel.label_management_is_not_allowed_use_this_feature'), {
        position: "top-right",
        timeout: 3000,
        icon: true,
        pauseOnFocusLoss: true,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.core__card__form {
  margin-bottom: 5px;

  &-content {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    @media only screen and (min-width: 993px) {
      &__searching > .row div {
        padding: 2px 0px !important
      }
    }
  }

  .title__searching {
    font-weight: 600;
    font-size: 15px;
    color: black;
    margin: 0;
  }

  &-actions {
    display: block;
    margin: 5px 0;
  }
}

.create__data__action {
  margin: 10px 0;
}
</style>
