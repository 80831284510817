import storage from "@/helpers/storageManage";
import {
  ApplicationManageAccountInfo,
  ApplicationManagePaymentInfo,
  ApplicationManageStorageToken,
  ApplicationManageTokenExpiredAt,
  ApplicationMembershipPeriod,
} from "@/plugins/setting";

import moment from "moment";
import { managePaymentStatus } from "../constants/commons";
import {
  ApplicationAdminAccountInfo,
  ApplicationAdminStorageToken,
  ApplicationAdminTokenExpiredAt,
  ApplicationMemberAccountInfo,
  ApplicationMemberStorageToken,
  ApplicationMemberTokenExpiredAt,
} from "../plugins/setting";

const rangeTime = 5;

export const checkAuthManageLifeTime = () => {
  let expiredAt = storage.getStorage(ApplicationManageTokenExpiredAt);
  let tokenManage = storage.getStorage(ApplicationManageStorageToken);
  if (!tokenManage || !expiredAt) {
    return false;
  }
  let pointNowTime = moment().valueOf();
  expiredAt = moment(parseInt(expiredAt))
    .subtract(rangeTime, "minutes")
    .valueOf();

  return pointNowTime < expiredAt;
};

export const checkAuthManageLoginBeforeTwoFactor = () => {
  return !!storage.getStorage(ApplicationManageAccountInfo);
};

export const checkMemberPeriod = () => {
  if (storage.getStorage(ApplicationMembershipPeriod)) {
    let membership_period = storage.getStorage(ApplicationMembershipPeriod),
      expiredAt = moment(membership_period, "YYYY-MM-DD")
        .endOf("days")
        .valueOf();
    return moment().valueOf() < expiredAt;
  }
  return false;
};

export const checkPaid = () => {
  if (storage.getStorage(ApplicationManagePaymentInfo)) {
    let { status, membership_period } = JSON.parse(
      storage.getStorage(ApplicationManagePaymentInfo)
    );
    let expiredAt = 0;
    if (membership_period) {
      expiredAt = moment(membership_period, "YYYY-MM-DD")
        .endOf("days")
        .valueOf();
    }
    let pointNowTime = moment().valueOf();
    return (
      status === managePaymentStatus.paid.value && pointNowTime < expiredAt
    );
  }
  return false;
};

export const checkAuthAdminLifeTime = () => {
  let expiredAt = storage.getStorage(ApplicationAdminTokenExpiredAt);
  let tokenAdmin = storage.getStorage(ApplicationAdminStorageToken);
  if (!tokenAdmin || !expiredAt) {
    return false;
  }
  let pointNowTime = moment().valueOf();
  expiredAt = moment(parseInt(expiredAt))
    .subtract(rangeTime, "minutes")
    .valueOf();

  return pointNowTime < expiredAt;
};

export const checkAuthMemberLifeTime = () => {
  let expiredAt = storage.getStorage(ApplicationMemberTokenExpiredAt);
  let tokenMember = storage.getStorage(ApplicationMemberStorageToken);
  if (!tokenMember || !expiredAt) {
    return false;
  }
  let pointNowTime = moment().valueOf();
  expiredAt = moment(parseInt(expiredAt))
    .subtract(rangeTime, "minutes")
    .valueOf();

  return pointNowTime < expiredAt;
};

export const checkAuthAdminLoginBeforeTwoFactor = () => {
  return !!storage.getStorage(ApplicationAdminAccountInfo);
};
