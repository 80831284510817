<template>
  <div class="w-50 d-block">
    <div class="d-flex align-items-center">
      <b-skeleton
          v-if="loading"
          type="input"></b-skeleton>
      <b-form-input
          v-else
          v-model="input"
          v-mask="inputCurrency"
          :disabled="disabled"
          maxlength="13"
          trim
          :class="{ 'is-invalid': isFailValidation}"
      >
      </b-form-input>
      <div class="ml-1" v-if="showUnit">{{ $t('panel.circle') }}</div>
    </div>
    <div v-show="isFailValidation" class="is-invalid-feedback-message">{{ textValidation }}</div>
  </div>
</template>

<script>
import validationMixin from '@/mixins/handle-validate-mixin';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  name: 'BaseInputCurrency',
  mixins: [validationMixin],
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showUnit: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    inputCurrency() {
      return createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
  }
}
</script>
