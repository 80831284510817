import i18n from '@/plugins/i18n';

export const result = {
    pass: {
        value: 1,
        text: i18n.t("apply-job.result_pass"),
    },
    reject: {
        value: 2,
        text: i18n.t("apply-job.result_reject"),
    }
};

export const status = {
    no_offer: {
        value: 0,
        text: i18n.t("apply-job.status_no_offer"),
    },
    have_offer: {
        value: 1,
        text: i18n.t("apply-job.status_have_offer"),
    }
};

export const desired_employment_status = {
    not_particular: {
        value: 0,
        text: i18n.t("apply-job.desired_employment_status.not_particular"),
    },
    regular: {
        value: 1,
        text: i18n.t("apply-job.desired_employment_status.regular"),
    },
    contract: {
        value: 3,
        text: i18n.t("apply-job.desired_employment_status.contract"),
    },
    part_time: {
        value: 4,
        text: i18n.t("apply-job.desired_employment_status.part_time"),
    }
};

export const final_education = {
    complete_graduate_doctor: {
        value: 1,
        text: i18n.t("apply-job.complete_graduate_doctor"),
    },
    complete_graduate_master: {
        value: 2,
        text: i18n.t("apply-job.complete_graduate_master"),
    },
    graduate_university: {
        value: 3,
        text: i18n.t("apply-job.graduate_university"),
    },
    graduate_junior_college: {
        value: 4,
        text: i18n.t("apply-job.graduate_junior_college"),
    },
    graduate_technical_college: {
        value: 5,
        text: i18n.t("apply-job.graduate_technical_college"),
    },
    graduate_vocational_school: {
        value: 6,
        text: i18n.t("apply-job.graduate_vocational_school"),
    },
    graduate_high_school: {
        value: 7,
        text: i18n.t("apply-job.graduate_high_school"),
    },
    other: {
        value: 8,
        text: i18n.t("apply-job.other"),
    }
};

export const job_change_urgency = {
    work_now: {
        value: 1,
        text: i18n.t("apply-job.job_change_urgency_selection.work_now"),
    },
    work_in_few_month: {
        value: 2,
        text: i18n.t("apply-job.job_change_urgency_selection.work_in_few_month"),
    },
    under_consideration: {
        value: 3,
        text: i18n.t("apply-job.job_change_urgency_selection.under_consideration")
    }
};