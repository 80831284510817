<template>
  <v-date-picker v-model="date" :popover="{ visibility: 'click' }"
                 :is24hr="is24hr"
                 :mode="mode"
                 :minute-increment="minuteIncrement"
                 :model-config="modelConfig"
                 :masks="mask"
                 locale="ja"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <div class="d-block">
        <div class="d-flex w-100 align-items-center g-5">
          <input
              class="form-control"
              :class="{ 'is-invalid': isFailValidation}"
              :value="inputValue"
              v-on="inputEvents"
              :placeholder="placeholder"
              :disabled="disabled"
          />
          <i v-if="showIconAppend" class="fa-solid fa-calendar"></i>
        </div>
        <div v-if="isFailValidation" class="is-invalid-feedback-message">{{ textValidation }}</div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import handleValidateMixin from '@/mixins/handle-validate-mixin';
import i18n from '@/plugins/i18n';
// import moment from 'moment';

export default {
  name: 'BaseInputDate',
  mixins: [handleValidateMixin],
  props: {
    value: [String, Date],
    disabled: {
      type: Boolean,
      default: false,
    },
    showIconAppend: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: 'YYYY/MM/DD'
    },
    is24hr: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'date'
    },
    minuteIncrement: {
      type: Number,
      description: "custom intervals for the minute select options, set 60 for no increment to select",
      default: 1,
    },
    modelConfig: {
      type: Object,
      description: "if you wantt display YYYY/MM/DD HH:MM in input :model-config='{ type: 'string', mask: 'YYYY/MM/DD HH:mm',}",
      default: function () {
        return {};
      }
    },
    mask: {
      type: Object,
      description: ":mask='{ L: 'YYYY/MM/DD' }'",
      default: function () {
        return {}
      }
    },
    placeholder: {
      type: String,
      default: function () {
        return i18n.t('panel.label_please_choose_date')
      }
    }
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
        // if (moment(newValue).isValid()) {
        //   this.$emit('input', moment(newValue).format(this.format));
        // } else {
        //   this.$emit('input', '');
        // }
      }
    },
    masks() {
      return {
        input: 'YYYY/MM/DD',
      }
    },
  }

}
</script>
<!-- note 
  if you wantt display YYYY/MM/DD HH:MM in input
   :model-config="{
                  type: 'string',
                  mask: 'YYYY/MM/DD HH:mm',
                  }"

    :mask="{ L: 'YYYY/MM/DD' }"

-->

<style lang="scss" scoped>

.date-input {
  width: 100%;
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  color: #565656;
  box-shadow: none;

  input {
    border: none;
    outline: none;

  }

  &:focus-within {
    background-color: #FFFFFF;
    border: 1px solid #AAAAAA;
    box-shadow: none;
    outline: 0 !important;
    color: #333333;
  }

  .icon {
    position: absolute;
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translate(-50%, -50%);
  }
}
</style>