import { SET_SOMETHING, SET_INSTANCE_SEARCHING, SET_VALIDATION } from './types';

export default {
  [SET_SOMETHING](state, payload) {
    state.nameApp = payload;
  },
  [SET_INSTANCE_SEARCHING](state, { routeName, path = null }) {
    let { pathname, search } = window.location;
    let routingPath = path ? path : `${pathname}${search}`;
    if (!routeName || typeof routeName !== 'string') {
      throw new Error(`[routeName] parameter should be not EMPTY and should be a string!`);
    }
    state.searchingInstance[routeName] = routingPath;
  },
  [SET_VALIDATION]: (state, payload) => {
    state.validation = payload;
  },
  SET_PAGE_NOT_FOUND_CLIENT_JBBF(state, payload) {
    // true or false
    state.pageNotFoundClient = payload;
  }
};
