import {auth as authManage, authPaid} from '@/middlewares/manage';
import permissions from '@/constants/permissions';

export default {
    path: 'job-applicant',
    name: 'ApplyJobManage',
    component: () => import(/* webpackChunkName: "apply-job-layout-view" */'@/views/Manage/ApplyJob/LayoutView.vue'),
    redirect: { name: 'ListApplyJobManage' },
    meta : {
    },
    children: [
        {
            path: '', // /manage/job-applicant
            name: 'ListApplyJobManage',
            component: () => import(/* webpackChunkName: "apply-job-searching-view" */'@/views/Manage/ApplyJob/Search.vue'),
            meta: {
                middleware: [authPaid]
            }
        },
        {
            path: 'edit/:id', // /manage/job-applicant/id
            name: 'ApplyJobManageEdit',
            component: () => import(/* webpackChunkName: "apply-job-edit-view" */'@/views/Manage/ApplyJob/Edit.vue'),
            meta: {
                middleware: [authPaid]
            }
        }
    ]
}
