var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-card", class: _vm.customClassBox }, [
    _c("div", [
      _c("span", { class: _vm.customTitleClass }, [_vm._v(_vm._s(_vm.title))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "border rounded", class: _vm.customClassContent },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }