<template>
  <div class="d-block" v-bind="$attrs">
    <div class="d-flex w-100 align-items-center">
      <input
          v-model="date"
          v-auto-format-date="{ mode: mode }"
          @keypress="onKeypress"
          class="form-control input-date"
          :class="{ 'is-invalid': isFailValidation}"
          :placeholder="getPlaceHolder"
          :disabled="disabled || isTurningOnDatePicker"
          @focus="removeValidation"
      />
      <v-date-picker
          :value="localDate"
          :popover="{ visibility: 'click' }"
          :is24hr="is24hr"
          :mode="mode"
          :minute-increment="minuteIncrement"
          @input="handlePickDate"
          @popoverWillHide="isTurningOnDatePicker = false"
          @popoverWillShow="isTurningOnDatePicker = true"
          locale="ja"
      >
        <template v-slot="{ togglePopover }">
          <button
              type="button"
              v-if="showIconAppend"
              class="calendar__button__trigger"
              @click.prevent="togglePopover"
              :disabled="disabled"
          >
            <!-- <i class="fa-solid fa-calendar"></i> -->
            <i class="fa fa-calendar"></i>
          </button>
        </template>
      </v-date-picker>
    </div>
    <span
        v-if="!textValidation"
        v-validate-date-format="{
        date: date, 
        mode: mode, 
        displayName: displayName, 
      }"></span>
    <div v-if="isFailValidation" class="is-invalid-feedback-message">{{ textValidation }}</div>
  </div>
</template>

<script>
import handleValidateMixin from '@/mixins/handle-validate-mixin';
import i18n from '@/plugins/i18n';
//import debounce from 'lodash/debounce';

export default {
  name: 'BaseInputDateText',
  mixins: [handleValidateMixin],
  props: {
    value: {
      type: [String, Date],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showIconAppend: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: ''
    },
    is24hr: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'date',
      description: "Only 2 mode [ date, dateTime ]",
      validator: propValue => {
        return ['date', 'dateTime'].includes(propValue);
      }
    },
    minuteIncrement: {
      type: Number,
      description: "custom intervals for the minute select options, set 60 for no increment to select",
      default: 1,
    },
    // modelConfig: {
    //   type: Object,
    //   description: "if you wantt display YYYY/MM/DD HH:MM in input :model-config='{ type: 'string', mask: 'YYYY/MM/DD HH:mm',}" ,
    //   default: function(){
    //     return {};
    //   }
    // },
    mask: {
      type: Object,
      description: ":mask='{ L: 'YYYY/MM/DD' }'",
      default: function () {
        return {}
      }
    },
    placeholder: {
      type: String,
      default: function () {
        return i18n.t('panel.label_please_choose_date')
      }
    },
    displayName: {
      type: String,
      default: 'Input'
    }
  },

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
        // if (moment(newValue).isValid()) {
        //   this.$emit('input', moment(newValue).format(this.format));
        // } else {
        //   this.$emit('input', '');
        // }
      },
    },

    modelConfig() {
      return {
        type: 'string',
        mask: this.getFormat
      }
    },

    // get format
    getFormat() {
      if (this.format) return this.format;

      switch (this.mode) {
        case 'date':
          return 'YYYY/MM/DD';

        case 'dateTime':
          return 'YYYY/MM/DD HH:mm';

        default:
          return 'L';
      }
    },

    masks() {
      return {
        input: 'YYYY/MM/DD',
      }
    },

    getPlaceHolder() {
      if (this.placeholder) return this.placeholder;
      return this.mode == 'date' ? this.$t('panel.label_please_choose_date') : this.$t('panel.label_please_choose_date_time');
    }
  },

  watch: {
    date() {
      this.localDate = this.date;
      /*debounce(() => {
        if (new Date(this.date) != 'Invalid Date') {
          this.localDate = this.$moment(this.date).format(this.getFormat)
        }
      }, 500)*/
    }
  },

  data() {
    return {
      localDate: null,
      isTurningOnDatePicker: false,
    }
  },

  methods: {
    handlePickDate(valueDate) {
      if (valueDate && this.isTurningOnDatePicker) {
        this.date = this.$moment(valueDate).format(this.getFormat);
        this.localDate = this.date;
        this.removeValidation()
      }
    },

    onKeypress(event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if (this.mode == 'date') {
        return (keyCode < 47 || keyCode > 57) && event.preventDefault();
      }
      ((keyCode < 47 || keyCode > 58) && keyCode != 32) && event.preventDefault();
    },

    removeValidation() {
      let localvalidation = JSON.parse(JSON.stringify(this.validationResult));
      if (Object.prototype.hasOwnProperty.call(localvalidation, this.keyName)) {
        delete localvalidation[this.keyName];
        delete this.$validation[this.keyName];
        this.$setValidation(localvalidation);
        this.$emit('update:validationResult', localvalidation);
      }
    }
  }
}
</script>
<!-- note 
  if you wantt display YYYY/MM/DD HH:MM in input
   :model-config="{
                  type: 'string',
                  mask: 'YYYY/MM/DD HH:mm',
                  }"

    :mask="{ L: 'YYYY/MM/DD' }"

-->

<style lang="scss" scoped>
.calendar__button__trigger {
  border: none;
  outline: none;
  background-color: inherit;
}

.date-input {
  width: 100%;
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  color: #565656;
  box-shadow: none;

  input {
    border: none;
    outline: none;

  }

  &:focus-within {
    background-color: #FFFFFF;
    border: 1px solid #AAAAAA;
    box-shadow: none;
    outline: 0 !important;
    color: #333333;
  }

  .icon {
    position: absolute;
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translate(-50%, -50%);
  }
}

.input-date {
  min-width: 208px;
}
</style>