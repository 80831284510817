var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "mt-2", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        [
          _vm.loading
            ? _c("b-skeleton-table", {
                attrs: {
                  rows: 5,
                  columns: _vm.tableColumnLoading,
                  "table-props": { bordered: true, striped: true },
                },
              })
            : _c(
                "div",
                { class: { "table-responsive": _vm.responsive } },
                [_vm._t("default")],
                2
              ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("pagination", function () {
        return [
          _c("b-card-text", [
            _vm.showPagination
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-pagination",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: { value: _vm.currentPage, align: "center" },
                          },
                          "b-pagination",
                          _vm.$props,
                          false
                        ),
                        _vm.$listeners
                      )
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }