import authAdmin from "@/middlewares/admin/authAdmin";

export default {
  path: "corporations",
  name: "AdminCorporations",
  component: () =>
    import(
      /* webpackChunkName: "admin-corporations-layout-view" */ "@/views/Admin/Corporations/LayoutView"
    ),
  redirect: { name: "CorporationsSearch" },
  children: [
    {
      path: "",
      name: "CorporationsSearch",
      component: () =>
        import(
          /* webpackChunkName: "admin-corporations-searching-view" */ "@/views/Admin/Corporations/Search"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    // {
    //   path: 'create',
    //   name: 'CorporationsCreate',
    //   component: () => import(/* webpackChunkName: "admin-corporations-create-view" */'@/views/Admin/Corporations/Create'),
    // },
    {
      path: "edit/:id",
      name: "CorporationsEdit",
      component: () =>
        import(
          /* webpackChunkName: "admin-corporations-edit-view" */ "@/views/Admin/Corporations/Edit"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
