import {
  checkAuthManageLifeTime,
  checkMemberPeriod,
} from "../../helpers/authenticate";

export default async function membershipPeriod({ to, next }) {
  if (checkMemberPeriod() && checkAuthManageLifeTime())
    next({ name: "ListApplyJobManage" });
  else next();
}
