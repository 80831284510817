import VTooltip from 'v-tooltip'
// Notifications plugin
import Notifications from '@/plugins/notification'
// A plugin file where you could register global components used across the app
import GlobalComponents from '@/components/Bases/globalComponents'
//client
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/plugins/sidebar'

import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/light-bootstrap-dashboard.scss'
import './assets/css/demo.css'

/**
 * This is the main Light Bootstrap Dashboard Vue plugin where dashboard related plugins are registerd.
 */
export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(SideBar)
    Vue.use(Notifications)
    Vue.use(VTooltip)
  }
}

