<template>
    <div>
        <b-card
            class="core__client__card__form border-0"
            no-body
        >
            <b-form v-on="$listeners"
                autocomplete="false">

                <!-- content -->
                <div class="core__client__card__form-content" :class="addClassBoxCentent">
                    <slot name="content">
                    </slot>
                </div>
                <!-- overlay -->
                <b-overlay
                    v-if="useLoading"
                    :show="loading"
                    opacity="0.7"
                    no-wrap>
                </b-overlay>

                <!-- SLOT ACTIONS -->
                <div class="core__client__card__form-actions">
                    <slot name="actions"
                        :goBackSearching="goBackSearching">
                        <div class="d-flex justify-content-between align-items-center">
                            <router-link
                                :to="backRoute"
                            > 
                                <b-button
                                    type="button"
                                    variant="secondary"
                                    class="mr-2 btn-w-100 mr-auto"
                                >
                                    {{ $t("panel.label_go_back") }}
                                </b-button>
                            </router-link>
                          
                            <!--  -->
                            <div class="create-action-box">
                                <!-- delete -->
                                <b-button
                                    v-if="edit"
                                    type="button"
                                    variant="danger"
                                    class="ml-2 btn-w-100"
                                    v-b-modal.removeClientDialog
                                >
                                    {{ $t("panel.label_remove") }}
                                </b-button>

                                <!-- confirm -->
                                <b-button
                                    variant="primary"
                                    class="ml-2 btn-w-100"
                                    v-b-modal.confirmClientDialog
                                >
                                    {{ $t("panel.label_register") }}
                                </b-button>
                            </div>
                        </div>
                    </slot>
                </div>
            </b-form>
        </b-card>

        <!-- SLOT MODAL -->
        <slot name="modals">
            <!-- Modal update-->
            <core-modal-confirm
                idComponent="confirmClientDialog"
                @ok="handleOk">
            </core-modal-confirm>

            <!-- Modal delete -->
            <core-modal-confirm
                remove
                idComponent="removeClientDialog"
                @ok="handleRemove">
            </core-modal-confirm>
        </slot>


    </div>
</template>

<script>
export default {
    name: 'CoreClientForm',
    props: {
        useLoading: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false
        },
        backRouteName: {
            type: String,
            default: ''
        },
        edit: {
            type: Boolean,
            default: false,
        },
        addClassBoxCentent: {
            type: String,
            default: ''
        }
    },
    computed: {
        backRoute() {
            return { path: window.goBackSearchingRoute(this.backRouteName) };
        }
    },
    mounted() {
    },

    methods: {
        goBackSearching() {
            return window.goBackSearchingRoute(this.backRouteName);
        },

        handleRemove() {
            this.$emit('confirm-remove');
        },

        handleOk() {
            this.$emit('confirm-ok');
        },

        reset(){
            this.$parent.reset();
        }
    },
}
</script>

<style scoped lang="scss">
    .core__client__card__form {
        
        margin-bottom: 5px;
        box-shadow: none;
        &-content {
            display: block;
            margin-top: 10px;
            margin-bottom :5px;
            @media only screen and (min-width: 993px){
                &__searching > .row div{
                    padding: 2px 0px !important
                }
            }
            @media screen and (max-width: 900px) {
                border: none;
                box-shadow: none;
            }
        }

        &-actions {
            display: block;
            margin: 5px 0;
        }
    }
</style>
