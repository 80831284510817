<template>
  <div class="wrapper">
    <side-bar :title="title">
      <mobile-menu v-if="false" slot="content"></mobile-menu>
      <template>
        <sidebar-link
          v-for="(itemMenu, index) in menu"
          :key="index"
          :to="itemMenu.to"
        >
          <i :class="itemMenu.icon"></i>
          <p>{{ itemMenu.title }}</p>
        </sidebar-link>
      </template>

      <template slot="bottom-links">
        <li class="active" router-link-exact-active="">
          <a href="#" class="nav-link" @click="logout">
            <i class="nc-icon nc-button-power"></i>
            <p>{{ $t("panel.logout") }}</p>
          </a>
        </li>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"></dashboard-content>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TheTopNavbar.vue";
import DashboardContent from "./TheContent.vue";
import MobileMenu from "./MobileMenu.vue";
import {
  ACT_LOGOUT,
  GET_ACCOUNT_IS_AUTH,
} from "@/stores/modules/AuthManage/types";
import { mapGetters } from "vuex";
import manageMenu from "../plugins/menu/manage";

export default {
  name: "TheDashboardLayout",
  props: {
    title: {
      type: String,
    },
  },
  components: {
    TopNavbar,
    DashboardContent,
    MobileMenu,
  },
  computed: {
    menu() {
      return manageMenu;
    },

    ...mapGetters("AuthManage", {
      isAuthManage: GET_ACCOUNT_IS_AUTH,
    }),
  },
  watch: {
    isAuthManage() {
      if (!this.isAuthManage) {
        this.$router.push({ name: "ManageLogin" });
      }
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    async logout() {
      await this.$store.dispatch(`AuthManage/${ACT_LOGOUT}`);
    },
  },
};
</script>
