var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block" },
    [
      _c("multiselect", {
        class: { "is-invalid": _vm.isFailValidation },
        attrs: {
          options: _vm.options,
          allowEmpty: "",
          label: "text",
          trackBy: "id",
          multiple: "",
          searchable: "",
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "noOptions",
            fn: function () {
              return [_c("span", [_vm._v(_vm._s(_vm.noOptions))])]
            },
            proxy: true,
          },
          {
            key: "placeholder",
            fn: function () {
              return [_c("span", [_vm._v(_vm._s(_vm.placeholder))])]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.multiple_value,
          callback: function ($$v) {
            _vm.multiple_value = $$v
          },
          expression: "multiple_value",
        },
      }),
      _vm._v(" "),
      _vm.isFailValidation
        ? _c("b-form-invalid-feedback", [
            _vm._v("\n    " + _vm._s(_vm.textValidation) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }