import store from "@/stores";
import Router from "@/routes";
import {
  ACT_FRESH_AUTH_KEEP_ACCOUNT_INFO,
  ACT_GET_PAYMENT_INFO,
  GET_ACCOUNT_ID,
  GET_PAYMENT_ID,
} from "../../stores/modules/AuthManage/types";
import {
  checkAuthManageLifeTime,
  checkMemberPeriod,
} from "../../helpers/authenticate";

export default async function authPaid({ to, next }) {
  if (checkAuthManageLifeTime()) {
    let payment_id = store.getters[`AuthManage/${GET_PAYMENT_ID}`];
    if (checkMemberPeriod() || !payment_id) {
      // login success and already paid
      if (to.name === "ManageLogin") {
        return Router.push({ name: "ListApplyJobManage" });
      }
      return next();
    } else {
      // call api check payment status
      try {
        const { id } = await store.dispatch(
          `AuthManage/${ACT_GET_PAYMENT_INFO}`,
          {
            user_id: store.getters[`AuthManage/${GET_ACCOUNT_ID}`],
            payment_id: payment_id,
          }
        );
        if (checkMemberPeriod()) return next();
        return next({
          name: "ManageConfirmPayment",
          query: { payment_id: id },
        });
      } catch (err) {
        throw new Error("Can get data from api");
      }
    }
  } else {
    let { path } = to;
    // remove store case not login but go to dashboard url
    store.dispatch(`AuthManage/${ACT_FRESH_AUTH_KEEP_ACCOUNT_INFO}`);
    setTimeout(() => {
      Router.push({ name: "ManageLogin", query: { redirect: path } });
    }, 500);
  }
}
