import {
    SET_TOKEN,
    SET_ACCOUNT_INFO,
    SET_EXPIRED_AT,
    SET_STATUS_ACCOUNT,
    SET_REFRESH_AUTH,
    SET_PAYMENT_INFO,
    SET_WITHDRAW_FLAG,
    SET_MEMBERSHIP_PERIOD
} from '@/stores/modules/AuthManage/types';

export default [
  `AuthManage/${SET_TOKEN}`,
  `AuthManage/${SET_ACCOUNT_INFO}`,
  `AuthManage/${SET_EXPIRED_AT}`,
  `AuthManage/${SET_STATUS_ACCOUNT}`,
  `AuthManage/${SET_REFRESH_AUTH}`,
  `AuthManage/${SET_PAYMENT_INFO}`,
  `AuthManage/${SET_WITHDRAW_FLAG}`,
  `AuthManage/${SET_MEMBERSHIP_PERIOD}`,
];
