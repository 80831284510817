var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container__wrapper__custom",
      class: {
        "nav-open": _vm.$sidebar.showSidebar,
        loading__page: _vm.loadingPage,
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c("indicator-page", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingPage,
                expression: "loadingPage",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("base-notifications"),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }