<template>
  <div class="form-group"
       :class="{
          'input-group': hasIcon,
          'input-group-focus': focused
       }">
    <slot name="label">
      <label v-if="label" class="control-label" :class="{ 'font-weight-bold': bold }">
        {{ label }}
      </label>
      <label v-if="required" class="text-danger">*</label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon"></i>
        </div>
      </span>
    </slot>
    <slot :inputFailValidate="isInputFailValidation">
      <div class="position-relative">
        <input
            v-show-password="{inputType: $attrs.type, showPassword: showPassword}"
            :value="value"
            v-bind="$attrs"
            v-on="listeners"
            class="form-control"
            :class="{ 'is-invalid': failInput || (validationResult && validationResult.hasOwnProperty(keyName))}"
            :placeholder="placeHolder"
            aria-describedby="addon-right addon-left"
        >
        <div v-if="$attrs.type == 'password'" class="eye-icon_parent" @click="showPassword = !showPassword"
             ref="ShowPasswordIcon">
          <i class="fas fa-eye eye-icon_child"></i>
        </div>
      </div>
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text">
          <i :class="addonRightIcon"></i>
        </div>
      </span>
    </slot>

    <div v-error-input-text="{ validation: validationResult, keyName: keyName }"></div>
    <div v-if="listKeyName && validationResult" class="text-danger">
      <template v-for="(itemKeyName, index) in listKeyName">
                    <span v-if="Object.prototype.hasOwnProperty.call(validationResult, itemKeyName)" :key="index"
                          class="is-invalid-feedback-message">
                        {{ validationResult[itemKeyName] }}
                    </span>
      </template>
    </div>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
import handleValidateMixin from '@/mixins/handle-validate-mixin';
import isEmpty from "lodash/isEmpty";

export default {
  inheritAttrs: false,
  name: "base-input",
  mixins: [handleValidateMixin],
  props: {
    label: {
      type: String,
      description: "Input label"
    },
    value: {
      type: [String, Number],
      description: "Input value"
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right"
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left"
    },
    placeHolder: {
      type: String,
      description: "placeHolder"
    },
    bold: {
      type: Boolean,
      default: false,
    },
    failInput: {
      type: Boolean,
      default: false,
    },
    listKeyName: {
      type: Array,
      default: function () {
        return [];
      }
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      showPassword: false
    }
  },
  computed: {
    hasIcon() {
      const {addonRight, addonLeft} = this.$slots;
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      }
    },
    isInputFailValidation() {
      return !isEmpty(this.validationResult) && this.isFailValidation;
    },
  },
  methods: {
    onInput(evt) {
      this.$emit('input', evt.target.value)
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    }
  },
  watch: {
    showPassword() {
      if (this.showPassword) {
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.add('fa-eye-slash')
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.remove('fa-eye');
      } else {
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.add('fa-eye');
        this.$refs.ShowPasswordIcon.querySelector('.eye-icon_child').classList.remove('fa-eye-slash');
      }
    }
  }
}
</script>
<style>
.eye-icon_parent {
  position: absolute;
  right: 10px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
