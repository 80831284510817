import {
  SET_ADMIN_EXPIRED_AT,
  SET_ADMIN_INFO,
  SET_ADMIN_TOKEN,
  SET_IS_AUTH_ADMIN,
  SET_REFRESH_AUTH_ADMIN,
  SET_STATUS_ADMIN,
} from "./types";

export default {
  [SET_ADMIN_TOKEN](state, token) {
    state.token = token;
  },
  [SET_IS_AUTH_ADMIN](state, status) {
    state.accountLogin.isAuth = status; // success or fail
  },
  [SET_REFRESH_AUTH_ADMIN](state) {
    state.accountLogin = {
      isAuth: "fail",
      info: {},
    };
    state.token = null;
    state.expired_at = null;
  },

  [SET_ADMIN_INFO](state, user_data) {
    state.accountLogin.info = user_data;
  },

  [SET_ADMIN_EXPIRED_AT](state, miliseconds) {
    state.expired_at = miliseconds;
  },

  [SET_STATUS_ADMIN](state, payload) {
    state.accountLogin.isAuth = payload; // payload is true or fail
  },
};
