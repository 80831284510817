<template>
    <div id="client__header__menu" class="navbar__client__wrapper dance">
        <b-container>
            <div>
                <center>
                    <span class="bar">|</span>
                    <span class="mr-1 ml-1 bar">{{ $t('member.label_title_edit') }}</span>
                    <span class="bar">|</span>
                </center>
            </div>
        </b-container>
    </div>
</template>

<script>

export default {
    name: 'NavbarClient',
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/component/client/navbar-client.scss';

.bar {
    color: #fff;
    font-weight: bold;
}
</style>
