export default {
  props: {
    useTotalLimitSize: Boolean,
    maxTotalSizeMb: {
      type: Number,
      default: 10,
      description: 'Only use megabytes'
    }
  },
  computed: {
    totalSizeCanBeUploaded() {
      return this.maxTotalSizeMb * 1024 * 1024; // Bytes
    }
  },
  data() {
    return {
      errorUploadFilesSize: false,
    }
  },
  methods: {
    isValidUploadTotalSize(files) {
      let formatFiles = Array.from(files);
      if (this.useTotalLimitSize && formatFiles.length) {
        let totalSize = formatFiles.reduce((size, file) => size + file.size, 0);
        if (totalSize < this.totalSizeCanBeUploaded) {
          this.errorUploadFilesSize = false;
          return true;
        }
        this.errorUploadFilesSize = true;
        return false;
      }
      return true;
    },
    isSizeGuestingValid(fileSize, currentTotalSize) {
      if (this.useTotalLimitSize) {
        if (fileSize + currentTotalSize < this.totalSizeCanBeUploaded) {
          this.errorUploadFilesSize = false;
          return true;
        }
        this.errorUploadFilesSize = true;

        return false;
      }
      return true;
    }
  },
}