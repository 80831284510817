import CoreAlertSystem from '../components/Cores/CoreAlertSystem.vue';
import CoreManageContent from '../layouts/ManageContent.vue';
import CoreCardForm from '../components/Cores/CoreCardForm.vue';
import CoreSelection from '../components/Cores/CoreSelection.vue';
import CoreInputGroup from '../components/Cores/CoreInputGroup.vue';
import CoreModalConfirm from '../components/Cores/CoreModalConfirm.vue';
import CoreTableResult from '../components/Cores/CoreTableResult.vue';
import CoreInputDate from '../components/Cores/Inputs/CoreInputDate.vue';
import CoreSelectData from '../components/Cores/CoreSelectData.vue';
import ClientContent from '../layouts/Client/ClientContent.vue';
import ClientLayout from '../layouts/Client/ClientLayout.vue';
import CoreTextarea from '../components/Cores/CoreTextarea';
import CoreClientForm from '../components/Cores/Client/CoreClientForm';
import CoreClientInputGroup from '../components/Cores/Client/CoreClientInputGroup';
import CoreClientProfileForm from '../components/Cores/Client/Profile/CoreClientForm.vue';
import CoreClientProfileCardForm from '../components/Cores/Client/Profile/CoreClientCardForm.vue';
import CoreClientProfileInputGroup from '../components/Cores/Client/Profile/CoreClientInputGroup';
import CoreClientAppendCardForm from '../components/Cores/CoreClientAppendCardForm';

export default {
    install(Vue) {
        // global core component
        Vue.component('core-alert-system', CoreAlertSystem);
        Vue.component('core-manage-content', CoreManageContent);
        Vue.component('core-card-form', CoreCardForm);
        Vue.component('core-select', CoreSelection);
        Vue.component('core-input-group', CoreInputGroup);
        Vue.component('core-modal-confirm', CoreModalConfirm);
        Vue.component('core-table-result', CoreTableResult);
        Vue.component('core-input-date', CoreInputDate);
        Vue.component('core-select-data', CoreSelectData);
        Vue.component('core-client-content', ClientContent);
        Vue.component('core-client-layout', ClientLayout);
        Vue.component('core-textarea', CoreTextarea);
        Vue.component('core-client-form', CoreClientForm);
        Vue.component('core-client-input-group', CoreClientInputGroup);
        Vue.component('core-client-profile-form', CoreClientProfileForm);
        Vue.component('core-client-profile-card-form', CoreClientProfileCardForm);
        Vue.component('core-client-profile-input-group', CoreClientProfileInputGroup);
        Vue.component('core-client-append-card-form', CoreClientAppendCardForm);
    }
}
