import i18n from '../../plugins/i18n';

export default [
  {
    to: { name: "AdminOffers" },
    title: i18n.t('panel.menu_admin_offers'),
    icon: 'nc-icon nc-paper-2 nav-icon-size'
  },
  {
    to: { name: "AdminJobApplicants" },
    title: i18n.t('panel.menu_admin_job_applicants'),
    icon: 'nc-icon nc-badge nav-icon-size'
  },
  {
    to: { name: "AdminCorporations" },
    title: i18n.t('panel.menu_admin_corporations'),
    icon: 'nc-icon nc-bank nav-icon-size'
  },
];
