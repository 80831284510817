var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    _vm._b(
      { staticClass: "form-control select2" },
      "select",
      _vm.$props,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }