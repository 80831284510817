<template>
    <v-date-picker
        v-model="date"
        mode="date"
        locale="ja"
        :masks="masks"
        :popover="popoverConfig"
        :model-config="modelConfig"
        :update-on-input="false"
        :available-dates='isBirthDay ? { start: null , end: new Date() } : ""'
        >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="d-block">
                <div class="d-flex align-items-center"  style="gap: 5px">
                    <input
                        class="form-control"
                        :class="{ 'is-invalid': error && validateFail.hasOwnProperty(keyName) }"
                        :value="inputValue"
                        :readonly="readOnly"
                        v-on="inputEvents"
                        :disabled="disabled"
                    />
                    <i v-if="showIcon" class="fas fa-calendar"></i>
                </div>
                <div v-show="error && validateFail.hasOwnProperty(keyName) && showErrorValidate" class="text-danger mt-1">{{ validateFail[keyName] }}</div>
            </div>
        </template>
    </v-date-picker>
</template>

<script>
import moment from 'moment';
export default {
    name: 'CoreInputDate',
    props: {
        value: null,
        showWhenClick: {
            type: Boolean,
            default: true
        },
        error: {
            type: Boolean,
            default: false,
        },
        validateFail: {
            type: Object,
            default: function () {
                return {}
            }
        },
        keyName: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        isBirthDay: {
            type: Boolean,
            default: false,
        },
        showErrorValidate: {
            type: Boolean,
            default: true,
        },
        showIcon: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(newValue) {
                let isDate = moment(newValue);
                this.$emit('input', isDate.isValid() ? moment(newValue).format('YYYY-MM-DD') : '');
            }
        },
        popoverConfig() {
            if (this.showWhenClick) {
                return { visibility: 'focus' };
            }
            return {};
        }
    },
    data() {
        return {
            masks: {
                input: 'YYYY-MM-DD',
            },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD'
            }
        }
    }
}
</script>

<style>

</style>
