<template>
  <div class="container__page__not__found">
      <p class="text-center message d-flex align-items-center m-0">
          <b class="h2 font-weight-bold status m-0">404</b>
          <label class="text h4">Not Found</label>
      </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    @import '../assets/sass/component/deny-not-found-page.scss';
</style>
