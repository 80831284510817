var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block w-100" },
    [
      _c("b-form-textarea", {
        class: {
          "is-invalid":
            _vm.error && _vm.validateFail.hasOwnProperty(_vm.keyName),
        },
        attrs: {
          placeholder: _vm.placeholder,
          rows: _vm.rows,
          disabled: _vm.disabled,
          "max-rows": _vm.maxRows,
          maxlength: _vm.maxlength,
        },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error && _vm.validateFail.hasOwnProperty(_vm.keyName),
              expression: "error && validateFail.hasOwnProperty(keyName)",
            },
          ],
          staticClass: "text-danger",
        },
        [_vm._v(_vm._s(_vm.validateFail[_vm.keyName]))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }