import {
  genderType,
  invoicePaidStatus,
  invoiceSendStatus,
  invoiceStatus,
  managePaymentStatus,
  managePaymentMethod,
  managePaymentType,
} from "@/constants/commons";
import moment from "moment";
import {
  desired_employment_status,
  final_education,
  job_change_urgency,
} from "../../constants/applyJob";

const findValueInConstant = (constantObject, valueInput) => {
  let textResult = "";
  for (let prop in constantObject) {
    if (constantObject[prop].value === parseInt(valueInput)) {
      textResult = constantObject[prop].text;
      return textResult;
    }
  }
  return "";
};

export default {
  install(Vue) {
    Vue.filter("formatDateTime", function (valueInput, $string = "-") {
      if (valueInput) {
        return moment(valueInput).format(
          "YYYY" + $string + "MM" + $string + "DD HH:mm"
        );
      }
    });
    Vue.filter("formatDate", function (valueInput, $string = "-") {
      if (valueInput) {
        return moment(valueInput).format(
          "YYYY" + $string + "MM" + $string + "DD"
        );
      }
    });

    Vue.filter("formatJPDate", function (valueInput) {
      if (valueInput) {
        return moment(valueInput, "YYYY-MM-DD").format("YYYY年MM月DD日");
      }
    });

    Vue.filter("formatJPDateTime", function (valueInput) {
      if (valueInput) {
        return moment(valueInput, "YYYY-MM-DD H:mm").format("YYYY年MM月DD日 H:mm");
      }
    });

    Vue.filter("currency", function (valueInput, currencyType = "") {
      if (valueInput) {
        return (
          currencyType +
          valueInput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } else {
        return 0;
      }
    });

    Vue.filter(
      "formatCurrency",
      function (valueInput, locale = "ja-JP", currency = "JPY") {
        return new Intl.NumberFormat(locale, {
          style: "currency",
          currency: currency,
        }).format(valueInput);
      }
    );

    Vue.filter("managePaymentStatus", function (valueInput) {
      return findValueInConstant(managePaymentStatus, valueInput);
    });

    Vue.filter("ageValue", function (valueInput = 0) {
      return moment().diff(new Date(valueInput), 'years') + '歳';
    });

    Vue.filter("managePaymentMethod", function (valueInput) {
      return findValueInConstant(managePaymentMethod, valueInput);
    });
    Vue.filter("managePaymentType", function (valueInput) {
      return findValueInConstant(managePaymentType, valueInput);
    });

    Vue.filter("invoiceStatus", function (valueInput) {
      return findValueInConstant(invoiceStatus, valueInput);
    });

    Vue.filter("invoicePaidStatus", function (valueInput) {
      return findValueInConstant(invoicePaidStatus, valueInput);
    });

    Vue.filter("invoiceSendStatus", function (valueInput) {
      return findValueInConstant(invoiceSendStatus, valueInput);
    });

    Vue.filter("genderType", function (valueInput) {
      return findValueInConstant(genderType, valueInput);
    });

    Vue.filter("desiredEmploymentStatus", function (valueInput) {
      return findValueInConstant(desired_employment_status, valueInput);
    });

    Vue.filter("finalEducation", function (valueInput) {
      return findValueInConstant(final_education, valueInput);
    });

    Vue.filter("jobChangeUrgency", function (valueInput) {
      return findValueInConstant(job_change_urgency, valueInput);
    });

    Vue.filter("testingFilter", (value) => `filter ===== ${value}`);
  },
};
