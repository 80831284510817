<template>
    <div class="for-template">
        <div class="d-block">
            <div class="d-flex justify-content-center m-3">
                <slot name="title-header">
                    <p class="h3 font-weight-bold">{{ titlePage }}</p>
                </slot>
            </div>

            <core-alert-system
                v-if="alert.show"
                @hide-alert="() => {this.alert.show = false}"
                :message="alert.message"
                :error="alert.error"
            />
            <b-overlay v-if="useLoading"
                :show="loading"
                opacity="0.7">
                <slot name="section">
                </slot>
                <div class="d-flex justify-content-center create__data__action" style="margin-top: 200px;">
                    <slot name="create-data">
                        <b-button
                            variant="primary"
                            class="btn-w-200"
                            v-b-modal.confirmDialog>{{ $t("student.button_register") }}
                        </b-button>
                    </slot>
                </div>
            </b-overlay>
        </div>

        <!-- SLOT MODAL -->
        <slot name="modals">
            <!-- Modal update-->
            <core-modal-confirm
                idComponent="confirmDialog"
                @ok="handleUpdate">
            </core-modal-confirm>
        </slot>
    </div>
</template>

<script>
    export default {
        name: 'CoreClientForm',
        props: {
            routeNameUpdate: {
                type: String,
                default: ''
            },
            useLoading: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false
            },
            titlePage: {
                type: String,
                default: 'title-page'
            }
        },
        data() {
            return {
                alert: {
                    show: false,
                    error: false,
                    message: ''
                }
            }
        },
        mounted() {
            this.$eventBus.$on('update-alert-system', this.handleAlertSystem)
        },

        methods: {
            handleUpdate() {
                this.$emit('confirm-ok');
            },
            handleAlertSystem({error, message}) {
                this.alert = {
                    show: true,
                    error: error,
                    message: message
                };
            }
        },
    }
</script>

<style scoped lang="scss">
    .core__card__form {
        margin-bottom: 5px;

        &-content {
            display: block;
            margin-top: 10px;
            margin-bottom: 5px;
            @media only screen and (min-width: 993px) {
                &__searching > .row div {
                    padding: 2px 0px !important
                }
            }
        }

        &-actions {
            display: block;
            margin: 5px 0;
        }
    }

    .create__data__action {
        margin: 10px 0;
    }
</style>
