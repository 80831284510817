var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b(
      { staticClass: "card", class: [_vm.type && `card-${_vm.type}`] },
      "div",
      _vm.$attrs,
      false
    ),
    [
      _vm.$slots.image
        ? _c("div", { staticClass: "card-image" }, [_vm._t("image")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.header || _vm.title
        ? _c(
            "div",
            { staticClass: "card-header", class: _vm.headerClasses },
            [
              _vm._t("header", function () {
                return [
                  _c(
                    "h4",
                    {
                      staticClass: "card-title",
                      class: _vm.titleClasses,
                      style: _vm.headerStyle,
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm._v(" "),
                  _vm.subTitle
                    ? _c("p", { staticClass: "card-category" }, [
                        _vm._v(_vm._s(_vm.subTitle)),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.default
        ? _c(
            "div",
            { staticClass: "card-body", class: _vm.bodyClasses },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("raw-content"),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "card-footer", class: _vm.footerClasses },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }