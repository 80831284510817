var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-expand-lg d-lg-none",
      class: !_vm.hideTopNav ? "d-lg-block" : "",
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "button",
          {
            staticClass: "navbar-toggler navbar-toggler-right",
            class: { toggled: _vm.$sidebar.showSidebar },
            attrs: {
              type: "button",
              "aria-controls": "navigation-index",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation",
            },
            on: { click: _vm.toggleSidebar },
          },
          [
            _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
            _vm._v(" "),
            _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
            _vm._v(" "),
            _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
          ]
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "collapse navbar-collapse justify-content-end" },
      [_c("ul", { staticClass: "navbar-nav ml-auto" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }