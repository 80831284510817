import _ from 'lodash';

const bindErrorValidation = (el, binding) => {
  let { validation, keyName } = binding.value;
  if (!_.isEmpty(validation) && Object.prototype.hasOwnProperty.call(validation, keyName)) {
    el.classList.add('is-invalid-feedback-message');
    el.textContent = validation[keyName];
  } else {
    el.classList.remove('is-invalid-feedback-message');
    el.textContent = '';
  }
}

export default {
  bind: function (el, binding) {
    bindErrorValidation(el, binding);
  },
  update: function (el, binding) {
    bindErrorValidation(el, binding);
  },
}
