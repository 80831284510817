export const role = {
    systemAdmin: 1,
    admin: 2,
    operator: 3,
    teacher: 4,
    student: 5,
};

export const status = {
    active: {
        value: 1,
        text: '有効'
    },
    inactive: {
        value: 2,
        text: '無効'
    }
};


export const gender = {
    undefined: {
        value: 1,
        text: '指定しない'
    },
    male: {
        value: 2,
        text: '男性'
    },
    female: {
        value: 3,
        text: '女性'
    },
};

export const canSendCard = {
    yes: {
        value: 1,
        text: '可'
    },
    no: {
        value: 0,
        text: '不可'
    },
};

// exp: text: cash ---> reference i18n
export const paymentMethod = {
    cash: {
        value: 1,
        text: 'cash'
    },
    paypay: {
        value: 2,
        text: 'paypay'
    },
    aplus: {
        value: 3,
        text: 'aplus'
    },
    credit_card: {
        value: 4,
        text: 'credit_card'
    }
};



export const studentStatus = {
    chasing: {
        value: 1,
        text: '追っかけ'
    },
    register: {
        value: 2,
        text: '申込'
    },
    enrollment: {
        value: 3,
        text: '入校'
    },
    leave_school: {
        value: 4,
        text: '退校',
    },
    absent: {
        value: 5,
        text: '休校',
    },
    waiting_for_application: {
        value: 6,
        text: '申込待ち'
    }
};

export const experienceResultStatus = {
    trial_application: {
        value: 1,
        text: '体験申込中'
    },
    trial_waiting: {
        value: 2,
        text: '体験キャンセル'
    },
    chasing: {
        value: 4,
        text: '追っかけ'
    },
    register: {
        value: 5,
        text: '入校申込'
    },
    enrollment: {
        value: 6,
        text: '入校／在校中'
    },
    absent: {
        value: 7,
        text: '休校',
    },
    leave: {
        value: 8,
        text: '退校'
    }
};

export const experienceResultTrial = [
    experienceResultStatus.trial_application.value,
    experienceResultStatus.trial_waiting.value,
    experienceResultStatus.chasing.value,
    experienceResultStatus.register.value,
];

export const experienceResultStatusMember = [
    experienceResultStatus.enrollment.value,
    experienceResultStatus.absent.value,
    experienceResultStatus.leave.value,
];


export const desiredReservationStatus = {
    trial: {
        value: 1,
        text: '体験'
    },
    inspection: {
        value: 2,
        text: '見学'
    },
    contact: {
        value: 3,
        text: '問い合わせ'
    }
};

export const reservationStatus = {
    reserved: {
        value: 1,
        text: '予約済'
    },
    lession_completed: {
        value: 2,
        text: 'レッスン済'
    },
    canceled: {
        value: 3,
        text: 'キャンセル'
    },
    waiting: {
        value: 4,
        text: 'キャンセル待ち'
    }
};

// exp: text: monthly_fee ---> reference i18n
export const feeType = [
    {
        value: 1,
        text: 'monthly_fee'
    },
    {
        value: 2,
        text: 'trial_lesson_fee',
    },
    {
        value: 3,
        text: 'enrollment_fee',
    },
    {
        value: 4,
        text: 'event_fee',
    },
    {
        value: 5,
        text: 'ticket_fee',
    }
];


// type of package
export const packageType = {
    package: 1,
    ticket: 2
};

// student stype && combine with lang
export const studentType = {
    vocal: {
        value: 1,
        text: 'label_student_vocal'
    },
    dance: {
        value: 2,
        text: 'label_student_dance'
    }
};


export const statisticGroupBy = {
    gb_operator_id: {
        value: 1,
        text: 'gb_operator_id'
    },
    gb_school_id: {
        value: 2,
        text: 'gb_school_id'
    },
    gb_teacher_id: {
        value: 3,
        text: 'gb_teacher_id'
    },
};

export const statisticTypeShow = {
    year: {
        value: 1,
        text: 'ts_year'
    },
    month: {
        value: 2,
        text: 'ts_month'
    }
}


// calendar type
export const calendarType = {
    lesson: {
        value: 1,
    },
    task: {
        value: 2,
    }
}

// course type
export const courseType = {
    vocal: {
        value: 1,
    },
    dance: {
        value: 2,
    }
};

// student & teacher type
export const typeStudentTeacher = {
    vocal: {
        value: 1,
    },
    dance: {
        value: 2
    }
};

// lesson type
export const lessonType = {
    vocal: {
        value: 1,
    },
    dance: {
        value: 2
    }
};

// repeat type
export const repeatType = {
    not_repeat: {
        value: 1,
        text: 'label_not_repeat',
    },
    everyday: {
        value: 2,
        text: 'label_every_day'
    },
    every_week: {
        value: 3,
        text: 'label_every_week',
    },
    every_month: {
        value: 4,
        text: 'label_every_month',
    },
    every_year: {
        value: 5,
        text: 'label_every_year',
    },
    every_weekday: {
        value: 6,
        text: 'label_every_weekday',
    }
};


// event type
export const eventType = {
    lesson: {
        value: 1,
        text: 'label_lesson',
    },
    task: {
        value: 2,
        text: 'label_task',
    }
};


// task type
export const taskType = {
    administrative : {
        value: 1,
        text: 'label_administrative',
    },
    training : {
        value: 2,
        text: 'label_training',
    },
    other : {
        value: 3,
        text: 'label_other',
    },
    off_day : {
        value: 4,
        text: 'label_off_day',
    },
    holiday: {
        value: 5,
        text: 'label_task_holiday',
    },
    fixed_holiday: {
        value: 6,
        text: 'label_task_fixed_holiday'
    }
};

// color type (calendar lang)
export const colorType = {
    lessonVocal: {
        value: 1, // lesson_vocal
        text: 'label_color_vocal_lesson'
    },
    lessonDance: {
        value: 2, // lesson_dance
        text: 'label_color_dance_lesson'
    },
    lessonTrialVocal: {
        value: 3, // lesson_trial_vocal
        text: 'label_color_vocal_trial_lesson'
    },
    lessonTrialDance: {
        value: 4, // lesson_trial_dance
        text: 'label_color_dance_trial_lesson'
    },
    administrative: {
        value: 5, // administrative
        text: 'label_color_office_work'
    },
    trainning: {
        value: 6,
        text: 'label_color_training'
    },
    other: {
        value: 7,
        text: 'label_color_others'
    },
    off_day: {
        value: 8,
        text: 'label_color_off_day'
    },
    holiday: {
        value: 9,
        text: 'label_color_holiday'
    }
};


export const reservationType = {
    trial: {
        value: 1,
        text: 'label_reservation_type_trial',
    },
    inspection: {
        value: 2,
        text: 'label_reservation_type_inspection',
    },
};


export const reservationStatusOrder = {
    reserved: {
        value: 1,
        text: 'label_reservation_status_reserved',
    },
    lesson_completed: {
        value: 2,
        text: 'label_reservation_status_lesson_completed',
    },
    canceled: {
        value: 3,
        text: 'label_reservation_status_canceled',
    },
    waiting_cancel: {
        value: 4,
        text: 'label_reservation_status_waiting_cancel'
    }
};


// option view calendar
export const optionViewCalendar = {
    dayGridMonth: 'dayGridMonth',
    timeGridWeek: 'timeGridWeek',
    timeGridDay: 'timeGridDay',
    listWeek: 'listWeek'
};

export const updateDeleteCalendarType = {
    today: {
        value: 1,
    },
    todayAndFuture: {
        value: 2,
    },
    todayDelete: {
        value: 3,
    },
    todayAndFutureDelete: {
        value: 4,
    }
};

export const age_type = {
    adult: {
        value: 1,
        text: '大人'
    },
    junior: {
        value: 2,
        text: 'ジュニア'
    },
    kid: {
        value: 3,
        text: 'キッズ'
    },
};

export const teacherType = {
    vocal: {
        value: 1,
        text: 'label_teacher_vocal'
    },
    dance: {
        value: 2,
        text: 'label_teacher_dance'
    }
};

export const paymentStatus = {
    not_deposited: {
        value: 1,
        text: "未入金"
    },
    deposited: {
        value: 2,
        text: "入金済"
    },
    cancellation: {
        value: 3,
        text: "打消"
    }
}

export const revenueFeeType = {
    monthly_fee : {
        value: 1,
        text: 'monthly_fee'
    },
    trial_lesson_fee:{
        value: 2,
        text: 'trial_lesson_fee'
    },
    enrollment_fee:{
        value: 3,
        text: 'enrollment_fee'
    },
    event_fee: {
        value: 4,
        text: 'event_fee'
    },
    ticket_fee: {
        value: 5,
        text: 'ticket_fee'
    }
}

export const paymentStatusMonthly = {
    onWayToSchool: {
        value: 6,
        text: '通学中',
        experience_result: 6
    },
    absent: {
        value: 7,
        text: '休学中',
        experience_result: 7
    },
    leave: {
        value: 8,
        text: '退校済',
        experience_result: 8
    }
}

export const tabCalendarEvent = {
    information: {
        value: 0,
    },
    reservation: {
        value: 1
    }
}