import i18n from '@/plugins/i18n';

export const zeusPaymentApi = "https://linkpt.cardservice.co.jp/cgi-bin/secure.cgi"
export const zeusOrderUrl = "https://linkpt.cardservice.co.jp/cgi-bin/credit/order.cgi"

export const roles = {}

export const menuManageType = {
    common: {
        value: 1
    },
    event: {
        value: 2
    }
}

export const managePaymentStatus = {
    waiting_for_payment: {
        value: 0,
        text: i18n.t("payment.label_waiting_for_payment"),
    },
    paid: {
        value: 1,
        text: i18n.t("payment.label_paid"),
    },
    expired: {
        value: 2,
        text: i18n.t("payment.label_expired"),
    },
};

export const managePaymentType = {
    membership_fee: {
        value: 1,
        text: i18n.t("payment.payment_type.membership_fee")
    }
}

export const managePaymentMethod = {
    credit_card: {
        value: 1,
        text: i18n.t("payment.payment_method.credit_card"),
    },
}

export const paymentMethod = {
    bank_transfer: {
        value: "BANK_TRANSFER",
        text: i18n.t("payment-agency.bank_transfer_flag"),
    },
    account_transfer: {
        value: "ACCOUNT_TRANSFER",
        text: i18n.t("payment-agency.account_transfer_flag"),
    },
    combini: {
        value: "COMBINI",
        text: i18n.t("payment-agency.combini_flag"),
    },
    debit: {
        value: "DEBIT",
        text: i18n.t("payment-agency.debit_flag"),
        model: "form.bank_transfer_flag",
    },
    credit: {
        value: "CREDIT",
        text: i18n.t("payment-agency.credit_flag"),
    },
    payeasy: {
        value: "PAYEASY",
        text: i18n.t("payment-agency.payeasy_flag"),
    },
    paypal: {
        value: "PAYPAL",
        text: i18n.t("payment-agency.paypal_flag"),
    },
};

export const genderType = {
    male: {
        value: 1,
        text: i18n.t('panel.label_gender_male')
    },
    female: {
        value: 2,
        text: i18n.t('panel.label_gender_female')
    },
    undefined: {
        value: 3,
        text: i18n.t('panel.label_gender_undefined')
    }
}

//even csm
export const useType = {
    required: {
        value: 1,
        text: i18n.t("event-cms.use_type_required"),
    },
    optional: {
        value: 2,
        text: i18n.t("event-cms.use_type_optional"),
    },
    hide: {
        value: 3,
        text: i18n.t("event-cms.use_type_hide"),
    },
};

export const useTypeEvtCheckList = {
    required: {
        value: 1,
        text: i18n.t('panel.label_client_required_field')
    },
    nonRequired: {
        value: 2,
        text: i18n.t('panel.label_client_non_required_field')
    }
};

export const operatorType = {
    // greater then equals (>=)
    gte: {
        value: 1,
        text: i18n.t('panel.label_greater_than_equals'),
    },
    // smaller than equals (<=)
    mte: {
        value: 2,
        text: i18n.t('panel.label_smaller_than_equals'),
    },
    // equal (=)
    equal: {
        value: 3,
        text: i18n.t('panel.label_equals')
    }
};

export const sendMailFlag = {
    isSendFlag: 1,
    noSendFlag: 0,
}


export const invoiceStatus = {
    pending: {
        value: 1,
        text: i18n.t("invoice.pending"),
    },
    confirm: {
        value: 2,
        text: i18n.t("invoice.confirm"),
    },
};


export const invoiceSendStatus = {
    not_send: {
        value: 1,
        text: i18n.t('invoice.not_send')
    },
    send: {
        value: 2,
        text: i18n.t('invoice.send')
    },
    resend: {
        value: 3,
        text: i18n.t('invoice.resend')
    }
}

export const invoicePaidStatus = {
    unpaid: {
        value: 1,
        text: i18n.t('invoice.unpaid')
    },
    paid: {
        value: 2,
        text: i18n.t('invoice.paid')
    },
    canceled: {
        value: 3,
        text: i18n.t('invoice.canceled')
    },
}

export const paginationOption = {
    paginate_20: {
        value: 20,
        text: "20",
        selected: true,
    },
    paginate_50: {
        value: 50,
        text: "50",
    },
    paginate_100: {
        value: 100,
        text: "100",
    },
    paginate_all: {
        value: "",
        text: "全て",
    },
};

export const accountTemp = 1;

export const feeMemberRegisterType = {
    free: {
        value: 1
    },
    non_free: {
        value: 0
    }
}

export const systemAminFlag = 1;

export const typeCmsComponent = {
    default: {
        value: 1,
    },
    extend: {
        value: 2,
    }
}

// mst_event/content column type
export const clientMyPageModuleType = {
//trn_my_page_setting column module_type
    event_entried: 1,
    event_result: 2,

// mst_content_cms column type
    slider: 1,
    advertise: 2,
    content: 3
}

// trn_my_page_settings column type
export const clientMyPageType = {
    common: 1,
    event: 2,
    content: 3,
    invoice: 4,
    account: 5
}

export const clientMyPageContentType = {
    slider: 1,
    banner: 2,
    news: 3,
}

export const clientLayoutType = {
    left: 1,
    right: 2,
}

export const limitSummary = 100;

export const invoiceTaxRate = {
    0: "0%",
    8: "8%",
    10: "10%"
}

export const registrationQuestionnaireOptions = {
    cost_reduction : {
        value: i18n.t('registration.cost_reduction'),
        text: i18n.t('registration.cost_reduction')
    },
    branding_power_through_social_contribution: {
        value: i18n.t('registration.branding_power_through_social_contribution'),
        text: i18n.t('registration.branding_power_through_social_contribution')
    },
    sales_approach_to_blue_chip_companies: {
        value: i18n.t('registration.sales_approach_to_blue_chip_companies'),
        text: i18n.t('registration.sales_approach_to_blue_chip_companies')
    },
    others: {
        value: i18n.t('registration.others'),
        text: i18n.t('registration.others')
    }
}
