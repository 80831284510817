var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "card-stats" }, [
    _c("div", { staticClass: "row" }, [
      _vm.$slots.header
        ? _c("div", { staticClass: "col-5" }, [
            _c(
              "div",
              { staticClass: "icon-big text-center" },
              [_vm._t("header")],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.content
        ? _c("div", { staticClass: "col-7" }, [
            _c("div", { staticClass: "numbers" }, [_vm._t("content")], 2),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.$slots.footer
      ? _c(
          "div",
          { staticClass: "stats", attrs: { slot: "footer" }, slot: "footer" },
          [_vm._t("footer")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }