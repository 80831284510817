import {
  SET_MEMBER_EXPIRED_AT,
  SET_MEMBER_INFO,
  SET_MEMBER_TOKEN,
  SET_IS_AUTH_MEMBER,
  SET_REFRESH_AUTH_MEMBER,
  SET_STATUS_MEMBER,
} from "./types";

export default {
  [SET_MEMBER_TOKEN](state, token) {
    state.token = token;
  },
  [SET_IS_AUTH_MEMBER](state, status) {
    state.accountLogin.isAuth = status; // success or fail
  },
  [SET_REFRESH_AUTH_MEMBER](state) {
    state.accountLogin = {
      isAuth: "fail",
      info: {},
    };
    state.token = null;
    state.expired_at = null;
  },

  [SET_MEMBER_INFO](state, user_data) {
    state.accountLogin.info = user_data;
  },

  [SET_MEMBER_EXPIRED_AT](state, miliseconds) {
    state.expired_at = miliseconds;
  },

  [SET_STATUS_MEMBER](state, payload) {
    state.accountLogin.isAuth = payload; // payload is true or fail
  },
};
