import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'

import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import '@/assets/scss/app.scss';
// import '@/assets/scss/client/index.scss';


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
