<template>
  <div class="indicator__page__view">
    <div class="bar-loading-5"></div>
  </div>
</template>

<script>

export default {
  name: 'IndicatorPage',
  data() {
    return {
      loadingPage: false,
    }
  },

}
</script>

<style scoped lang="scss">
.indicator__page__view {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 52;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.show {
    display: flex;
  }
}
</style>