<template>
  <b-pagination
      v-bind="$props"
      v-on="$listeners"
      :limit="maxLimit"
      size="md"
      hide-ellipsis
      align="center"></b-pagination>
</template>

<script>
import {maxLimitPagination} from '@/constants/core';

export default {
  name: "BasePagination",
  inheritAttrs: true,
  props: {
    value: [Number, String],
    currentPage: [Number, String],
    totalRows: [Number, String],
    perPage: [Number, String],
    maxLimit: {
      type: [Number, String],
      default: maxLimitPagination
    },
    prevText: {
      type: String,
      default: "‹"
    },
    nextText: {
      type: String,
      default: "›"
    }
  },
}
</script>