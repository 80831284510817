export default {
  path: "payment",
  name: "ManagePayment",
  component: () => import("@/layouts/TheContent"),
  redirect: { name: "ManageNewPayment" },
  children: [
    {
      path: "new",
      name: "ManageNewPayment",
      component: () =>
        import(
          /* webpackChunkName: "payment-new" */ "@/views/Manage/Payment/PaymentView.vue"
        ),
    },
    {
      path: "confirm",
      name: "ManageConfirmPayment",
      component: () =>
        import(
          /* webpackChunkName: "payment-confirm" */ "@/views/Manage/Payment/ConfirmPaymentView.vue"
        ),
    },
    {
      path: "change-credit-card",
      name: "ManageChangeCreditCard",
      component: () =>
        import(
          /* webpackChunkName: "payment-change-credit-card" */ "@/views/Manage/Payment/ChangeCreditCardView.vue"
        ),
    },{
      path: "asct/:payment_method",
      name: "ManageActOnSpecifiedCommercialTransaction",
      component: () =>
        import(
          /* webpackChunkName: "payment-asct" */ "@/views/Manage/Payment/ActOnSpecifiedCommercialTransaction.vue"
        ),
    },
  ],
};
