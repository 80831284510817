<template>
  <div class="d-block">
    <multiselect
        v-model="multiple_value"
        :options="options"
        allowEmpty
        label="text"
        trackBy="id"
        multiple
        searchable
        :placeholder="placeholder"
        :disabled="disabled"
        :class="{ 'is-invalid': isFailValidation }"
    >
      <template #noOptions>
        <span>{{ noOptions }}</span>
      </template>

      <template #placeholder>
        <span>{{ placeholder }}</span>
      </template>
    </multiselect>

    <b-form-invalid-feedback v-if="isFailValidation">
      {{ textValidation }}
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import handleValidateMixin from '@/mixins/handle-validate-mixin';

export default {
  name: 'BaseMultipleSelect',
  mixins: [handleValidateMixin],
  components: {
    Multiselect
  },
  props: {
    value: Array,
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    noOptions() {
      return this.$t('panel.label_no_data')
    },
    placeholder() {
      return this.$t('panel.label_please_choose');
    },
    multiple_value: {
      get() {
        if (this.options.length && this.value) {
          let findOptionByListId = [...this.options].filter(item => this.value.includes(item.id));

          return findOptionByListId;
        } else {
          return [];
        }
      },
      set(newValue) {
        let reduceId = newValue.reduce(function (listId, item) {
          listId.push(item.id)
          return listId;
        }, [])
        this.$emit('input', reduceId);
      }
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.multiselect__tags {
  border: 1px solid #ced4da;
  color: #495057;
  padding: 5px 40px 0 .75rem;
}

.multiselect__select {
  width: auto;

  &:before {
    border-color: #343a40 transparent transparent;
  }
}

.multiselect__placeholder {
  color: #495057;
  font-size: 16px;
  margin-bottom: 5px;
  // height: 41px;
}

.multiselect.is-invalid {
  .multiselect__tags {
    border: 1px solid #dc3545 !important;
  }
}
</style>