var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container__page__not__found" }, [
      _c(
        "p",
        { staticClass: "text-center message d-flex align-items-center m-0" },
        [
          _c("b", { staticClass: "h2 font-weight-bold status m-0" }, [
            _vm._v("404"),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "text h4" }, [_vm._v("Not Found")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }