<template>
    <b-modal
        :id="idComponent"
        centered
        :header-bg-variant="color"
        :title="$t('panel.label_confirm_title')"
        v-on="$listeners"
        >
        <template #default>
            <slot name="content">
                <p class="confirmText text-left">{{ confirmText }}</p>
            </slot>
        </template>
        <template #modal-footer="{ ok,cancel }">
            <div class="d-flex w-100 justify-content-end">
                <b-button :variant="color" @click="ok()" style="margin-right: 5px;">
                    {{  textButtonConfirm }}
                </b-button>
                <b-button @click="cancel">
                    {{ $t("panel.label_cancel_btn_modal") }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'CoreModal',
    props: {
        remove: {
            type: Boolean,
            default: false
        },
        idComponent: {
            type: String,
            default: 'modalConfirm'
        },
        customConfirmText: {
            type: String,
            default: ''
        }
    },
    computed: {
        color() {
            return this.remove ? 'danger' : 'primary';
        },
        confirmText() {
            return !_.isEmpty(this.customConfirmText) ? this.customConfirmText : (this.remove ? this.$t('panel.label_confirm_text_remove') : this.$t('panel.label_confirm_text_save'));
        },
        textButtonConfirm() {
            return this.remove ? this.$t('panel.label_delete_btn_modal') : 'OK';
        }
    },
    methods: {
        ok() {
            this.$emit('confirm-process');
        },
        closeModal() {
            this.$emit('update:show', false);
        },
    }
}
</script>

<style scoped>
.confirmText{
    white-space: pre-line;
    width: 100%;
    text-align: center;
}
</style>
