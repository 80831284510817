import {
  GET_SOMETHING,
  GET_INSTANCE_SEARCHING,
  GET_PATH_SEARCH,
  GET_VALIDATION,
} from './types';

export default {
  [GET_SOMETHING](state) {
    return state.nameApp;
  },
  [GET_INSTANCE_SEARCHING](state) {
    return state.searchingInstance;
  },
  [GET_PATH_SEARCH]: (state) => (routeName) => {
    let instanceSearch = state.searchingInstance[routeName];
    if (!instanceSearch) {
      return { name: routeName };
    }
    return { path: instanceSearch };
  },
  [GET_VALIDATION]: (state) => state.validation,
  getPageNotFoundClientStatus: (state) => state.pageNotFoundClient,
}
