import {auth as authManage, authPaid} from '@/middlewares/manage';

export default {
    path: 'corporation-info',
    name: 'CorporationInfoManage',
    component: () => import(/* webpackChunkName: "corportaion-info-layout-view" */'@/views/Manage/CorporationInfo/LayoutView.vue'),
    redirect: { name: 'EditCorporationInfoManage' },
    meta : {
    },
    children: [
        {
            path: '', // /manage/corporation-info
            name: 'EditCorporationInfoManage',
            component: () => import(/* webpackChunkName: "corportaion-info-edit-view" */'@/views/Manage/CorporationInfo/Edit.vue'),
            meta: {
                middleware: [authPaid]
            }
        }
    ]
}
