import store from "@/stores";
import Router from "@/routes";
import { checkAuthAdminLifeTime } from "@/helpers/authenticate";
import { ACT_FORCE_FRESH_AUTH_ADMIN } from "@/stores/modules/AuthAdmin/types";

export default function authAdmin({to, next}) {
  let isAuthManage = checkAuthAdminLifeTime();
  // not authenticated
  if (!isAuthManage) {
    let { path } = to
    // remove store
    store.dispatch(`AuthAdmin/${ACT_FORCE_FRESH_AUTH_ADMIN}`)
    setTimeout(() => {
      Router.push({ name: 'AdminLogin', query: { redirect: path } });
    },500)
  } else {
    // authenticate is TRUE
    if (to.name === 'AdminLogin') {
      return Router.push({ name: 'AdminHome' });
    }
    return next();
  }
}
