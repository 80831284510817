var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navbar__client__wrapper dance",
      attrs: { id: "client__header__menu" },
    },
    [
      _c("b-container", [
        _c(
          "div",
          [
            _c("center", [
              _c("span", { staticClass: "bar" }, [_vm._v("|")]),
              _vm._v(" "),
              _c("span", { staticClass: "mr-1 ml-1 bar" }, [
                _vm._v(_vm._s(_vm.$t("member.label_title_edit"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "bar" }, [_vm._v("|")]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }