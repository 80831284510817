import $ from "jquery";
export default {
  props: {
    maxLength: {
      type: Number,
      default: 0,
    },
  },
  bind: function (el, binding) {
    el.handler = function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        binding.value.maxLength > 0 &&
        this.value.length >= binding.value.maxLength
      ) {
        if (!charCode) {
          $(this).val(this.value.slice(0, binding.value.maxLength));
        }
        evt.preventDefault();
        return true;
      }
    };
    el.addEventListener("keypress", el.handler);
    el.addEventListener("change", el.handler);
  },
  unbind: function (el) {
    el.removeEventListener("keypress", el.handler);
    el.removeEventListener("change", el.handler);
  },
};
