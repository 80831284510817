<template>
    <div class="container__deny__access__page">
        <p class="text-center message d-flex align-items-center m-0">
            <b class="h2 font-weight-bold status m-0">403</b>
            <label class="text h4">Forbidden</label>
        </p>
    </div>
</template>

<script>
export default {
    name: 'DenyAccess',

}
</script>

<style scoped lang="scss">
    @import '../assets/sass/component/deny-not-found-page.scss';
</style>
