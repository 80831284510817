<template>
  <base-modal-dialog :name="name" height="auto" width="500" resizable draggable>
    <b-card
      :header="$t('panel.label_confirm_title')"
      :header-bg-variant="color"
      header-text-variant="white"
      class="text-white m-0"
      no-body
    >
      <b-card-body>
        <div class="d-block">
          <slot name="content">
            <p class="confirmText text-center text-dark" v-if="!useVHtml">
              {{ confirmText }}
            </p>
            <p class="confirmText text-center text-dark" v-else>
              <span v-html="confirmText"></span>
            </p>
          </slot>
        </div>

        <div class="d-block mt-2">
          <slot name="btn-action">
            <div class="d-flex w-100 justify-content-around g-5">
              <b-button @click="cancel">
                {{ cancelButton }}
              </b-button>
              <b-button :variant="color" @click="confirmOK">
                {{ textButtonConfirm }}
              </b-button>
            </div>
          </slot>
        </div>
      </b-card-body>
    </b-card>
  </base-modal-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "BaseModalConfirm",

  props: {
    name: {
      type: String,
      default: "base-modal-confirm",
    },
    remove: {
      type: Boolean,
      default: false,
    },
    idComponent: {
      type: String,
      default: "modalConfirm",
    },
    customConfirmText: {
      type: String,
      default: "",
    },
    useVHtml: {
      type: Boolean,
      default: false,
    },
    customOkText: {
      type: String,
    },
    customCancelText: {
      type: String,
    },
  },
  computed: {
    color() {
      return this.remove ? "danger" : "primary";
    },
    confirmText() {
      return !_.isEmpty(this.customConfirmText)
        ? this.customConfirmText
        : this.remove
        ? this.$t("panel.label_confirm_text_remove_new")
        : this.$t("panel.label_confirm_text_save_new");
    },
    textButtonConfirm() {
      return this.customOkText
        ? this.customOkText
        : this.$t("panel.label_confirm_yes");
    },
    cancelButton() {
      return this.customCancelText
        ? this.customCancelText
        : this.$t("panel.label_confirm_no");
    },
  },
  methods: {
    confirmOK() {
      this.$modal.hide(this.name);
      this.$emit("confirm-process");
    },
    closeModal() {
      this.$emit("update:show", false);
    },
    cancel() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style scoped>
.card {
  border: none;
}

.confirmText {
  white-space: pre-line;
  width: 100%;
  text-align: center;
}
</style>
