<template>
  <div class="d-block">

    <div class="select__file__button__wrapper d-flex g-5 w-100" @click="selectFileHandler"
      :class="{ 'border-validation-error ': isFailValidation, 'not__error': !isFailValidation }">
      <span class="select__file__button__wrapper-select__button bg-secondary text-white">{{ $t('panel.label_browse_file')
      }}</span>
      <span class="select__file__button__wrapper-file__name text-dark">{{ displayFileName }}</span>
    </div>

    <b-form-file ref="selectFileRef" :value="value" :accept="accept" @change="pickFile" :disabled="disabled"
      :state="!isFailValidation" class="d-none" :browse-text="$t('panel.label_browse_file')"
      :placeholder="$t('panel.label_please_choose_one_file')" />
    <template v-if="selectImage && displayImage">
      <div class="d-flex g-5 mt-2">
        <div class="review__img__wrapper text-danger">
          <b-img thumbnail fluid :src="displayImage" alt="Image 1" class="h-100 w-100"></b-img>
        </div>
        <div v-if="(image || imageUrl) && !disabled" @click="removeFile" class="text-danger cursor-pointer">
          <i class="fas fa-trash"></i>
        </div>
      </div>
    </template>
    <div class="is-invalid-feedback-message" v-if="isFailValidation">
      {{ textValidation }}
    </div>
    <div class="is-invalid-feedback-message" v-if="errorUploadFilesSize">
      {{ $t('panel.label_upload_file_size_fail', { size: maxTotalSizeMb, name: labelInputFile }) }}
    </div>
    <!-- hint text -->
    <div class="d-block hint__text" v-html="hintText"></div>
  </div>
</template>

<script>
import handleValidateMixin from '@/mixins/handle-validate-mixin';
import useLimitTotalFileSize from '@/mixins/use-limit-total-file-size';

export default {
  name: 'BaseInputFile',
  mixins: [handleValidateMixin, useLimitTotalFileSize],
  props: {
    value: null,
    accept: {
      type: String,
      default: ".jpg, .png"
    },
    selectImage: {
      type: Boolean,
      default: true,
    },
    imageUrl: {
      type: String,
      default: null
    },
    compareSize: {
      type: Boolean,
      default: false,
    },
    maxMb: {
      type: Number,
      default: 0
    },
    fileName: String,
    labelInputFile: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: '',
    }
  },
  computed: {
    displayFileName() {
      if (this.fileName) {
        return this.fileName;
      }
      return this.image?.name || this.$t('panel.label_please_choose_one_file');
    },
    displayImage() {
      return this.reviewImg || this.imageUrl
    },
  },
  data() {
    return {
      image: null,
      reviewImg: null,
      errorMaxSize: false
    }
  },

  methods: {
    pickFile(e) {
      if (e.target.files.length) {
        this.image = e.target.files[0];
        this.reviewImg = URL.createObjectURL(this.image)
        if (this.isValidUploadTotalSize(e.target.files)) {
          this.$emit('input', this.image)
          this.$emit('selected-image');
        } else {
          this.$emit('input', null)
          this.image = null;
          this.reviewImg = null;
        }
      }

    },
    removeFile() {
      if (this.image && this.reviewImg) {
        this.image = null;
        this.reviewImg = null;
        this.$emit('input', null);
      } else {
        this.$emit('input', null);
        this.$emit('remove-current-image');
      }
    },

    selectFileHandler() {
      this.$refs.selectFileRef.$el.querySelector('.custom-file-input').click()
    },

    isValidFileSize() {
      let currentSizeMb = this.image.size / 1024 / 1024;
      console.log(currentSizeMb, this.compareSize);
      if (this.compareSize) {
        if (currentSizeMb > this.maxMb) {
          this.errorMaxSize = true;
          return false;
        } else {
          this.errorMaxSize = false;
          return true;
        }
      }
      return true;
    }
  }
}
</script>

<style scoped lang="scss">
.review__img__wrapper {
  display: block;
  position: relative;
  height: 150px;
  width: 150px;

  .remove__icon {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
  }
}

.select__file__button__wrapper {
  display: flex;
  gap: 5px;
  min-width: 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);

  &.not__error {
    border-color: #ced4da;
  }

  &-select__button,
  &-file__name {
    display: flex;
    height: 100%;
    padding: 8px 12px;
  }

  &-select__button {
    text-align: center;
    border-right: 1px solid #ced4da;
    word-break: keep-all;
  }

  &-file__name {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>