// actions
export const ACT_MEMBER_LOGIN = 'ACT_MEMBER_LOGIN';
export const ACT_MEMBER_CONFIRM_LOGIN = 'ACT_MEMBER_CONFIRM_LOGIN';
export const ACT_MEMBER_LOGOUT = 'ACT_MEMBER_LOGOUT';
export const ACT_GET_MEMBER_INFO = 'ACT_GET_MEMBER_INFO';
export const ACT_FORCE_FRESH_AUTH_MEMBER = 'ACT_FORCE_FRESH_AUTH_MEMBER';
export const ACT_FRESH_AUTH_KEEP_MEMBER_INFO = 'ACT_FRESH_AUTH_KEEP_MEMBER_INFO';
export const ACT_GET_MEMBER_PAYMENT_INFO = 'ACT_GET_MEMBER_PAYMENT_INFO';

// mutations
export const SET_MEMBER_TOKEN = 'SET_MEMBER_TOKEN';
export const SET_IS_AUTH_MEMBER = 'SET_IS_AUTH_MEMBER';
export const SET_REFRESH_AUTH_MEMBER = 'SET_REFRESH_AUTH_MEMBER';
export const SET_REFRESH_AUTH_KEEP_MEMBER_INFO = 'SET_REFRESH_AUTH_KEEP_MEMBER_INFO';
export const SET_MEMBER_INFO = 'SET_MEMBER_INFO';
export const SET_MEMBER_EXPIRED_AT = 'SET_MEMBER_EXPIRED_AT';
export const SET_STATUS_MEMBER = 'SET_STATUS_MEMBER';
export const SET_MEMBER_PAYMENT_INFO = 'SET_MEMBER_PAYMENT_INFO';

// getters
export const GET_AUTH_MEMBER_TOKEN = 'GET_AUTH_MEMBER_TOKEN';
export const GET_MEMBER_IS_AUTH = 'GET_MEMBER_IS_AUTH';
export const GET_MEMBER_ID = 'GET_MEMBER_ID';
export const GET_MEMBER_EMAIL = 'GET_MEMBER_EMAIL';
export const GET_MEMBER_ROLE = 'GET_MEMBER_ROLE';
export const GET_MEMBER_TYPE = 'GET_MEMBER_TYPE';
export const GET_IS_SCREEN_MEMBER = 'GET_IS_SCREEN_MEMBER';
export const GET_SYSTEM_MEMBER_FLAG = 'GET_SYSTEM_MEMBER_FLAG';
export const GET_MEMBER_INFO = 'GET_MEMBER_INFO';
export const GET_MEMBER_PAYMENT_INFO = 'GET_MEMBER_PAYMENT_INFO';
export const GET_IS_PAID_MEMBER = 'GET_IS_PAID_MEMBER';
export const GET_MEMBER_PAYMENT_ID = 'GET_MEMBER_PAYMENT_ID';
