<template>
  <div class="d-block">

    <div class="select__file__button__wrapper d-flex g-5 w-100" @click="selectFileHandler"
    :class="{ 'border-validation-error ': isFailValidation, 'not__error': !isFailValidation  }"
    >
      <span class="select__file__button__wrapper-select__button bg-secondary text-white" >{{ $t('panel.label_browse_file') }}</span>
      <span class="select__file__button__wrapper-file__name text-dark">{{ displayFileName }}</span>
    </div>

    <b-form-file
      ref="selectFileRef"
      :value="value"
      :accept="accept"
      @change="pickFile"
      :disabled="disabled"
      :state="!isFailValidation"
      class="d-none"
      :browse-text="$t('panel.label_browse_file')"
      :placeholder="$t('panel.label_please_choose_one_file')"
    />
    <template v-if="selectImage && displayImage">
      <div class="d-flex g-5 mt-2">
        <div class="review__img__wrapper text-danger">
          <b-img thumbnail fluid :src="displayImage" alt="Image 1" class="h-100 w-100"></b-img>
        </div>
        <div
          v-if="(image || imageUrl) && !disabled"
          @click="removeFile"
          class="text-danger cursor-pointer">
          <i class="fas fa-trash"></i>
        </div>
      </div>
    </template>
    <div class="is-invalid-feedback-message" v-if="isFailValidation">
        {{ textValidation }}
    </div>
    <div class="is-invalid-feedback-message" v-if="Object.prototype.hasOwnProperty.call($validation, validateFileSizeName)">
      {{  $validation[validateFileSizeName] }}
    </div>
    <!-- <div class="is-invalid-feedback-message" v-if="Object.prototype.hasOwnProperty.call($validation, validateFileExtension)">
      {{  $validation[validateFileExtension] }}
    </div> -->
    <!-- hint text -->
    <div class="d-block hint__text">
      <div v-html="hintText"></div>
    </div>
  </div>
</template>

<script>
import handleValidateMixin from '@/mixins/handle-validate-mixin';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'BaseInputFileNoLimit',
  mixins: [handleValidateMixin],
  props: {
    value: null,
    accept: {
      type: String,
      default: ".png, .jpg, .jpeg"
    },
    selectImage: {
      type: Boolean,
      default: true,
    },
    imageUrl: {
      type: String,
      default: null
    },
    compareSize: {
      type: Boolean,
      default: false,
    },
    maxMb: {
      type: Number,
      default: 0
    },
    fileName: String,
    labelInputFile: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: '',
    }
  },
  computed: {
    displayFileName() {
      if (this.fileName) {
        return this.fileName;
      }
      return this.image?.name || this.$t('panel.label_please_choose_one_file');
    },
    displayImage() {
      return this.reviewImg || this.imageUrl
    },
    validateFileSizeName() {
      return `base_input_file_error_file_size_${this.uidComponent}`;
    },
    validateFileExtension() {
      return `base_input_file_error_file_extension_${this.uidComponent}`;
    }
  },
  data() {
    return {
      image: null,
      reviewImg: null,
      uidComponent: null,
    }
  },

  created() {
    this.uidComponent = this._uid;
    if (this.value instanceof File) { // use for facility (admin)
      this.image = this.value;
      this.reviewImg = URL.createObjectURL(this.image)
    }
  },

  methods: {
    pickFile(e) {
      if (e.target.files.length) {
        const fileUpload = e.target.files[0];
        // refresh validate local
        this.formatValidateFile();

        //
        if (this.validateExtension(fileUpload) && this.isValidFileSize(fileUpload)) {
          this.image = fileUpload;
          this.reviewImg = URL.createObjectURL(this.image)
          // check file size

          if (this.image.size <= this.$maxUploadFileSize.byte) {
            this.$emit('input', this.image)
            this.$emit('selected-image');
          } else {
            this.$emit('input', null)
            this.image = null;
            this.reviewImg = null;
          }
        }
        this.$refs.selectFileRef.reset();
      }
    },
    removeFile() {
      if (this.image && this.reviewImg) {
        this.image = null;
        this.reviewImg = null;
        this.$emit('input', null);
      } else {
        this.$emit('input', null);
        this.$emit('remove-current-image');
      }
    },

    selectFileHandler() {
      this.$refs.selectFileRef.$el.querySelector('.custom-file-input').click()
    },

    isValidFileSize(file) {
      let currentSizeMb = file.size;
      if (currentSizeMb > this.$maxUploadFileSize.byte) {
        let cloneValidation = cloneDeep(this.$validation);

        cloneValidation[`${this.validateFileSizeName}`] = this.$t('panel.label_upload_file_size_fail', {
          size: this.$maxUploadFileSize.mb,
          name: this.labelInputFile
        })
        this.$setValidation({
          ...cloneValidation
        });
        return false;
      } else {
        let cloneValidation = cloneDeep(this.$validation);
        delete cloneValidation[`${this.validateFileSizeName}`];
        this.$setValidation({
          ...cloneValidation
        })
        return true;
      }
    },

    validateExtension(file) {
      let extFile= file.type.split('/').pop();

      if (this.accept === "*") {
        return true;
      } else {
        let extensions = this.accept.split(',').map(item => item.replaceAll(' ' ,'').replace('.', ''));
        let validExt = extensions.includes(extFile);
        if (!validExt) {
          let cloneValidation = cloneDeep(this.$validation);
          cloneValidation[`${this.validateFileExtension}`] = this.$t('panel.label_file_is_image_type', {
            name: this.labelInputFile
          })
          this.$setValidation({
            ...cloneValidation
          });
        } else {
          let cloneValidation = cloneDeep(this.$validation);
          delete cloneValidation[`${this.validateFileExtension}`];
          this.$setValidation({
            ...cloneValidation
          })
        }
        return validExt;
      }
    },

    formatValidateFile() {
      let cloneValidation = cloneDeep(this.$validation);
      delete cloneValidation[`${this.validateFileExtension}`];
      delete cloneValidation[`${this.validateFileSizeName}`];
      this.$setValidation({
        ...cloneValidation
      })
    }
  }
}
</script>

<style scoped lang="scss">
.review__img__wrapper {
  display: block;
  position: relative;
  height: 150px;
  width: 150px;

  .remove__icon {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
  }
}
.select__file__button__wrapper {
  display: flex;
  gap: 5px;
  min-width: 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);

  &.not__error {
    border-color: #ced4da;
  }

  &-select__button,  &-file__name {
    display: flex;
    height: 100%;
    padding: 8px 12px;
  }

  &-select__button {
    text-align: center;
    border-right: 1px solid #ced4da;
    word-break: keep-all;
  }

  &-file__name {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
