<template>
  <div class="for-template">


    <!-- Content form searching -->
    <b-card
        :header="title"
        header-bg-variant="primary"
        class="core__card__form"
        header-text-variant="white-text"
        v-if="showFormCard">
      <b-form v-on="$listeners"
              autocomplete="false"
              @keydown="handleKeyDown"
      >

        <!-- content -->
        <div class="core__card__form-content">
          <div class="core__card__form-content__searching">
            <slot name="content"></slot>
          </div>
        </div>
        <!-- overlay -->
        <b-overlay
            v-if="useLoading"
            :show="loading"
            opacity="0.7"
            no-wrap>
        </b-overlay>

        <!-- SLOT ACTIONS -->
        <div class="core__card__form-actions">
          <slot name="actions">
            <div class="d-flex justify-content-center">
              <b-button
                @click="reset"
                variant="warning"
                class="mr-5 btn-w-100">{{ $t("panel.label_clear") }}
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                class="ml-5 btn-w-100">{{ $t("panel.label_search") }}
              </b-button>
            </div>
          </slot>
        </div>
      </b-form>
    </b-card>
    <div
        v-if="showCreateButton"
        class="d-flex justify-content-end create__data__action g-5"
    >
      <slot v-if="showCreateButton"
            name="create-data"
            :routeCreateName="routeNameCreate"
      >
        <template>
          <router-link :to="{ name: routeNameCreate }">
            <b-button variant="success">{{ $t("panel.label_create") }}</b-button>
          </router-link>
        </template>
      </slot>
    </div>
    <slot name="area-custom">
    </slot>
  </div>
</template>

<script>

export default {
  name: 'BaseCardSearch',
  props: {
    title: {
      type: String,
      default: 'card-title'
    },
    showCreateDataBtn: {
      type: Boolean,
      default: true
    },
    routeNameCreate: {
      type: String,
      default: ''
    },
    useLoading: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    showFormCard: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showCreateButton() {
      return this.showCreateDataBtn; // show prop
    }
  },
  methods: {
    goBackSearching(nameRoute) {
      return window.goBackSearchingRoute(nameRoute);
    },

    handleRemove() {
      this.$emit('confirm-remove');
    },

    handleOk() {
      this.$emit('confirm-ok');
    },

    reset(e) {
      e.preventDefault();
      this.$emit('reset')
      // if (Object.prototype.hasOwnProperty.call(this.$parent, 'reset')) {
      //     this.$parent.reset();
      // }
    },
    handleKeyDown(event) {
      var keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
      if (keyCode == 13) {

        if (!e) var e = window.event;

        e.cancelBubble = true;
        e.returnValue = false;

        if (e.stopPropagation) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.core__card__form {
  margin-bottom: 5px;

  &-content {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    @media only screen and (min-width: 993px) {
      &__searching > .row div {
        padding: 2px 0px !important
      }
    }
  }

  .title__searching {
    font-weight: 600;
    font-size: 15px;
    color: black;
    margin: 0;
  }

  &-actions {
    display: block;
    margin: 5px 0;
  }
}

.create__data__action {
  margin: 10px 0;
}
</style>
