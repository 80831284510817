var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "mt-2", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        { staticClass: "mb-0 pb-0" },
        [
          _vm.loading
            ? _c("b-skeleton-table", {
                attrs: {
                  rows: 5,
                  columns: _vm.tableColumnLoading,
                  "table-props": { bordered: true, striped: true },
                },
              })
            : _c(
                "div",
                [
                  _vm.showTotalRows
                    ? _c(
                        "div",
                        {
                          staticClass: "show__total__result",
                          staticStyle: { gap: "10px" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("panel.label_total_searching"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                `${_vm.totalRows}${_vm.$t(
                                  "panel.label_unit_search"
                                )}`
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("filter"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "scroll-horizontal",
                          rawName: "v-scroll-horizontal",
                        },
                      ],
                      class: { "table-responsive": _vm.responsive },
                    },
                    [_vm._t("default")],
                    2
                  ),
                ],
                2
              ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("pagination", function () {
        return [
          _c("b-card-text", [
            _vm.showPagination
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "base-pagination",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              value: _vm.currentPage,
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              align: "center",
                            },
                          },
                          "base-pagination",
                          _vm.$props,
                          false
                        ),
                        _vm.$listeners
                      )
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }