var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-block" }, [
    _c(
      "div",
      {
        staticClass: "select__file__button__wrapper d-flex g-5 w-100",
        class: {
          "border-validation-error ": _vm.isFailValidation,
          not__error: !_vm.isFailValidation,
        },
        on: { click: _vm.selectFileHandler },
      },
      [
        _c(
          "span",
          {
            staticClass:
              "select__file__button__wrapper-select__button bg-secondary text-white",
          },
          [_vm._v(_vm._s(_vm.$t("panel.label_browse_file")))]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "select__file__button__wrapper-file__name text-dark" },
          [_vm._v(_vm._s(_vm.displayFileName))]
        ),
      ]
    ),
    _vm._v(" "),
    _c("input", {
      ref: "selectMultipleFile",
      staticClass: "d-none",
      attrs: {
        type: "file",
        multiple: "",
        accept: _vm.accept,
        disabled: _vm.disabled,
      },
      on: { change: _vm.pickFile },
    }),
    _vm._v(" "),
    _vm.isFailValidation
      ? _c("div", { staticClass: "d-block my-2 is-invalid-feedback-message" }, [
          _vm._v("\n    " + _vm._s(_vm.textValidation) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-none",
        class: {
          "d-block is-invalid-feedback-message":
            Object.prototype.hasOwnProperty.call(
              _vm.$validation,
              _vm.nameComponentValidation
            ),
        },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$validation[_vm.nameComponentValidation]) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "d-block hint__text",
      domProps: { innerHTML: _vm._s(_vm.hintText) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "display__file__name__wrapper mt-2" },
      [
        _vm.listFiles.length
          ? [
              _vm._l(_vm.listFiles, function (itemFile, index) {
                return [
                  itemFile
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "d-block mb-1",
                          class: {
                            "border border-danger rounded":
                              Object.prototype.hasOwnProperty.call(
                                _vm.validationResult,
                                `${_vm.keyName}.${index}.file`
                              ),
                          },
                        },
                        [
                          _c("div", { staticClass: "d-block" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "display__file__name__wrapper-file__item",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "display__file__name__wrapper-file__item-file__name text-primary",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(itemFile.file_name) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.disabled
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "display__file__name__wrapper-file__item-remove__file text-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItemFile(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa-solid fa-xmark",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            Object.prototype.hasOwnProperty.call(
                              _vm.validationResult,
                              `${_vm.keyName}.${index}.file`
                            )
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "is-invalid-feedback-message",
                                  },
                                  [
                                    _vm._v(
                                      "\n              - " +
                                        _vm._s(
                                          _vm.validationResult[
                                            `${_vm.keyName}.${index}.file`
                                          ]
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.fileUrls.length
          ? _vm._l(_vm.fileUrls, function (itemUrl) {
              return _c(
                "div",
                { key: itemUrl.id, staticClass: "d-block mb-1" },
                [
                  !itemUrl.is_deleted
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "display__file__name__wrapper-file__item",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "display__file__name__wrapper-file__item-file__name text-primary",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-primary",
                                  attrs: {
                                    href: itemUrl.file_path_url,
                                    download: itemUrl.file_name,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(itemUrl.file_name))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "display__file__name__wrapper-file__item-remove__file text-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeExistFile(itemUrl)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa-solid fa-xmark" })]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }