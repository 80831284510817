import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import storage from "@/helpers/storageManage";
import {
  ApplicationManageAccountInfo,
  ApplicationManageIsAuth,
  ApplicationManageStorageToken,
  ApplicationManageTokenExpiredAt,
  ApplicationManagePaymentInfo,
  ApplicationManageWithdrawFlag,
  ApplicationMembershipPeriod,
} from "@/plugins/setting";

const state = () => ({
  expired_at: storage.getStorage(ApplicationManageTokenExpiredAt), // the time that token is not valid anymore
  token: storage.getStorage(ApplicationManageStorageToken),
  accountLogin: {
    isAuth: storage.getStorage(ApplicationManageIsAuth) || "fail",
    info: storage.getStorage(ApplicationManageAccountInfo)
      ? JSON.parse(storage.getStorage(ApplicationManageAccountInfo))
      : {},
  },
  paymentInfo: storage.getStorage(ApplicationManagePaymentInfo)
    ? JSON.parse(storage.getStorage(ApplicationManagePaymentInfo))
    : {},
  withdraw_flag: storage.getStorage(ApplicationManageWithdrawFlag)
    ? parseInt(storage.getStorage(ApplicationManageWithdrawFlag))
    : 0,
  membershipPeriod: storage.getStorage(ApplicationMembershipPeriod)
    ? storage.getStorage(ApplicationMembershipPeriod)
    : "",
  permission_menu: [],
  permission_tree_menu: [],
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
