var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    _vm._b(
      {
        attrs: {
          options: _vm.localOptions,
          multiple: true,
          taggable: true,
          "track-by": "id",
          label: "text",
          placeholder: _vm.options
            ? _vm.$t("panel.label_please_choose")
            : _vm.$t("panel.label_no_data"),
          selectLabel: "",
          deselectLabel: "",
          selectedLabel: "",
          disabled: _vm.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function (props) {
              return [
                _vm._v("\n        " + _vm._s(props.option.text) + "\n    "),
              ]
            },
          },
        ]),
        model: {
          value: _vm.select,
          callback: function ($$v) {
            _vm.select = $$v
          },
          expression: "select",
        },
      },
      "multiselect",
      _vm.$props,
      false
    ),
    [
      _vm._v(" "),
      _c("template", { slot: "noOptions" }, [
        _vm._v("\n        " + _vm._s(_vm.$t("panel.label_no_data")) + "\n    "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }