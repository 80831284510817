var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block" },
    [
      _vm.getPageNotFoundStatus
        ? _c("page-not-found")
        : [
            _vm.getIsCanAccessPage
              ? [
                  _c(
                    "div",
                    { staticClass: "client__wrapper" },
                    [
                      _vm._t("default", function () {
                        return [
                          _c("client-header", {
                            ref: "ClientHeader",
                            attrs: { "user-type": _vm.getType },
                          }),
                          _vm._v(" "),
                          _c("navbar-client", {
                            ref: "NavbarClient",
                            attrs: { "user-type": _vm.getType },
                          }),
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "b-container",
                        { attrs: { fluid: "sm" } },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [_c("router-view")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              : [_c("deny-access")],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }