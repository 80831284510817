var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal-dialog",
    {
      attrs: {
        name: _vm.name,
        height: "auto",
        width: "500",
        resizable: "",
        draggable: "",
      },
    },
    [
      _c(
        "b-card",
        {
          staticClass: "text-white m-0",
          attrs: {
            header: _vm.$t("panel.label_confirm_title"),
            "header-bg-variant": _vm.color,
            "header-text-variant": "white",
            "no-body": "",
          },
        },
        [
          _c("b-card-body", [
            _c(
              "div",
              { staticClass: "d-block" },
              [
                _vm._t("content", function () {
                  return [
                    !_vm.useVHtml
                      ? _c(
                          "p",
                          { staticClass: "confirmText text-center text-dark" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.confirmText) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "p",
                          { staticClass: "confirmText text-center text-dark" },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.confirmText) },
                            }),
                          ]
                        ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-block mt-2" },
              [
                _vm._t("btn-action", function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex w-100 justify-content-around g-5",
                      },
                      [
                        _c("b-button", { on: { click: _vm.cancel } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.cancelButton) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: _vm.color },
                            on: { click: _vm.confirmOK },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.textButtonConfirm) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }