import i18n from '../../plugins/i18n';

export default [
    {
        to: { name: "ApplyJobManage" },
        title: i18n.t('panel.menu_label_list_apply_job'),
        icon: 'nc-icon nc-badge nav-icon-size'
    },
    {
        to: { name: "CorporationInfoManage" },
        title: i18n.t('panel.menu_corporation_info'),
        icon: 'nc-icon nc-bank nav-icon-size'
    },
];
