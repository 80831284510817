var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-date-picker", {
    attrs: {
      mode: "date",
      locale: "ja",
      masks: _vm.masks,
      popover: _vm.popoverConfig,
      "model-config": _vm.modelConfig,
      "update-on-input": false,
      "available-dates": _vm.isBirthDay ? { start: null, end: new Date() } : "",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ inputValue, inputEvents }) {
          return [
            _c("div", { staticClass: "d-block" }, [
              _c(
                "div",
                {
                  staticClass: "d-flex align-items-center",
                  staticStyle: { gap: "5px" },
                },
                [
                  _c(
                    "input",
                    _vm._g(
                      {
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.error &&
                            _vm.validateFail.hasOwnProperty(_vm.keyName),
                        },
                        attrs: {
                          readonly: _vm.readOnly,
                          disabled: _vm.disabled,
                        },
                        domProps: { value: inputValue },
                      },
                      inputEvents
                    )
                  ),
                  _vm._v(" "),
                  _vm.showIcon
                    ? _c("i", { staticClass: "fas fa-calendar" })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.error &&
                        _vm.validateFail.hasOwnProperty(_vm.keyName) &&
                        _vm.showErrorValidate,
                      expression:
                        "error && validateFail.hasOwnProperty(keyName) && showErrorValidate",
                    },
                  ],
                  staticClass: "text-danger mt-1",
                },
                [_vm._v(_vm._s(_vm.validateFail[_vm.keyName]))]
              ),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v
      },
      expression: "date",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }